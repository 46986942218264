<template>
    <div class="actions-due-selector actions-due-selector--due cursor-pointer" v-click-away="onClickAway">
        <div class="actions-ds-selected-val" @click="dropdown = !dropdown" :class="{'actions-ds-selected-val--default': activeValue === 'Due today'}">
            <div class="flex items-center justify-between">
                <div>
                    {{activeValue}}
                </div>
                <div class="icon icon--xs">
                    <svg class="icon-svg">
                        <use xlink:href="#icon-pointer-down"></use>
                    </svg>
                </div>
            </div>

        </div>
        <ul class="actions-ds-options dropdown" v-if="dropdown">
            <li class="actions-ds-option" v-for="(item, index) in options" @click="changeVal(item)">
                <div>{{item}}</div>
            </li>
        </ul>
    </div>
</template>
<script setup>
import {computed, ref} from "vue";
    const activeValue = ref(props.dueProp === 'today' ? 'Due today' : (props.dueProp === 'week' ? 'Due this week' : 'Due next week'));
    const options = computed(() => ['Due today', 'Due this week', 'Due next week'].filter((i) => i !== activeValue.value))
    const dropdown = ref(false);
    const emit = defineEmits(['valueChanged'])
    function changeVal(val) {
        activeValue.value = val;
        dropdown.value = false;
        emit('valueChanged', activeValue.value === 'Due today' ? 'today' : (activeValue.value === 'Due this week' ? 'week' : 'next'))
    }
    const onClickAway = (event) => {
        dropdown.value = false;
    }

    const props = defineProps({
        dueProp: {
            type: String,
            required: true
        }
    })
</script>
<style due="postcss" scoped>
    .actions-due-selector {
        @apply relative w-56;
    }
    .actions-ds-options {
        @apply w-full z-10;
    }
    .actions-ds-selected-val {font-size: 18px;
        @apply rounded-md rounded-br-none text-white bg-ddbg py-1 px-3 transition-all font-thin whitespace-nowrap;
    }
    .actions-ds-selected-val--default {
        @apply text-gray-200
    }
</style>
