<template>
    <PieChart v-bind="lineChartProps" :options="options" />
</template>

<script setup>
import {Chart, registerables} from 'chart.js'
import {PieChart, useLineChart} from 'vue-chart-3'
import {computed, defineComponent, ref} from 'vue'

Chart.register(...registerables)

const props = defineProps({
    dataset: {
        type: Object,
        required: true
    }
})

const options = ref({
    responsive: true,
    plugins: {
        legend: {
            position: 'left'
        },
        tooltip: {
            enabled: true,
            callbacks: {
                label: function(context) {
                    return context.label + ': ' + context.raw + ' Users'
                }
            }
        },
    },
})

const chartData = computed(() => ({
    labels: ['Active', 'Inactive'],
    datasets: [
        {
            label: ['Active', 'Inactive'],
            data: [props.dataset['with'], props.dataset['without']],
            backgroundColor: ['#FE3562', '#9CABC7', '#8ff0E8', '#FA8072', '#14121F', '#add666', '#66A3A3', '#231825' ],
            tension: 0.4,
            responsive: true,
            legend:false
        }
    ]
}))

const {lineChartProps, lineChartRef} = useLineChart({
    chartData,
})
</script>

<style>
</style>
