<template>
    <div class="page__contents">
        <AdminHeader />
        <div class="flex-table">
            <div v-if="faqs.length" class="ft__head">
                <div class="ft__row">
                    <div class="ft__col ft__col--id">
                        Order
                    </div>
                    <div class="ft__col ft__col--lg">
                        Question
                    </div>
                    <div class="ft__col ft__col--desc">
                        Answer
                    </div>
                    <div class="ft__col ft__col--md">
                        Date Added
                    </div>
                    <div class="ft__col ft__col--lasdfst">
                    </div>
                </div>
            </div>
            <div class="ft__body">
                <div class="ft__row__wrap bg-lighter">
                    <div class="ft__row">
                        <div class="ft__col ft__col--id">
                            <span class="font-semibold leading-8 pl-2">{{ maxOrderValueForNew }}</span>
                        </div>
                        <div class="ft__col ft__col--lg">
                            <textarea rows="8" placeholder="New Question..." v-model="newFaqForm.question" maxlength="250"></textarea>
                        </div>
                        <div class="ft__col ft__col--desc">
                            <textarea rows="8" placeholder="New Answer..." v-model="newFaqForm.answer" maxlength="500"></textarea>
                        </div>
                        <div class="ft__col ft__col--md hidden md:block" style="padding-left: 2.25rem;">
                            <div class="text-light lg:hidden">Date Added</div>
                            <span class="leading-8">Today</span>
                        </div>
                        <div class="ft__col-breaker"></div>
                        <div class="ft__col ft__col--lasdfst">
                            <div class="flex items-center justify-end" style="padding-top: 4px;">
                                <div class="col">
                                    <div class="icon icon--xs noprint cursor-pointer text-success" :style="{'cursor': canSubmitNewFaqForm ? 'pointer' : 'not-allowed'}" @click="submitNewFaqForm">
                                        <svg class="icon-svg">
                                            <use xlink:href="#icon-add"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FaqRow v-for="(faq, index) in faqs" :key="index" :faq="faq" :is-active="active === faq.id" :max-order="maxOrderValue" v-model="orderForm.orders[faq.id].order" />
                <div v-if="faqs.length > 1" class="col w-full mt-10 text-center">
                    <input type="button" value="Reorder" @click="updateFaqs" :disabled="!orderForm.isDirty" class="btn btn-add" :style="{cursor: !orderForm.isDirty ? 'not-allowed' : 'pointer'}">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import AdminHeader from "@components/AdminHeader"
import FaqRow from '@components/FaqRow.vue'
import {useForm} from "@inertiajs/inertia-vue3";
import {computed, onMounted, ref, watch} from "vue";

const props = defineProps({
    faqs: {
        type: Array,
        default: []
    },
    active: {
        type: Number,
        default: null
    }
})

const faqsCount = computed(() => props.faqs?.length ?? 0)

const maxOrderValue = computed(() => faqsCount.value === 0 ? 1 : faqsCount.value)
const maxOrderValueForNew = computed(() => faqsCount.value === 0 ? 1 : maxOrderValue.value + 1)

const newFaqForm = useForm({
    question: '',
    answer: '',
})
const canSubmitNewFaqForm = computed(() => newFaqForm.question?.length && newFaqForm.answer?.length)
const submitNewFaqForm = () => {
    if (canSubmitNewFaqForm.value) {
        newFaqForm.post('/admin/faqs/add', {
            preserveScroll: true,
            preserveState: (page) => Object.keys(page.props.errors).length,
        })
    }
}

const faqsOrders = computed(() => props.faqs?.reduce((o, faq) => ({ ...o, [faq.id]: {id: faq.id, order: faq.order}}), {}))

const orderForm = useForm({orders: faqsOrders.value})

const updateFaqs = () => {
    if (orderForm.isDirty) {
        orderForm.post("/admin/faqs/reorder", {
            preserveState: (page) => Object.keys(page.props.errors).length,
        })
    }
}

</script>
<style type="postcss" scoped>
    .flex-table {
        @apply px-3 text-dark
    }
    .ft__row__wrap {
        transition:600ms;
        @apply border-b-2 border-gray-200 relative
    }
    .ft__row {
        @apply flex w-full static flex-wrap lg:flex-nowrap;
    }
    .ft__row__wrap--highlight {
        @apply bg-highlight;
    }
    .ft__row--extras .ft__col {
        @apply pt-2;
    }
    .ft__head {
        @apply hidden lg:block
    }
    .ft__head .ft__col {font-size:11px;
        @apply py-2 font-semibold text-gray-600
    }
    .ft__head .ft__col--sorted {
        @apply text-black
    }
    .ft__head .ft__col .icon {
        @apply inline-block align-top mt-1 ml-1
    }
    .ft__col {
        @apply p-2 order-none;
    }
    .ft__col.np-t {
        @apply pt-0;
    }
    .ft__col.np-l {
        @apply pl-0;
    }
    .ft__col--icon {
        @apply w-12
    }
    .ft__col--icon .icon {
        @apply mt-1
    }
    .ft__col--id {
        @apply w-20
    }
    .ft__col--desc {
        @apply flex-1 flex-grow
    }
    .ft__col--xs {
        @apply w-28
    }
    .ft__col--sm {
        @apply w-28
    }
    .ft__col--md {
        @apply w-44
    }
    .ft__col--lg {
        @apply w-52
    }
    .ft__col--due {
        @apply w-36
    }
    .ft__col--owner {
        @apply w-48 order-none
    }
    .ft__col--owner .owner-stamp {
    }
    .ft__col--last {
        @apply lg:w-52 ml-auto relative
    }
    .ft__col--timeline { width:15rem; }
    .ft__col-breaker {
        @apply w-full lg:hidden
    }
    .ft__col--raised,
    .ft__col--due {
        @apply order-2 lg:order-none
    }
    .ft__col--sorted,
    .ft__col--last {
        @apply order-3 lg:order-none
    }
    .ft__col input[type="text"],
    .ft__col textarea {
        @apply bg-transparent focus:bg-gray-100 py-1.5 px-2 transition-all leading-4 appearance-none;
    }
    .ft__row--confirmation {
        @apply justify-end;
    }
    .confirmation-box {width:460px;
        @apply max-w-full p-3 text-dark
    }
    .slide-enter,
    .slide-leave-active {
        transition: all 666ms ease-in;
    }
    .slide-enter, .slide-leave-to {
        opacity: 0;
        left: 100%;
    }
    .ft__row--extras {
        @apply bg-gray-50 lg:bg-transparent;
    }
    /* .ft__row--extras .ft__col--icon,
    .ft__row--extras .ft__col--id {
        @apply hidden
    } */
    @media(max-width: 1023px) {
        .ft__row.ft__row--extras .ft__col--timeline {
            width:100%; order:6;
        }
        .ft__row.ft__row--extras .ft__col--icon,
        .ft__row.ft__row--extras .ft__col--id,
        .ft__row.ft__row--extras .ft__col--priority {
            @apply hidden
        }

        .ft__col.np-t {
            @apply pt-2
        }
    }
</style>
<style type="text/css" scoped>

</style>
