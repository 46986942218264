<template>
    <div>
        <p class="text-xxl mb-6 text-center">Confirm Password</p>
        <form @submit.prevent="form.post('/user/confirm-password')">
            <div class="auth-input-wrapper">
                <input type="password" placeholder="Password" v-model="form.password">
            </div>
            <FormErrors :errors="form.errors" />
            <div class="auth-button-wrapper">
                <input type="submit" value="Continue" :disabled="form.processing">
            </div>
        </form>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrors from "@components/FormErrors";

    const form = useForm({
        password: ""
    })
</script>

<script>
import AuthLayout from "@layouts/AuthLayout"

export default {
    layout: AuthLayout
}
</script>

<style type="postcss">
    .auth-input-wrapper {
        @apply relative pb-6;
    }
    .auth-input-wrapper button {
        @apply  absolute right-4 top-4
    }
    .auth-input-wrapper button span {
        @apply underline font-semibold
    }
    .auth-input-wrapper input { cursor:pointer;
        @apply block text-white w-full rounded-md border-2 border-white py-3 px-2 md:px-5 bg-transparent text-sm md:text-xxl;
    }
    .auth-input-wrapper input:placeholder {
        @apply italic
    }
    .auth-input-wrapper input.password {
        @apply pr-12;
    }

    .auth-button-wrapper {
        @apply relative pt-12;
    }
    .auth-button-wrapper input[type="submit"] { cursor:pointer;
        @apply block text-palegreen w-full rounded-md border-2 border-palegreen py-3 px-2 md:px-5 bg-transparent text-sm md:text-xxl hover:bg-success hover:border-success hover:text-white transition-all;
    }

    .auth-otp-wrapper {
        @apply relative pb-6 grid grid-cols-6 gap-3 md:gap-4 mx-auto;
    }
    .auth-otp-wrapper input {
        @apply block text-gray-700 w-full rounded-md border-2 border-white py-3 px-2 md:px-5 bg-transparent text-xxl md:text-4xl text-center;
    }
    .auth-input-wrapper [multiple]::placeholder,
    .auth-input-wrapper [type=date]::placeholder,
    .auth-input-wrapper [type=datetime-local]::placeholder,
    .auth-input-wrapper [type=email]::placeholder,
    .auth-input-wrapper [type=month]::placeholder,
    .auth-input-wrapper [type=number]::placeholder,
    .auth-input-wrapper [type=password]::placeholder,
    .auth-input-wrapper [type=search]::placeholder,
    .auth-input-wrapper [type=tel]::placeholder,
    .auth-input-wrapper [type=text]::placeholder,
    .auth-input-wrapper [type=time]::placeholder,
    .auth-input-wrapper [type=url]::placeholder,
    .auth-input-wrapper [type=week]::placeholder,
    .auth-input-wrapper select::placeholder,
    .auth-input-wrapper textarea::placeholder {
        @apply  text-sm md:text-xxl
    }
</style>
