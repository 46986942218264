<template>
    <InertiaLink :href="'/project/' + project.project_slug" class="relative">
        <div v-if="showingRename" class="absolute inset-0 bg-dark opacity-50 rounded-md rounded-br-none" style="z-index: 9999;" @click.stop.prevent></div>
        <div class="action-card" :class="{'action-card--general': project.title === 'General', 'h-full' : project.open_actions}">
            <div class="action-card--inner" :style="{'padding-bottom' : project.open_actions ? '20px' : '12px'}">
                <div class="flex justify-between items-center flex-wrap pb-4">
                    <div v-if="!showingRename" class="action-card-title font-thin">
                        <span class="pr-3">{{ project.title }}:</span> <InertiaLink :href="'/project/' + project.project_slug" class="font-semibold inline-block">
                        <span v-if="project.open_actions > 0">{{ project.open_actions }} open</span><span v-else>No open actions</span>
                        </InertiaLink>
                    </div>
                    <div v-else @click.stop.prevent class="action-card-title font-thin flex align-center" style="z-index: 99999; margin-bottom: 6px;">
                        <input type="text" v-model="form.name" ref="renameField" class="input w-auto inline text-lighter font-light text-black bg-transparent border p-0 px-2" @keydown.enter="renameProject" tabindex="0" style="font-size: 1.5rem;">
                        <button class="inline btn  bg-light nb-focus ml-4" type="button" @click.stop.prevent="hideRename" @keyup.enter.stop.prevent="hideRename" tabindex="0" style="height: 25px;">Cancel</button>
                    </div>
                    <div v-if="!showingRename && project.project_slug !== 'general'" class="col">
                        <ProjectContextNav @click.stop.prevent class="noprint" :is-open="project.is_open" @rename="showRename()" @open="openProject()" @close="closeProject()" />
                    </div>
                </div>
                <div v-if="project.open_actions" class="action-tiles">
                    <!-- <div class="action-tile action-tile--total">
                        <div class="action-tile__number">{{ project.total_actions }}</div>
                        <div class="action-tile__text">Total</div>
                    </div> -->
                    <div class="action-tile">
                        <div class="action-tile__number">{{ project.critical_actions }}</div>
                        <div class="action-tile__text">Critical</div>
                        <div class="action-tile__percentage" :style="{ height: project.critical_actions_percentage + '%' }"></div>
                    </div>
                    <div class="action-tile">
                        <div class="action-tile__number">{{ project.high_actions }}</div>
                        <div class="action-tile__text">High</div>
                        <div class="action-tile__percentage" :style="{ height: project.high_actions_percentage + '%' }"></div>
                    </div>
                    <div class="action-tile">
                        <div class="action-tile__number">{{ project.medium_actions }}</div>
                        <div class="action-tile__text">Medium</div>
                        <div class="action-tile__percentage" :style="{ height: project.medium_actions_percentage + '%' }"></div>
                    </div>
                    <div class="action-tile">
                        <div class="action-tile__number">{{ project.low_actions }}</div>
                        <div class="action-tile__text">Low</div>
                        <div class="action-tile__percentage" :style="{ height: project.low_actions_percentage + '%' }"></div>
                    </div>
                    <div class="action-tile action-tile--unrated">
                        <div class="action-tile__number">{{ project.unclassified_actions }}</div>
                        <div class="action-tile__text italic">Unrated</div>
                        <div class="action-tile__percentage" :style="{ height: project.unclassified_actions_percentage + '%' }"></div>
                    </div>
                </div>
                <!-- <div :class="project.open_actions ? 'mb-12' : 'mb-6'"></div> -->
            </div>
            <div class="bottom-row" :style="{backgroundColor: project.title === 'General' ? '#6C6E70' : '#393E42'}">
                <InertiaLink :href="'/project/' + project.project_slug + '?status=closed'" class="font-light inline-block">
                    <span v-if="project.escalated_actions">{{ project.escalated_actions }} escalated<span v-if="project.closed_actions">, </span></span>
                    <span v-if="project.closed_actions">{{ project.closed_actions }} closed</span>
                </InertiaLink>
                <div v-if="project.overdue_actions" class="flex items-center">
                    <div class="col-auto pr-3">
                        <div class="icon icon--sm">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-time"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="whitespace-nowrap">{{ project.overdue_actions }} overdue</div>
                </div>
            </div>
        </div>
    </InertiaLink>
</template>
<script setup>
import ProjectContextNav from "./ProjectContextNav.vue";
import {ref} from "vue";
import {Inertia} from "@inertiajs/inertia";
import {useForm} from "@inertiajs/inertia-vue3";

const props = defineProps({
    project: {
        type: Object,
        required: true
    }
})

const renameField = ref(null)
const showingRename = ref(false)
const showRename = () => {
    showingRename.value = true
    setTimeout(() => renameField.value?.focus(), 300)
}
const hideRename = () => showingRename.value = false


const closeProject = () => {
    Inertia.post("/p/" + props.project.project_slug + "/close", null, {
        preserveScroll: false,
        preserveState: false
    })
}
const openProject = () => {
    Inertia.post("/p/" + props.project.project_slug + "/open", null, {
        preserveScroll: false,
        preserveState: false
    })
}

const form = useForm({
    name: props.project.title
})
const renameProject = () => {
    form.post("/p/" + props.project.project_slug + "/rename", {
        preserveScroll: true,
        preserveState: true,
        onSuccess: (p) => {
            form.reset()
            hideRename()
        }
    })
}



</script>
<style type="postcss">
.actions-list {grid-template-columns: repeat(auto-fill,minmax(325px,1fr));
    @apply grid gap-5;
}
.action-card { position: relative; border-top-width:8px; border-color:#393E42; border-radius: 4px;
    @apply bg-ddbg text-white rounded-br-none flex flex-col;
}
.action-card--inner { padding: 8px 12px 20px;
    @apply flex-1;
}
.action-card-title {font-size: 18px;}
.action-tiles {
    @apply grid grid-cols-5 gap-4
}
.action-tile { border-radius: 4px; padding-top: 12px; min-height: 82px; padding-bottom: 8px;
    @apply bg-white text-gray-700 text-center flex flex-col justify-end relative overflow-hidden
}
.action-tile--total {
    @apply bg-light text-white
}
.action-tile--unrated {
    @apply bg-light text-white
}
.action-tile__number { font-size: 18px;
    @apply pb-1 relative z-10;
}
.action-tile__text {
    @apply relative z-10 leading-5
}
.action-tile__percentage {
    @apply absolute z-0 left-0 bottom-0 right-0 bg-amber;
}
.action-tile--unrated .action-tile__percentage {
    @apply bg-dark
}
.action-card--general { border-color:#6C6E70;
    @apply bg-light
}
.action-card--general .action-tile--total {
    @apply bg-ddbg;
}
.action-card--general .action-tile--unrated {
    background: #6c6e70;
}

.action-card .bottom-row {padding: 6px 8px; min-height: 32px; border-bottom-left-radius:4px;
    @apply flex items-center justify-between;
}
@media (max-width:350px) {
    .actions-list {
        @apply flex flex-col;
    }
    .action-tiles {
        @apply gap-3;
    }
}
</style>
