<template>
    <div v-if="true" class="ft__row__wrap" :class="{'bg-lighter':isOpen && (showingDelete || showingRebase || showingMove), 'bg-gray-200':!isOpen && showingDelete, 'ft__row__wrap--highlight': isHighlighted}" @mouseenter="removeHighlighted()">
        <div class="ft__row">
            <div class="ft__col ft__col--icon">
                <ActivityIcon v-if="action.type === 'Activity'" size="medium" @click="changeType" @keyup.enter="changeType" :class="[isOpen && isProjectOpen ? 'cursor-pointer' : '', typeIconPriorityColourClass]" :tabindex="isOpen && isProjectOpen ? 0 : null" class="nb-focus" />
                <DecisionIcon v-else-if="action.type === 'Decision'" size="medium" @click="changeType" @keyup.enter="changeType" :class="[isOpen && isProjectOpen ? 'cursor-pointer' : '', typeIconPriorityColourClass]" :tabindex="isOpen && isProjectOpen ? 0 : null" class="nb-focus" />
                <AddIcon v-else size="medium" @click="changeType" @keyup.enter="changeType" :class="[isOpen && isProjectOpen ? 'cursor-pointer' : '', typeIconPriorityColourClass]" :tabindex="isOpen && isProjectOpen ? 0 : null" class="nb-focus" />
            </div>
            <div class="ft__col ft__col--id">
                <span class="font-semibold leading-8 text-md">{{ action.project_index_id }}</span>
            </div>
            <div v-if="isOpen && isProjectOpen" class="ft__col ft__col--desc np-l">
                <input type="text" v-model="descriptionForm.description" @blur="submitDescriptionForm" @keyup.enter="submitDescriptionForm" tabindex="0"  ref="showNsdfotesButton" />
            </div>
            <div v-else class="ft__col ft__col--desc">
                <div class="font-semibold text-md" :class="{'leading-5': !isOpen}">{{ action.description }}</div>
                <div v-if="action.close_message" class="pt-1"><span class="font-semibold">Resolved by:</span> {{ action.close_message }}</div>
            </div>
            <div v-if="showProjectColumn" class="ft__col ft__col--desc">
                <div class="font-semibold text-md">{{ action.project }}</div>
            </div>
            <div class="ft__col ft__col--sm ft__col--priority">
                <PrioritySelector v-if="isOpen && isProjectOpen" @focus="closeDatePopovers" :id="'priority-' + action.id" v-model="priorityForm.priority" v-on:update:modelValue="submitPriorityForm" class="bg-transparent" />
                <div v-else class="priority priority--rounded flex items-center" :class="action.priority === 'Critical' ? 'priority--critical' : (action.priority === 'High' ? 'priority--high' : (action.priority === 'Medium' ? 'priority--medium' : (action.priority === 'Low' ? 'priority--low' : '!bg-white')))" style="height: 24px;" >
                    <div class="icon icon--xs mr-2" v-if="action.priority === 'Critical'">
                        <svg class="icon-svg">
                            <use xlink:href="#icon-critical"></use>
                        </svg>
                    </div>
                    <div class="icon icon--xs mr-2" v-else-if="action.priority === 'High'">
                        <svg class="icon-svg">
                            <use xlink:href="#icon-high"></use>
                        </svg>
                    </div>
                    <div>
                        {{ action.priority }}
                    </div>
                </div>
            </div>
            <div class="ft__col-breaker"></div>
            <div v-if="isOpen && isProjectOpen" class="ft__col ft__col--sm ft__col--raised">
                <div class="text-light lgx:hidden">Raised</div>
                <span class="leading-8">{{ action.created_at }}</span>
            </div>
            <div v-else class="ft__col ft__col--xs">
                <div class="text-light lgx:hidden">Raised</div>
                <span class="leading-8 font-semibold">{{ action.created_at }}</span>
            </div>
            <div @click="toggleTimeline" class="ft__col ft__col--lg hidden 2xl:block timeline-col-xl" :class="{'closed-timeline': !isOpen}" style="padding-top: 10px;">
                <TimelineMini v-if="action.timeline" :timeline="action.timeline" class="cursor-pointer" tabindex="0" @keyup.enter="toggleTimeline" @focus="closeDatePopovers" />
            </div>
            <div v-if="isOpen && isProjectOpen" class="ft__col ft__col--md ft__col--due">
                <div class="text-light lgx:hidden">Due</div>
                <DueDatePicker :closePopoverProp="closeDatePopover" @popoverClosed="closeDatePopover = false" :url="baseUrl + '/edit-due-date'" :baseline-url="action.canBaseline ? (baseUrl + '/baseline') : null" :date-prop="action.due_date" :baseline="action.baseline_date" :baseline-variance="action.baseline_variance" :isDueDateInThePast="action.isDueDateInThePast" :min-date="action.dueMinDate" :min-date-minus-1="action.dueMinDateMinus1" />
            </div>
            <div v-else class="ft__col ft__col--xs">
                <div class="text-light lgx:hidden">Due</div>
                <span class="leading-8 font-semibold">{{ action.due_date }}</span>
            </div>
            <div v-if="!isOpen" class="ft__col ft__col--lg">
                <div class="text-light lgx:hidden">Closed</div>
                <span class="leading-8 font-semibold" :class="{'text-danger': action.close_variance?.isPlus, 'text-success': !action.close_variance?.isPlus}">{{ action.closed_date }}</span>
                <span v-if="action.close_variance?.value" class="p-3" :class="{'text-danger': action.close_variance?.isPlus, 'text-success': !action.close_variance?.isPlus}">{{ (action.close_variance?.isPlus ? '+' : '-') + action.close_variance?.value }} days</span>
            </div>
            <div v-if="isOpen && isProjectOpen" class="ft__col ft__col--owner">
                <div class="text-light lgx:hidden pl-8">Owner</div>
                <div class="owner-stamp">
                    <div class="flex items-center">
                        <div class="col pr-1 pt-2">
                            <PersonIcon size="xs" />
                        </div>
                        <div class="col flex-1">
                            <Dropdown
                                :id="'owner-' + action.id"
                                placeholder="Owner..."
                                data-url="/data/users"
                                v-model="ownerForm.owner"
                                @focus="closeDatePopovers"
                                @blur="submitOwnerForm"
                                :new-saved-value="savedNewOwnerValue"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="ft__col ft__col--md">
                <div class="text-light lgx:hidden pl-8">Owner</div>
                <div class="flex items-center">
                    <div class="col pr-2 pt-2">
                        <PersonIcon size="xs" />
                    </div>
                    <div class="col">
                        {{ action.owner }}
                    </div>
                </div>
            </div>
            <div v-if="isOpen && isProjectOpen" class="ft__col-breaker order-3"></div>
            <div class="ft__col ft__col--last ft__col--actions">
                <div class="items-center gap-4 hidden lgx:flex" :class="{'justify-end': isOpen && isProjectOpen}">
                    <div class="col flex-1" :class="{'flex-1 text-center': !isOpen || !isProjectOpen}">
                        <div v-if="!isProjectOpen" class="as__button text-center leading-8 font-semibold" :class="{'open': action.status ==='Open', 'closed': action.status ==='Closed', 'escalated': action.status ==='Escalated' }">{{action.status}}</div>
                        <ActionStatus v-else-if="isOpen" :closePopoverProp="closeDatePopover" @popoverClosed="closeDatePopover = false" :base-url="baseUrl" :action-id="action.id" :status="action.status" :unresolved-escalations="action.unresolved_escalations" :pending-closure-request="action.pending_closure_request" :due-min-date="action.dueMinDate" :due-min-date-minus-1="action.dueMinDateMinus1" />
                        <span v-else class="leading-8 font-semibold text-success">Closed</span>
                    </div>
                    <div class="col">
                        <NoteIcon :has-notes="action.has_notes" class="noprint cursor-pointer nb-focus" size="medium" @click="showNotes" @keyup.enter="toggleNotes" tabindex="0" :id="action.id + '-showNotesButton'" />
                    </div>
                    <div class="col">
                        <ContextNav v-if="isProjectOpen" :action-id="action.id" id-suffix="desktop" class="noprint" :can-rebaseline="action.canRebaseline" :can-reset="action.canReset" :can-move="action.canMove" @move="showMove" :current-slug="action.project_slug" :projects="projects" @delete="showDelete()" @duplicate="submitDuplicate()" @reset="submitReset()" @rebaseline="showRebase()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="ft__row ft__row--extras">
            <div class="ft__col ft__col--icon"></div>
            <div class="ft__col ft__col--id"></div>
            <div class="ft__col ft__col--desc np-t">
                <div class="xs:flex w-full" style="gap: 12px;">
                    <DimensionSelector class="mb-2 sm:mb-0" :id="'dimension-' + action.id" :is-open="isOpen && isProjectOpen" v-model="dimensionForm.dimension" @update:modelValue="submitDimensionForm" />
                    <div class="flex-1">
                        <LabelSelector :can-add="isOpen && isProjectOpen" :labels="action.labels" :base-url="baseUrl" />
                    </div>
                </div>
            </div>
            <div v-if="isOpen && isProjectOpen" class="ft__col ft__col--sm ft__col--priority hidden lgx:block"></div>
            <div v-if="action.timeline" @click="toggleTimeline" class="ft__col ft__col--sm ft__col--timeline 2xl:hidden timeline-col-sm" :class="{'closed-timeline': !isOpen || !isProjectOpen}">
                <TimelineMini :timeline="action.timeline" class="cursor-pointer nb-focus" tabindex="0" @keyup.enter="toggleTimeline" @focus="closeDatePopovers" />
            </div>
            <div v-if="!isOpen || !isProjectOpen" class="ft__col ft__col--lg hidden lgx:block"></div>
            <div class="ft__col hidden lgx:block" :class="{'ft__col--owner': isOpen && isProjectOpen, 'ft__col--md': !isOpen || !isProjectOpen}"></div>
            <div class="ft__col ft__col--last ft__col--actions">
                <div class="flex items-center gap-4 lgx:hidden" :class="{'justify-end': isOpen && isProjectOpen}">
                    <div class="col flex-1" :class="{'flex-1 text-center': !isOpen || !isProjectOpen}">
                        <div v-if="!isProjectOpen" class="as__button text-center leading-8 font-semibold" :class="{'open': action.status ==='Open', 'closed': action.status ==='Closed', 'escalated': action.status ==='Escalated' }">{{action.status}}</div>
                        <ActionStatus v-else-if="isOpen" :closePopoverProp="closeDatePopover" @popoverClosed="closeDatePopover = false" :base-url="baseUrl" :action-id="action.id" :status="action.status" :unresolved-escalations="action.unresolved_escalations" :pending-closure-request="action.pending_closure_request" :due-min-date="action.dueMinDate" :due-min-date-minus-1="action.dueMinDateMinus1" />
                        <span v-else class="leading-8 font-semibold text-success">Closed</span>
                    </div>
                    <div class="col">
                        <NoteIcon :has-notes="action.has_notes" class="noprint cursor-pointer nb-focus" size="medium" @click="showNotes" @keyup.enter="toggleNotes" tabindex="0" />
                    </div>
                    <div class="col">
                        <ContextNav v-if="isProjectOpen" :action-id="action.id" id-suffix="mobile" class="noprint" :can-rebaseline="action.canRebaseline" :can-reset="action.canReset" :can-move="action.canMove" @move="showMove" :current-slug="action.project_slug" :projects="projects" @delete="showDelete()" @duplicate="submitDuplicate()" @reset="submitReset()" @rebaseline="showRebase()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="ft__row ft__row--confirmation noprint" v-if="showingDelete">
            <div class="confirmation-box">
                <div class="flex">
                    <div class="col">
                        <ExclamationIcon size="md"/>
                    </div>
                    <div class="col pl-4 w-full">
                        <div class="font-semibold pb-1">Delete this action?</div>
                        <div class="pb-3">This will delete the action including all notes and events. If this action still forms part of the project record, it should be closed.</div>
                        <form>
                            <textarea class="nb-focus" rows="3" placeholder="Reason for deletion..." v-model="deleteForm.description" maxlength="250" :id="action.id + '-delete-textarea'"></textarea>
                            <div class="flex justify-end pt-4">
                                <div class="col">
                                    <button class="btn btn--border btn--dark nb-focus" type="button" @click="submitDeleteForm" @keyup.enter="submitDeleteForm" :disabled="!deleteForm.description?.length || deleteForm.processing" tabindex="0">Delete</button>
                                </div>
                                <div class="col pl-3">
                                    <button class="btn btn--border btn--dark nb-focus" type="button" @click="hideDelete" @keyup.enter="hideDelete" tabindex="0">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="ft__row ft__row--confirmation noprint" v-else-if="showingRebase">
            <div class="confirmation-box">
                <div class="flex">
                    <div class="col">
                        <ExclamationIcon size="md"/>
                    </div>
                    <div class="col pl-4 w-full">
                        <div class="font-semibold pb-3">Rebase this action?</div>
                        <div class="pb-3">This will update the baseline with the current due date.</div>
                        <form>
                            <textarea class="nb-focus" rows="3" placeholder="Reason for re-baselining..." v-model="rebaseForm.description" maxlength="250" :id="action.id + '-rebase-textarea'"></textarea>
                            <div class="flex justify-end pt-4">
                                <div class="col">
                                    <button type="button" class="btn btn--border btn--dark nb-focus" @click="submitRebaseForm" @keyup.enter="submitRebaseForm" tabindex="0" :disabled="!rebaseForm.description?.length || rebaseForm.processing">Rebase</button>
                                </div>
                                <div class="col pl-3">
                                    <button type="button" class="btn btn--border btn--dark nb-focus" @click="hideRebase" @keyup.enter="hideRebase" tabindex="0">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="ft__row ft__row--confirmation noprint" v-else-if="showingMove">
            <div class="confirmation-box">
                <div class="flex">
                    <div class="col">
                        <ExclamationIcon size="md"/>
                    </div>
                    <div class="col pl-4 w-full">
                        <div class="font-semibold pb-3">Move to project {{ moveForm.title }}</div>
                        <div class="pb-3">Moving an action to another project will affect any previous project reporting.</div>
                        <form>
                            <div class="input-wrapper">
                                <div class="custom-checkbox">
                                    <label>
                                        <input type="checkbox" class="pr-2" v-model="moveForm.reset_raised">
                                        <span class="text-dark">Reset date raised</span>
                                    </label>
                                </div>
                            </div>
                            <div class="flex justify-end pt-4">
                                <div class="col">
                                    <button type="button" class="btn btn--border btn--dark nb-focus" @click="submitMoveForm" @keyup.enter="submitMoveForm" tabindex="0" :disabled="moveForm.processing">Move</button>
                                </div>
                                <div class="col pl-3">
                                    <button type="button" class="btn btn--border btn--dark nb-focus" @click="hideMove" @keyup.enter="hideMove" tabindex="0">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Timeline v-if="action.timeline && showingTimeline" :timeline="action.timeline" @close="hideTimeline" @open-notes="showNotes" />
    </div>
    <transition name="notes" mode="out-in">
        <Notes v-if="showingNotes" @hide="hideNotes" :notes="action.notes" :actionId="action.id" :actionIndexId="action.project_index_id" :actionDescription="action.description" v-click-away="hideNotes" />
    </transition>
</template>

<script setup>
import PrioritySelector from '@components/PrioritySelector.vue'
import ActionStatus from '@components/ActionStatus.vue'
import ContextNav from '@components/ContextNav.vue'
import DimensionSelector from '@components/DimensionSelector.vue'
import DueDatePicker from '@components/DueDatePicker.vue'
import LabelSelector from '@components/LabelSelector.vue'
import Timeline from '@components/Timeline.vue'
import {computed, nextTick, onMounted, ref} from "vue";
import {useForm} from "@inertiajs/inertia-vue3";
import useToast from "@composables/useToast";
import {Inertia} from "@inertiajs/inertia";
import Notes from '@components/Notes.vue'
import PersonIcon from '@icons/PersonIcon.vue'
import ActivityIcon from '@icons/ActivityIcon.vue'
import DecisionIcon from '@icons/DecisionIcon.vue'
import AddIcon from '@icons/AddIcon.vue'
import NoteIcon from '@icons/NoteIcon.vue'
import ExclamationIcon from '@icons/ExclamationIcon.vue'
import {watch} from "vue";
import AutoComplete from '@components/AutoComplete.vue'
import TimelineMini from '@components/TimelineMini.vue'

import Dropdown from '@components/Dropdown.vue'

const props = defineProps({
    action: {
        type: Object,
        required: true
    },
    isOpen: {
        type: Boolean,
        required: true
    },
    isActive: {
        type: Boolean,
        default: false
    },
    projects: {
        type: Array,
        required: true
    },
    showProjectColumn: {
        type: Boolean,
        default: false
    },
})

const isProjectOpen = computed(() => props.action.project_is_open)

const closeDatePopover = ref(false)

const closeDatePopovers = () => {
    closeDatePopover.value = true
}

function useNotes() {
    const showingNotes = ref(false)

    const showNotes = () => showingNotes.value = true
    const hideNotes = () => {
        showingNotes.value = false
        let el = document.getElementById(props.action.id + '-showNotesButton')
        if (el) {
            setTimeout(() => el.focus(), 300)
        }
    }
    const toggleNotes = () => showingNotes.value = !showingNotes.value

    watch(showingNotes, (currentValue, oldValue) => {
        if(currentValue) {
            document.documentElement.style.overflow = 'hidden'
        } else {
            document.documentElement.style.overflow = 'auto'
        }
    })

    return {showingNotes, showNotes, hideNotes, toggleNotes}
}
const {showingNotes, showNotes, hideNotes, toggleNotes} = useNotes()


const emit = defineEmits(['notes'])

function useTimelime() {
    const showingTimeline = ref(false)
    const hideTimeline = () => showingTimeline.value = false
    const showTimeline = () => showingTimeline.value = true
    const toggleTimeline = () => showingTimeline.value = !showingTimeline.value

    return {showingTimeline,hideTimeline, showTimeline, toggleTimeline}
}

const {showingTimeline,hideTimeline, showTimeline, toggleTimeline} = useTimelime()

const isHighlighted = ref(props.isActive);
function removeHighlighted() {
    isHighlighted.value = false
}

onMounted(() => {
    setTimeout(() => removeHighlighted(), 600)
})

const {toast} = useToast()

const baseUrl = computed(() => '/action/' + props.action.id)


function useDescription() {
    const descriptionForm = useForm({
        description: props.action.description
    })
    const submitDescriptionForm = () => {
        descriptionForm.post(baseUrl.value + '/edit-description', {
            preserveScroll: true,
            preserveState: true
        })
    }
    return {descriptionForm, submitDescriptionForm}
}
const {descriptionForm, submitDescriptionForm} = useDescription()

function useOwner() {
    const ownerForm = useForm({
        owner: props.action.owner
    })

    const savedNewOwnerValue = ref("")

    const submitOwnerForm = () => {
        if (ownerForm.owner !== props.action.owner) {
            ownerForm.post(baseUrl.value + '/edit-owner', {
                preserveScroll: true,
                preserveState: true,
                onFinish: () => savedNewOwnerValue.value = ownerForm.owner
            })
        }
    }
    return {ownerForm, submitOwnerForm, savedNewOwnerValue}
}
const {ownerForm, submitOwnerForm, savedNewOwnerValue} = useOwner()

function usePriority() {
    const priorityForm = useForm({
        priority: props.action.priority ?? ""
    })
    const submitPriorityForm = () => {
        priorityForm.post(baseUrl.value + '/edit-priority', {
            preserveScroll: true,
            preserveState: true
        })
    }
    return {priorityForm, submitPriorityForm}
}
const {priorityForm, submitPriorityForm} = usePriority()

function useDimension() {
    const dimensionForm = useForm({
        dimension: props.action.dimension ?? ""
    })
    const submitDimensionForm = () => {
        dimensionForm.post(baseUrl.value + '/edit-dimension', {
            preserveScroll: true,
            preserveState: true
        })
    }
    return {dimensionForm, submitDimensionForm}
}
const {dimensionForm, submitDimensionForm} = useDimension()

const submitDuplicate = () => {
    Inertia.post(baseUrl.value + '/duplicate',  null, {
        preserveScroll: true,
        preserveState: true
    })
}
const submitReset = () => {
    Inertia.post(baseUrl.value + '/reset',  null, {
        preserveScroll: true,
        preserveState: true
    })
}
const changeType = () => {
    if (props.isOpen && isProjectOpen.value) {
        Inertia.post(baseUrl.value + '/edit-type', null, {
            preserveScroll: true,
            preserveState: true
        })
    }
}

function useRebaseAction() {
    const showingRebase = ref(false)

    const hideRebase = () => {
        rebaseForm.reset()
        showingRebase.value = false
    }
    const showRebase = () => {
        hideMove()
        hideDelete()
        showingRebase.value = true
        setTimeout(() => {
            let el = document.getElementById(props.action.id + '-rebase-textarea')
            if (el) {
                el.focus()
            }
        }, 300)
    }

    const rebaseForm = useForm({
        description: ""
    })

    const submitRebaseForm = () => {
        rebaseForm.post(baseUrl.value + '/rebaseline', {
            preserveScroll: true,
            preserveState: true,
            onSuccess: (p) => hideRebase()
        })
    }

    return {showingRebase, hideRebase, showRebase, rebaseForm, submitRebaseForm}
}
const {showingRebase, hideRebase, showRebase, rebaseForm, submitRebaseForm} = useRebaseAction()


function useDeleteAction() {
    const showingDelete = ref(false)

    const hideDelete = () => {
        deleteForm.reset()
        showingDelete.value = false
    }
    const showDelete = () => {
        hideMove()
        hideRebase()
        showingDelete.value = true
        setTimeout(() => {
            let el = document.getElementById(props.action.id + '-delete-textarea')
            if (el) {
                el.focus()
            }
        }, 300)
    }

    const deleteForm = useForm({
        description: ""
    })

    const submitDeleteForm = () => {
        deleteForm.post(baseUrl.value + '/delete', {
            preserveScroll: true,
            preserveState: true,
            onSuccess: (p) => hideDelete()
        })
    }

    return {showingDelete, hideDelete, showDelete, deleteForm, submitDeleteForm}
}
const {showingDelete, hideDelete, showDelete, deleteForm, submitDeleteForm} = useDeleteAction()


function useMoveAction() {
    const showingMove = ref(false)

    const hideMove = () => {
        moveForm.reset()
        showingMove.value = false
    }
    const showMove = (val) => {
        hideRebase()
        hideDelete()
        moveForm.title = val.value
        moveForm.slug = val.slug
        showingMove.value = true
    }

    const moveForm = useForm({
        title: "",
        slug: "",
        reset_raised: false,
    })

    const submitMoveForm = () => {
        moveForm.post(baseUrl.value + '/move', {
            preserveScroll: true,
            preserveState: true,
            onSuccess: (p) => hideMove()
        })
    }

    return {showingMove, hideMove, showMove, moveForm, submitMoveForm}
}
const {showingMove, hideMove, showMove, moveForm, submitMoveForm} = useMoveAction()

const typeIconPriorityColourClass = computed(() => props.action?.priority === 'Critical' ? 'text-danger' : (props.action?.priority === 'High' ? 'text-error' : (props.action?.priority === 'Medium' ? 'text-warning' : (props.action?.priority === 'Low' ? 'text-success' : 'text-lighter'))))

</script>

<style type="postcss" scoped>
.flex-table {
    @apply px-3 text-dark
}
.ft__row__wrap {
    transition:600ms;
    @apply border-b-2 border-gray-200 relative
}
.ft__row {
    @apply flex w-full static flex-wrap lgx:flex-nowrap;
}
.ft__row__wrap--highlight {
    @apply bg-highlight;
}
.ft__row--extras .ft__col {
    @apply pt-2;
}
.ft__head {
    @apply hidden lgx:block
}
.ft__head .ft__col {font-size:11px;
    @apply py-2 font-semibold text-gray-600
}
.ft__head .ft__col--sorted {
    @apply text-black
}
.ft__head .ft__col .icon {
    @apply inline-block align-middle mr-1
}
.ft__col {padding-left:6px; padding-right:6px;
    @apply py-1 sm:py-2 order-none;
}
.ft__col.np-t {
    @apply pt-0;
}
.ft__col.np-l {
    @apply pl-0;
}
.ft__col--icon {
    @apply w-12
}
.ft__col--id {
    @apply w-20
}
.ft__col--desc {
    @apply flex-1 flex-grow
}
.ft__col--desc input[type="text"] {
    @apply text-md truncate
}
.ft__col--xs {
    @apply w-28
}
.ft__col--sm {
    @apply lgx:w-28
}
.ft__col--priority {
    @apply w-32
}
.ft__col--md {
    @apply lgx:w-44
}
.ft__col--lg {
    @apply w-52
}
.ft__col--due {
    @apply w-48
}
.ft__col--owner {
    @apply w-48 order-none mr-auto;
}
.ft__col--owner .owner-stamp {
}
.ft__col--last {
    @apply lgx:w-52 ml-auto relative
}
.ft__col--actions {
    @apply lgx:w-64;
}
.ft__col--timeline {
    width:19rem;
}
.ft__col--timeline.closed-timeline {
    @apply w-56
}
.ft__col-breaker {
    @apply w-full lgx:hidden
}
.ft__col--raised,
.ft__col--due {
    @apply order-2 lgx:order-none
}
.ft__col--sorted,
.ft__col--last {
    @apply order-3 lgx:order-none
}
.ft__col input[type="text"],
.ft__col textarea { padding:4px 8px; line-height:16px;
    @apply bg-transparent focus:bg-gray-100 transition-all appearance-none;
}
.ft__row--confirmation {
    @apply justify-end;
}
.confirmation-box {width:460px;
    @apply max-w-full p-3 text-dark
}
.slide-enter,
.slide-leave-active {
    transition: all 666ms ease-in;
}
.slide-enter, .slide-leave-to {
    opacity: 0;
    left: 100%;
}
.ft__row--extras {
    @apply bg-lighter lgx:bg-transparent mt-2 lgx:mt-0
}
/* .ft__row--extras .ft__col--icon,
.ft__row--extras .ft__col--id {
    @apply hidden
} */
@media(max-width: 991px) {
    .ft__row.ft__row--extras .ft__col--timeline {
        width:100%; order:6;
    }
    .ft__row.ft__row--extras .ft__col--icon,
    .ft__row.ft__row--extras .ft__col--id,
    .ft__row.ft__row--extras .ft__col--priority {
        @apply hidden
    }

    .ft__col.np-t {
        @apply pt-2
    }
    .ft__col--due {
        @apply w-48
    }
    .ft__col--id {
        @apply w-12
    }
}
@media(max-width: 399px) {
    .ft__col--raised {
        @apply w-48
    }
    .ft__col--due {
        @apply ml-auto;
    }
}
@media(max-width: 329px) {
    .ft__col--raised {
        @apply w-auto
    }
}
.all__actions .timeline-col-xl {
    @apply 2xl:hidden;
}
.all__actions .timeline-col-sm {
    @apply 2xl:block;
}
</style>
