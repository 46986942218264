<template>
    <div>
        <DueBar v-if="projects?.length" :actions="actions" :due="due" @due-value-changed="dueValueChanged" />
        <div v-if="projects?.length" class="lg:p-8 p-5">
            <div class="pb-8 flex items-center justify-between gap-4">
                <div class="text-xxl text-dark font-light">
                    <span class="inline-block font-medium pr-3">Active projects:</span>
                    <span class="inline-block">
                    <button @click="showingOpenProjects = true" @keyup.enter="showingOpenProjects = true" tabindex="0" :class="{'underline': !showingOpenProjects, 'text-light': !showingOpenProjects}" class="nb-focus font-light">{{ openProjectsCount }} open</button>
                    <span class="px-4">|</span>
                    <button @click="showingOpenProjects = false" @keyup.enter="showingOpenProjects = false" tabindex="0" :class="{'underline': showingOpenProjects, 'text-light': showingOpenProjects}" class="nb-focus font-light">{{ closedProjectsCount }} closed</button>
                    </span>
                </div>
                <div class="flex items-center gap-2">
                    <InertiaLink href="/home" class="cursor-pointer flex items-center text-light rounded border border-light" style="height: 29px;width: 29px;">
                        <div class="icon icon--medium m-auto">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-project-tile"></use>
                            </svg>
                        </div>
                    </InertiaLink>
                    <InertiaLink href="/all-actions" class="cursor-pointer flex items-center text-light rounded border border-light bg-gray-100" style="height: 29px;width: 29px;">
                        <div class="icon icon--medium m-auto">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-action-list"></use>
                            </svg>
                        </div>
                    </InertiaLink>
                </div>
            </div>
            <div v-if="filteredProjects.length" class="actions-list">
                <div v-for="(project, index) in filteredProjects" :key="project" class="col-auto">
                    <ProjectCard :project="project"/>
                </div>
            </div>
        </div>
        <div v-if="!actions?.length && !projects?.length" class="lg:p-8 p-5">
            <div class="flex">
                <div class="col pr-3 pt-2">
                    <div class="icon icon--sm">
                        <svg class="icon-svg">
                            <use xlink:href="#icon-exclamation"></use>
                        </svg>
                    </div>
                </div>
                <div class="col text-xxl font-thin">Sympmy Beat presents a powerful new system to manage actions for all your projects or to help  control your 'To-Do' lists</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";
import ProjectCard from '@components/ProjectCard.vue'
import DueBar from '@components/DueBar.vue'
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    projects: {
        type: Array,
        default: []
    },
    actions: {
        type: Array,
        default: []
    },
    due: {
        type: String,
        required: true
    },
})

const dueValueChanged = (q) => {
    Inertia.visit('/home?due=' + q)
}

const showingOpenProjects = ref(true)
const filteredProjects = computed(() => props.projects.filter(p => p.is_open === showingOpenProjects.value))

const closedProjectsCount = computed(() => props.projects.filter(p => !p.is_open).length)
const openProjectsCount = computed(() => props.projects.filter(p => p.is_open).length)

</script>

<style type="postcss">
.toggle-actions-btn {
    @apply lg:hidden ring-1 ring-white ring-inset rounded-md py-2 px-3 inline-block font-light text-xxl leading-8 h-full;
}
</style>
