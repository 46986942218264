<template>
    <div class="add-new-bar noprint">
        <div class="flex gap-sm flex-wrap">
            <div class="col-auto lg:block hidden">
                <div class="text-xxl leading-7 font-thin">Add new</div>
            </div>
            <div class="col lg:flex-1 w-full">
                <div class="relative">
                    <input ref="description" type="text" placeholder="Description..." v-model="form.description"
                           @focus="focusField('description')"
                           @blur="unfocusField()"
                           @mouseover="showTooltipOn('description')"
                           @mouseleave="hideTooltipOn();"
                    >
                    <tool-tip v-show="hasEnabledTooltips && !form.description?.length && focussedField !== 'description' && showingTooltipOn === 'description'">Start by adding an action here</tool-tip>
                </div>
            </div>
            <div class="col lg:w-36 2xl:w-40 w-2/4">
                <div class="relative">
                    <TypeSelector id="new-type" placeholder="Type..." v-model="form.type"
                          @mouseover="showTooltipOn('type')"
                          @mouseleave="hideTooltipOn"
                          @focus="focusField('type')"
                          @blur="unfocusField"
                    />
                    <tool-tip v-show="hasEnabledTooltips && !form.type?.length && focussedField !== 'type' && showingTooltipOn === 'type'">The Type can be a Decision or an Activity</tool-tip>
                </div>
            </div>
            <div class="col lg:w-36 2xl:w-40 w-2/4">
                <div class="relative">
                    <PrioritySelector id="new-priority" placeholder="Priority..." v-model="form.priority"
                          @mouseover="showTooltipOn('priority')"
                          @mouseleave="hideTooltipOn"
                          @focus="focusField('priority')"
                          @blur="unfocusField"
                    />
                    <tool-tip v-show="hasEnabledTooltips && !form.priority?.length && focussedField !== 'priority' && showingTooltipOn === 'priority'">If you don't know the priority yet, skip this and prioritise later</tool-tip>
                </div>
            </div>
            <div class="col lg:w-36 2xl:w-56 w-full">
                <div class="relative" style="height: 100%;">
                    <Dropdown
                        id="new-project"
                        placeholder="Project..."
                        data-url="/data/projects"
                        v-model="form.project"
                        @mouseover="showTooltipOn('project')"
                        @mouseleave="hideTooltipOn"
                        @focus="focusField('project')"
                        @blur="unfocusField"
                    />
                    <tool-tip v-show="hasEnabledTooltips && !form.project?.length && focussedField !== 'project' && showingTooltipOn === 'project'">If you assign it to a project, Sympmy Beat will start building up a project list for you. If not, this will join your General action log</tool-tip>
                </div>
            </div>
            <div class="col lg:w-36 2xl:w-60 w-3/5">
                <div class="relative">
                    <Dropdown
                        id="new-owner"
                        placeholder="Owner..."
                        data-url="/data/users"
                        v-model="form.owner"
                        @mouseover="showTooltipOn('owner')"
                        @mouseleave="hideTooltipOn"
                        @focus="focusField('owner')"
                        @blur="unfocusField"
                    />
                    <tool-tip v-show="hasEnabledTooltips && !form.owner?.length && focussedField !== 'owner' && showingTooltipOn === 'owner'">If you name an Owner, Sympmy Beat will build up a list of people for you. Just leave blank if you wish the action to be assigned to you</tool-tip>
                </div>
            </div>
            <div class="col lg:w-28 2xl:w-40 w-2/5 relative" v-click-away="onClickAwayDate">
                <div class="relative">
                    <input type="text" class="rounded-br-none"
                           @mouseover="showTooltipOn('due')"
                           @mouseleave="hideTooltipOn"
                           @focus="dateRoughFocusedCustom"
                           placeholder="Due..." v-model="form.due_date"
                    >
                    <tool-tip v-show="hasEnabledTooltips && !form.due?.length && focussedField !== 'due' && showingTooltipOn === 'due'">If you don't know the Due date yet, enter a ballpark such as 'End of the Month' or 'Q3', or leave this blank</tool-tip>
                </div>
                <div class="date-drop" v-if="dateDrop && focussedField === 'due'">
                    <DatePicker v-model="form.due_date" :min-date="todaysDate" :min-date-minus-1="todaysDateMinus1" @calendarSelect="calendarSelected" />
                </div>
            </div>
            <div class="col lg:w-auto w-full">
                <input type="submit" value="Add" @click="submitForm" @focus="focusField('submit')" @blur="unfocusField" class="btn btn-add" :style="{cursor: !form.description?.length ? 'not-allowed' : 'pointer'}">
            </div>
        </div>
    </div>
</template>
<script setup>
import {onMounted, computed, ref, watch} from "vue";
import PrioritySelector from '@components/PrioritySelector.vue'
import TypeSelector from '@components/TypeSelector.vue'
import DatePicker from '@components/DatePicker.vue'
import AutoComplete from '@components/AutoComplete.vue'
import ToolTip from "@components/ToolTip";
import {useForm, usePage} from "@inertiajs/inertia-vue3";
import useDatePicker from "@composables/useDatePicker";
import {Inertia} from "@inertiajs/inertia";
import Dropdown from '@components/Dropdown.vue'

const activeProjectName = computed(() => usePage().props?.value?.project ?? null)
watch(activeProjectName, (newVal) => form.project = newVal ? newVal : "")

const form = useForm({
    description: '',
    type: '',
    priority: '',
    project: activeProjectName.value ?? "",
    owner: '',
    due_date: '',
})

function useFocusable() {
    const description = ref(null)
    onMounted(() => {
        description.value.focus();
    });

    const focussedField = ref("")

    const focusField = (label) => {
        focussedField.value = label
    }
    const unfocusField = () => {
        focussedField.value = ""
        showingTooltipOn.value = ""
    }

    return {description, focussedField, focusField, unfocusField}
}

const {description, focussedField, focusField, unfocusField} = useFocusable()


const tooltipFocus = ref("")



const showingTooltipOn = ref("")
const showTooltipOn = (field) => {
    setTimeout(() => {
        showingTooltipOn.value = field
    }, 300)
}
const hideTooltipOn = () => {
    showingTooltipOn.value = ""
}



const {dateDrop, onClickAwayDate, dateRoughFocused} = useDatePicker(form, 'due_date')

const dateRoughFocusedCustom = () => {
    focusField('due')
    dateRoughFocused()
}

const emits = defineEmits(['added'])

const submitForm = () => {
    if (form.description?.length) {
        form.post("/add-action", {
            preserveScroll: true,
            preserveState: (page) => Object.keys(page.props.errors).length,
            onSuccess: (visit) => {
                form.reset()
                form.project = activeProjectName.value ?? ""
                emits('added')
            },
            onFinish: (visit) => {
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur()
                }
            }
        })
    }
}

const hasEnabledTooltips = computed(() => usePage().props.value.user?.has_enabled_tooltips)

const todaysDate = computed(() => {
    let today = new Date()
    return today.toLocaleString('en-UK', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    })
})
const todaysDateMinus1 = computed(() => {
    let today = new Date()
    const yesterday = new Date(today)

    yesterday.setDate(yesterday.getDate() - 1)
    return yesterday.toLocaleString('en-UK', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    })
})



const calendarSelected = () => {
    setTimeout(() => onClickAwayDate(), 100)
}

</script>
<style type="postcss">
    .add-new-bar {
        @apply bg-ddbg text-white lg:p-5 p-4;
    }
    .btn-add {
        cursor:pointer; line-height:18px;
        @apply lg:w-32 lg:w-24 w-full text-center border font-semibold
        bg-primary border-primary text-white
        hover:bg-white hover:border-white hover:text-primary
        focus:bg-white focus:border-white focus:text-primary
    }
    .add-new-bar [type=text],
    .add-new-bar select,
    .add-new-bar .ps-selected-val {
        @apply text-gray-700 appearance-none bg-white px-4 py-2 text-sm font-semibold;
    }
    .add-new-bar [type=text]:placeholder,
    .add-new-bar select:placeholder,
    .add-new-bar .ps-selected-val:placeholder {
        @apply font-normal italic
    }
    .add-new-bar .ps-selected-val--default {
        @apply text-light font-normal;
    }
    .add-new-bar .ps-selected-val.critical {
        @apply bg-danger text-white not-italic font-semibold
    }
    .add-new-bar .ps-selected-val.high {
        @apply bg-error text-white not-italic font-semibold
    }
    .add-new-bar .ps-selected-val.medium {
        @apply bg-warning text-white not-italic font-semibold
    }
    .add-new-bar .ps-selected-val.low {
        @apply bg-success text-white not-italic font-semibold
    }
    .add-new-bar .col,
    .add-new-bar .col-auto {
        @apply lg:p-0 p-2
    }
    .add-new-bar .auto-complete .col,
    .add-new-bar .auto-complete .col-auto {
        padding: initial;
    }
    .tooltip {
        @apply absolute left-0 top-full pt-4 z-40
    }
    .tooltip-message { max-width:160px;
        @apply bg-xdark text-white px-3 py-2 rounded-md leading-6 text-center font-semibold relative;
    }
    .tooltip-message:before { content:''; border:0 solid transparent; border-left-width:6px; border-right-width:6px; border-bottom:10px solid var(--xdark);
        @apply absolute left-0 right-0 mx-auto bottom-full w-0 h-0
    }
    @media(min-width:890px) {
        .gap-sm {
            gap:12px;
        }
    }
</style>
