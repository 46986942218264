<template>
    <LineChart v-bind="lineChartProps" :options="options" />
</template>

<script setup>
import {Chart, registerables} from 'chart.js'
import { LineChart, useLineChart } from 'vue-chart-3';
import {computed, ref} from 'vue'

Chart.register(...registerables)

const props = defineProps({
    monthLabels: {
        type: Array,
        required: true
    },
    yearLabels: {
        type: Array,
        required: true
    },
    datasets: {
        type: Object,
        required: true
    },
    dataKey: {
        type: String,
        required: true
    },
    dataKeyTwo: {
        type: String,
        required: true
    },
})

const options = ref({
    responsive: true,
    plugins: {
        tooltip: {
            enabled: true,
            callbacks: {
                label: function(context) {
                    return context.dataset.label + ': ' + context.raw;
                }
            }
        },
    },
})
const chartData = computed(() => ({
    labels: props.monthLabels,
    datasets: [
        {
            label: props.yearLabels[props.yearLabels.length - 1],
            data: props.datasets[props.yearLabels[props.yearLabels.length - 1]][props.dataKey][props.dataKeyTwo],
            backgroundColor: '#fe3562',
            tension: 0.4,
            responsive: true,
            legend:false
        },
        {
            label: props.yearLabels[props.yearLabels.length - 2],
            data: props.datasets[props.yearLabels[props.yearLabels.length - 2]][props.dataKey][props.dataKeyTwo],
            backgroundColor: '#9CABC7',
            tension: 0.4,
            responsive: true,
            legend:false
        },
        {
            label: props.yearLabels[props.yearLabels.length - 3],
            data: props.datasets[props.yearLabels[props.yearLabels.length - 3]][props.dataKey][props.dataKeyTwo],
            backgroundColor: '#8ff0e8',
            tension: 0.4,
            responsive: true,
            legend:false
        }
    ]
}))

const {lineChartProps, lineChartRef} = useLineChart({
    chartData,
})
</script>

<style>
</style>
