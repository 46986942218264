<template>
    <BarChart v-bind="lineChartProps" :options="options" />
</template>

<script setup>
import { Chart, registerables } from 'chart.js'
import { BarChart, useLineChart } from 'vue-chart-3'
import { ref, computed, defineComponent } from 'vue'

Chart.register(...registerables)

const props = defineProps({
    monthLabels: {
        type: Array,
        required: true
    },
    yearLabels: {
        type: Array,
        required: true
    },
    datasets: {
        type: Object,
        required: true
    },
})
const options = ref({
    responsive: true,
    plugins: {
        tooltip: {
            enabled: true,
            callbacks: {
                label: function(context) {
                    return context.dataset.label + ': ' + context.raw;
                }
            }
        },
    },
})
const chartData = computed(() => ({
    labels: props.monthLabels,
    datasets: [
        {
            label: 'Users',
            data: props.datasets[props.yearLabels[0]].users.total,
            backgroundColor: '#fe3562',
            tension: 0.4,
            responsive: true,
            legend:false
        },
        {
            label: 'Actions',
            data: props.datasets[props.yearLabels[0]].actions.total,
            backgroundColor: '#9CABC7',
            tension: 0.4,
            responsive: true,
            legend:false
        }
    ]
}))

const { lineChartProps, lineChartRef } = useLineChart({
  chartData,
})

</script>

<style>
</style>
