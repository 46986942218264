<template>
    <div class="relative z-8" :class="{'z-30': showingDropdown}">
        <input type="text" :value="modelValue" class="rounded-br-none" :placeholder="placeholder"
               @input="$emit('update:modelValue', $event.target.value)"
               @focus="focus"
               @blur="blur"
               @keydown.up.prevent="moveFocus(-1)"
               @keydown.down.prevent="moveFocus(1)"
               @keyup.enter.prevent="selectFocusedOption"
        />
        <div class="awesome-dropdown-clear" v-if="modelValue">
            <span @click="clearSelection" @keyup.enter="clearSelection" class="awesome-dropdown-clear-icon"></span>
        </div>
        <span class="awesome-dropdown-new-btn cursor-pointer" v-if="showNewTag" @click="clearSelection" @keyup.enter="clearSelection">NEW</span>
        <div class="awesome-dropdown" v-if="showingDropdown">
            <ul class="awesome-dropdown-options">
                <li
                    v-for="(option, index) in options"
                    :id="id + '-nb-dropdown-' + index"
                    :key="index"
                    @mousedown.prevent="selectOption(option)"
                    class="awesome-dropdown-option awesome-dropdown-option"
                    :class="{'bg-dark': focusedOption === index}"
                >{{ option }}</li>
            </ul>
        </div>
    </div>
</template>
<script setup>
import {ref, watch, computed, nextTick, onMounted} from "vue"
import '@vueform/multiselect/themes/default.css'
import axios from "axios"
import {usePage} from "@inertiajs/inertia-vue3";

const props = defineProps({
    modelValue: {
        type: String,
        default: null
    },
    placeholder: {
        type: String,
        required: true
    },
    dataUrl: {
        type: String,
        required: true
    },
    id: {
        type: String,
        required: true
    },
    newSavedValue: {
        type: String,
        default: ""
    },
})

const emit = defineEmits(['update:modelValue', 'focus', 'blur'])
const activeProjectName = computed(() => usePage().props?.value?.project ?? null)
function useShowNewTag() {
    const initialValue = ref(props.modelValue)
    const showNewTag = computed(() =>
        props.modelValue?.length &&
        props.modelValue.toLowerCase() !== activeProjectName.value?.toLowerCase() &&
        props.modelValue.toLowerCase() !== props.newSavedValue?.toLowerCase() &&
        (options.value?.length
                ? (
                    isFocused.value
                    ? !options.value?.some(option => option?.toLowerCase().startsWith(props.modelValue?.toLowerCase()))
                    : !options.value?.some(option => option?.toLowerCase() === props.modelValue?.toLowerCase())
                )
                : props.modelValue !== initialValue.value
        )
    )

    return {showNewTag}
}
const {showNewTag} = useShowNewTag()

function useFocusable() {

    const isFocused = ref(false)

    const focus = () => {
        isFocused.value = true
        emit('focus')
        fetchOptions()
    }
    const blur = () => {
        isFocused.value = false
        focusedOption.value = null
        emit('blur')
    }

    return {isFocused, focus, blur}
}
const {isFocused, focus, blur} = useFocusable()

function useMoveable() {
    const focusedOption = ref(null)

    const selectFocusedOption = () => {
        if (focusedOption.value !== null && focusedOption.value in options.value) {
            selectOption(options.value[focusedOption.value])
        } else {
            blur()
        }
    }
    const moveFocus = (direction) => {
        const optionsLength = options.value?.length ?? 0;
        const newFocus = focusedOption.value !== null ? focusedOption.value + direction : 0;

        if(newFocus < 0) {
            focusedOption.value = optionsLength - 1;
        } else if(newFocus >= optionsLength) {
            focusedOption.value = 0;
        } else {
            focusedOption.value = newFocus;
        }

        scrollIntoView();
    }

    const scrollIntoView = () => {
        nextTick(() => {
            if (focusedOption.value !== null) {
                const dropdownOption = document.querySelector('#' + props.id + '-nb-dropdown-' + focusedOption.value)
                const dropdownList = dropdownOption?.parentElement?.parentElement;
                if (dropdownOption && dropdownList) {
                    const optionRect = dropdownOption.getBoundingClientRect();
                    const listRect = dropdownList.getBoundingClientRect();

                    if (optionRect.bottom > listRect.bottom) {
                        dropdownList.scrollTop += optionRect.bottom - listRect.bottom;
                    } else if (optionRect.top < listRect.top) {
                        dropdownList.scrollTop += optionRect.top - listRect.top;
                    }
                }
            }
        })
    }

    return {focusedOption, selectFocusedOption, moveFocus}
}
const {focusedOption, selectFocusedOption, moveFocus} = useMoveable()

function useOptions() {
    const showingDropdown = computed(() => isFocused.value && options.value?.length >= 1);

    const options = ref([])

    watch(() => props.modelValue, () => fetchOptions())

    const fetchOptions = () => {
        let url = props.dataUrl
        if (props.modelValue) {
            let params = new URLSearchParams();
            params.append('terms', encodeURIComponent(props.modelValue))

            let suffix = params.toString()
            url +=  suffix ? '?' + suffix : ''
        }
        axios.get(url)
            .then((resp) => {
                options.value = resp.data
            })
            .catch((err) => {
            })
    }

    return {showingDropdown, options, fetchOptions}
}
const {showingDropdown, options, fetchOptions} = useOptions()

function useSelect() {
    const selectOption = (option) => {
        emit('update:modelValue', option)
        blur()
    }
    const clearSelection = () => {
        selectOption(null)
    }
    return {selectOption, clearSelection}
}
const {selectOption, clearSelection} = useSelect()

</script>

<style type="postcss" scoped>
input {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 20px; /* Adjust based on the size of your clear icon */
}

.awesome-dropdown {
    overflow-x: auto;
}
</style>

<style type="postcss">
    .awesome-dropdown {max-height: 120px;
        @apply bg-ddbg text-white font-medium rounded-none border-none absolute left-0 top-full right-0 overflow-y-scroll;
    }
    .awesome-dropdown-options {
        @apply border border-dark;
    }
    .awesome-dropdown-options li { padding:4px 8px; line-height:15px; height:24px;
        @apply block whitespace-nowrap text-white text-sm border-t-0 border-b border-light cursor-pointer truncate;
    }
    .awesome-dropdown-options li:last-child,
    .awesome-dropdown-no-options:last-child,
    .awesome-dropdown-no-results:last-child {
        @apply border-transparent;
    }
    .awesome-dropdown-options li:hover,
    .awesome-dropdown-options li.is-selected,
    .awesome-dropdown-options li.is-pointed {
        @apply bg-dark;
    }
    .awesome-dropdown-options li.multiselect-option {
        line-hight:15px; padding:4px 8px;
    }
    .awesome-dropdown-clear {
        @apply absolute right-0 top-2.5 z-0 pr-3;
    }
    .awesome-dropdown-caret,
    .awesome-dropdown-clear-icon {width:13px; height:13px;
        @apply cursor-pointer;
    }
    .awesome-dropdown-clear-icon {
        -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
        mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
        background-color: var(--ms-clear-color,#999);
        -webkit-mask-position: center;
        mask-position: center;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        @apply inline-block transition-colors hover:bg-black;
    }
    .awesome-dropdown-new-btn {padding:4px 0.75rem; line-height:20px; height:28px; border-radius:0 4px 0 0;
        @apply bg-success absolute top-0 right-0 font-semibold text-white;
    }
    .owner-stamp .awesome-dropdown-new-btn {
        padding: 2px 0.5rem;
        height: 100%;
    }
</style>
