<template>
    <div class="auto-complete auto-complete--tag" :id="id">
        <Multiselect
            ref="multiselect"
            v-model="modelValue"
            placeholder="Type..."
            :searchable="false"
            :filter-results="false"
            :resolve-on-load="false"
            :clear-on-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :can-deselect="false"
            :can-clear="false"
            :create-option="false"
            :append-new-option="false"
            :hide-selected="false"
            @change="valueChanged"
            @open="$emit('focus')"
            @close="$emit('blur')"
            :options="['Activity', 'Decision']"
        >
            <template v-slot:singlelabel="{ value }">
                {{ value.label }}
            </template>
        </Multiselect>
  </div>
</template>
<script setup>
import {ref} from "vue"
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

const props = defineProps({
    modelValue: {
        type: String,
        default: null
    },
    id: {
        type: String,
        required: true
    }
})

const multiselect = ref(null)

const emit = defineEmits(['update:modelValue', 'focus', 'blur'])
const valueChanged = (val) => {
    emit('update:modelValue', val)
}
</script>

<style type="postcss" scoped>
    .auto-complete .multiselect { min-height:24px;
        @apply block rounded w-full font-semibold relative text-gray-700 appearance-none bg-transparent p-0 rounded-br-none border-none text-sm;
    }
    .add-new-bar .auto-complete .multiselect {min-height:27px;}
    .auto-complete .multiselect-placeholder {
        @apply block px-4 py-1.5 w-full text-sm relative text-light italic font-normal
    }
    .auto-complete .multiselect-search {
        @apply rounded-br-none
    }
    .auto-complete .multiselect.is-active {
        @apply shadow-none
    }
    .auto-complete .multiselect.is-open {
        @apply z-30
    }
    .multiselect-single-label {
        @apply pl-2 pr-6
    }
    .auto-complete .multiselect-single-label-text {
        @apply text-dark appearance-none bg-transparent px-2 py-1.5;
    }
    .auto-complete .multiselect-caret {
        @apply hidden;
    }
    .auto-complete .multiselect-dropdown {
        @apply bg-ddbg text-white font-medium rounded-none border border-dark;
    }
    .auto-complete .multiselect-options li,
    .auto-complete .multiselect-no-options,
    .auto-complete .multiselect-no-results {
        padding:4px 8px; line-height:17px;
        @apply block whitespace-nowrap text-white text-sm border-t-0 border-b border-light;
    }
    .auto-complete .multiselect-options li:last-child,
    .auto-complete .multiselect-no-options:last-child,
    .auto-complete .multiselect-no-results:last-child {
        @apply border-transparent
    }
    .auto-complete .multiselect-options li:hover,
    .auto-complete .multiselect-options li.is-selected,
    .auto-complete .multiselect-options li.is-pointed {
        @apply bg-dark;
    }
    .auto-complete .multiselect-clear {
        @apply absolute right-0 top-2 z-0
    }
    .auto-complete.auto-complete--tag .multiselect {line-height:16px; padding:4px 8px;
        @apply text-gray-700 appearance-none bg-white text-sm font-semibold;
    }
    .auto-complete.auto-complete--tag .multiselect-placeholder {
        @apply absolute left-0 top-0 w-full;
    }
</style>
