<template>
    <div class="flex relative" v-click-away="onClickAway">
        <div class="flex-1" :class="{'relative': baselineVariance?.value}">
            <div class="due-date-wrapper flex items-center" :class="[baselineUrl ? 'due-date-wrapper--baseline-url' : '', isDueDateInThePast ? 'due-date-wrapper--error' : '', isDueDateInThePast ? 'text-white' : 'text-dark']">
                <div class="ml-1.5 mt-1.5">
                    <div class="icon icon--xs" v-if="isDueDateInThePast">
                        <svg class="icon-svg">
                            <use xlink:href="#icon-time"></use>
                        </svg>
                    </div>
                </div>
                <input type="text" v-model="form.date" @focus="dateRoughFocused" @click="dateRoughFocused" @keyup.enter="onClickAway" placeholder="Date due..." class="due-date-input" />
            </div>
            <div class="baseline-date" v-if="baseline">{{baseline}}</div>
            <div class="date-drop" v-if="dateDrop">
                <DatePicker v-model="form.date" :min-date="minDate" :min-date-minus-1="minDateMinus1" @calendarSelect="calendarSelected" />
            </div>
        </div>
        <div v-if="baselineUrl" class="col baselining__navs" :class="{'baselining__navs--open': baselineDrop}">
            <button type="button" class="baselining__nav noprint nb-focus" @click="showBaselineDrop" @keyup.enter="showBaselineDrop" tabindex="0">
                ...
            </button>
            <ul class="dropdown" v-if="baselineDrop">
                <li><div @click="submitBaseline" @keyup.enter="submitBaseline" tabindex="0" class="cursor-pointer nb-focus">Baseline</div></li>
            </ul>
        </div>
        <div v-else-if="baselineVariance?.value" class="col baselining__navs" :class="{'baseline': baselineVariance?.value}">
            <span class="pl-1 pt-1 inline-block font-semibold" :class="{'text-danger': baselineVariance.isPlus, 'text-success': !baselineVariance.isPlus}">{{ (baselineVariance.isPlus ? '+' : '-') + baselineVariance.value }}</span>
        </div>
    </div>
</template>
<script setup>
import {ref, watch} from "vue"
import {useForm} from "@inertiajs/inertia-vue3"
import useDatePicker from "@composables/useDatePicker"
import DatePicker from '@components/DatePicker.vue'
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    url: {
        type: String,
        required: true
    },
    dateProp: {
        type: String,
        default: null
    },
    baseline: {
        type: String,
        default: null
    },
    baselineUrl: {
        type: String,
        default: null
    },
    baselineVariance: {
        type: Object,
        default: {}
    },
    isDueDateInThePast: {
        type: Boolean,
        default: false
    },
    minDateMinus1: {
        type: String,
        default: null
    },
    minDate: {
        type: String,
        default: null
    },
    closePopoverProp: {
        type: Boolean,
        default: false
    },
})

const emits = defineEmits(['popoverClosed'])
watch(() => props.closePopoverProp, (newVal) => {
    if (newVal) {
        onClickAwayDate()
        emits('popoverClosed')
    }
})


const baselineDrop = ref(false)

const form = useForm({
    date: props.dateProp,
})

const {dateDrop, onClickAwayDate, dateRoughFocused} = useDatePicker(form, 'date')

const onClickAway = () => {
    baselineDrop.value = false
    onClickAwayDate()
    if (form.isDirty) {
        submitForm()
    }
}

const submitForm = () => {
    form.post(props.url, {
        preserveScroll: true,
        preserveState: (page) => true,
        onFinish: (p) => {
            baselineDrop.value = false
            onClickAwayDate()
        }
    })
}

const showBaselineDrop = () => {
    dateDrop.value = false
    baselineDrop.value = true
}

const submitBaseline = () => {
    baselineDrop.value = false
    Inertia.post(props.baselineUrl,  null, {
        preserveScroll: true,
        preserveState: (page) => true,
        onFinish: (p) => {
            baselineDrop.value = false
            onClickAwayDate()
        }
    })
}

const calendarSelected = () => {
    setTimeout(() => onClickAway(), 100)
}

</script>

<style type="postcss">
    .baselining__navs {
        @apply rounded-tr bg-silver relative text-right border-l border-white;
    }
    .baselining__navs.baseline {
        @apply bg-transparent;
    }
    .baselining__nav {
        @apply pt-1 pl-1 pr-1 font-semibold;
    }
    .baselining__navs--open {
        @apply bg-ddbg text-white;
    }
    .due-date-wrapper { border-radius:4px;
        @apply bg-silver rounded-br-none
    }
    .due-date-wrapper--baseline-url {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .due-date-wrapper--error { border-radius:4px;
        @apply bg-error
    }
    .due-date-input {
        @apply bg-transparent not-italic font-semibold
    }
    .baseline-date { font-size:11px; border-radius:4px;
        @apply mx-auto mt-1 py-0 px-2 bg-highlight font-semibold
    }
</style>
