<template>
    <div class="auto-complete" :id="id" style="height: 100%;">
        <Multiselect
            ref="multiselect"
            v-model="modelValue"
            :placeholder="placeholder"
            :filter-results="false"
            :min-chars="1"
            :resolve-on-load="true"
            :clear-on-search="true"
            :clear-on-select="true"
            :close-on-select="true"
            :can-deselect="false"
            :can-clear="true"
            :delay="0"
            :searchable="true"
            :create-option="true"
            :append-new-option="true"
            :add-option-on="['enter']"
            :hide-selected="true"
            @change="valueChanged"
            @open="clearSelected"
            :options="async function(query) {
                return await fetchOptions(query)
            }"
        >
            <template v-slot:clear v-if="highlightNew && isNewOption">
                <span v-if="isNewOption" class="multiselect-clear bg-success text-white" style="top: 0; height: 100%; padding: 0; border-top-right-radius: 4px;" @click="clearSelect" @keyup.enter="clearSelect">
                    <span class="m-auto px-3">NEW</span>
                </span>
            </template>
        </Multiselect>
  </div>
</template>
<script setup>
import {ref, onMounted, watch} from "vue"
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
import axios from "axios"

const props = defineProps({
    placeholder: {
        type: String,
        required: true
    },
    dataUrl: {
        type: String,
        required: true
    },
    modelValue: {
        type: String,
        default: null
    },
    id: {
        type: String,
        required: true
    },
    highlightNew: {
        type: Boolean,
        default: false
    }
})

const multiselect = ref(null)
const options = ref([])
const isNewOption = ref(false)

watch(() => props.modelValue, async (newValue) => {
    isNewOption.value = !options.value.includes(newValue);
});

onMounted(() => {
    var parentContainer = document.querySelector('#' + props.id);
    var inputField = parentContainer.querySelector('.multiselect-search');
    inputField.addEventListener('blur', blurred)
})

const blurred = (e) => {
    if (e.target.value) {
        multiselect.value.preparePointer(e.target.value)
        multiselect.value.selectPointer(e.target.value)
        multiselect.value.close()
    }
    emit('blur')
}

const clearSelect = (e) => {
    if (props.highlightNew) {
        multiselect.value.clear()
    }
}

const clearSelected = (e) => {
    emit('focus')
}

const emit = defineEmits(['update:modelValue', 'focus', 'blur'])
const valueChanged = (val) => {
    emit('update:modelValue', val)
}

onMounted(() => {
    // get the initial set of options for NEW check
    if (props.highlightNew) {
        axios.get(props.dataUrl)
            .then((resp) => {
                options.value = resp.data
            })
            .catch((err) => {
                console.log(err)
            })
    }
})


const fetchOptions = async (terms) => {
    let arr = []

    let url = props.dataUrl

    if (terms) {
        let params = new URLSearchParams();
        params.append('terms', encodeURIComponent(terms))

        let suffix = params.toString()
        url +=  suffix ? '?' + suffix : ''
    }

    await axios.get(url)
        .then((resp) => {
            arr = resp.data
        })
        .catch((err) => {
            console.log(err)
        })

    return arr
}
</script>

<style type="postcss">
    .auto-complete .multiselect { min-height:24px; border-radius:4px;
        @apply block w-full font-semibold relative text-light appearance-none bg-transparent p-0 rounded-br-none border-none text-sm;
    }
    .add-new-bar .auto-complete .multiselect {min-height:27px; }
    .add-new-bar .auto-complete .multiselect [type=text] {
        @apply pl-1;
    }
    .auto-complete .multiselect-placeholder { line-height:16px; padding:4px 8px;
        @apply block w-full text-sm relative text-light italic font-normal
    }
    .auto-complete .multiselect-search { padding-left:8px;
        @apply rounded-br-none
    }
    .auto-complete .multiselect.is-active {
        @apply shadow-none
    }
    .auto-complete .multiselect.is-open {
        @apply z-30
    }
    .multiselect-single-label { line-height:16px; padding:0 15px 0 0; }
    .auto-complete .multiselect-single-label-text {line-height:16px; padding:4px 8px;
        @apply text-dark appearance-none bg-transparent;
    }
    .auto-complete .multiselect-caret {
        @apply hidden;
    }
    .auto-complete .multiselect-dropdown {
        @apply bg-ddbg text-white font-medium rounded-none border-none;
    }
    .add-new-bar .auto-complete .multiselect-dropdown {
        @apply right-0;
    }
    .auto-complete .multiselect-options {
        @apply border border-dark;
    }
    .auto-complete .multiselect-options li,
    .auto-complete .multiselect-no-options,
    .auto-complete .multiselect-no-results { padding:4px 8px; line-height:15px; height:24px;
        @apply block whitespace-nowrap text-white text-sm border-t-0 border-b border-light;
    }
    .auto-complete .multiselect-options li:last-child,
    .auto-complete .multiselect-no-options:last-child,
    .auto-complete .multiselect-no-results:last-child {
        @apply border-transparent;
    }
    .auto-complete .multiselect-options li:hover,
    .auto-complete .multiselect-options li.is-selected,
    .auto-complete .multiselect-options li.is-pointed {
        @apply bg-dark;
    }
    .auto-complete .multiselect-options li.multiselect-option {
        line-hight:15px; padding:4px 8px;
    }
    .auto-complete .multiselect-clear {
        @apply absolute right-0 top-2.5 z-0
    }
    .auto-complete .multiselect-caret,
    .auto-complete .multiselect-clear-icon {width:13px; height:13px;}
    .auto-complete .multiselect {
        height: 100%;
    }
</style>
