<template>
    <div class="dimension-selector auto-complete auto-complete--tag" :id="id" :class="{'auto-complete--default': modelValue === null}">
        <Multiselect
            ref="multiselect"
            v-model="modelValue"
            placeholder="Priority..."
            :searchable="false"
            :filter-results="false"
            :resolve-on-load="false"
            :clear-on-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :disabled="!isOpen"
            :can-deselect="false"
            :can-clear="false"
            :create-option="false"
            :append-new-option="false"
            :hide-selected="false"
            @change="valueChanged"
            @open="$emit('focus')"
            @close="$emit('blur')"
            :options="['None', 'Scope', 'Time', 'Cost', 'Quality', 'Risk', 'Benefits']"
        >
            <template v-slot:placeholder>
                <div class="ds-selected-val ds-selected-val--default">
                    <div class="flex items-center">
                        <div class="icon icon--xs mr-2">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-hex-0"></use>
                            </svg>
                        </div>
                        Dimension
                    </div>
                </div>
            </template>
            <template v-slot:option="{ option, search }">
                <div class="ds-options" :class="{'font-light italic': option.label === 'None'}">{{ option.label }}</div>
            </template>
            <template v-slot:singlelabel="{ value }">
                <div class="ds-selected-val" :class="{'ds-selected-val--default': value.value === 'Dimension' || value.value === 'None'}">
                    <div class="flex items-center">
                        <div class="icon icon--xs mr-2" v-if="value.value === 'Dimension' || value.value === 'None'">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-hex-0"></use>
                            </svg>
                        </div>
                        <div class="icon icon--xs mr-2" v-if="value.value === 'Scope'">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-hex-1"></use>
                            </svg>
                        </div>
                        <div class="icon icon--xs mr-2" v-if="value.value === 'Time'">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-hex-2"></use>
                            </svg>
                        </div>
                        <div class="icon icon--xs mr-2" v-if="value.value === 'Cost'">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-hex-6"></use>
                            </svg>
                        </div>
                        <div class="icon icon--xs mr-2" v-if="value.value === 'Quality'">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-hex-3"></use>
                            </svg>
                        </div>
                        <div class="icon icon--xs mr-2" v-if="value.value === 'Risk'">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-hex-5"></use>
                            </svg>
                        </div>
                        <div class="icon icon--xs mr-2" v-if="value.value === 'Benefits'">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-hex-4"></use>
                            </svg>
                        </div>
                        {{ value.label === 'None' ? 'Dimension' : value.label }}
                    </div>
                </div>
            </template>
        </Multiselect>
    </div>
</template>
<script setup>
import {ref} from "vue"
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

const props = defineProps({
    modelValue: {
        type: String,
        default: null
    },
    id: {
        type: String,
        required: true
    },
    isOpen: {
        type: Boolean,
        required: true
    }
})

const multiselect = ref(null)

const emit = defineEmits(['update:modelValue', 'focus', 'blur'])
const valueChanged = (val) => {
    emit('update:modelValue', val)
}
</script>

<style type="postcss" scoped>

/*.dimension-selector.auto-complete /deep/ .multiselect .multiselect-dropdown {*/
/*    display: block !important;*/
/*}*/

.dimension-selector {
    @apply relative inline-block;
}

.dimension-selector.auto-complete /deep/ .multiselect {
    padding: 0;
    min-height: 21px;
}

.dimension-selector.auto-complete /deep/ .multiselect .multiselect-dropdown .multiselect-options {
    max-height: fit-content;
}

.dimension-selector.auto-complete /deep/ .multiselect .multiselect-dropdown {
    @apply w-32;
    overflow: hidden;
}

.dimension-selector.auto-complete /deep/ .multiselect .ds-options {
    @apply w-full z-10;
}
.dimension-selector.auto-complete /deep/ .multiselect .ds-selected-val { line-height:13px; padding:4px 8px;
    @apply rounded-md rounded-br-none bg-silver text-sm transition-all w-32 font-semibold;
}
.ds-selected-val--default {
    @apply  italic font-thin
}


.auto-complete /deep/ .multiselect-dropdown {
    @apply bg-ddbg text-white font-medium rounded-none border border-dark;
}
.auto-complete /deep/ .multiselect-options li,
.auto-complete /deep/ .multiselect-no-options,
.auto-complete /deep/ .multiselect-no-results {
    @apply py-1 px-2 block whitespace-nowrap text-white text-sm border-t-0 border-b border-light ;
    padding:4px 8px
}
.auto-complete /deep/ .multiselect-options li:last-child,
.auto-complete /deep/ .multiselect-no-options:last-child,
.auto-complete /deep/ .multiselect-no-results:last-child {
    @apply border-transparent
}
.auto-complete /deep/ .multiselect-options li:hover,
.auto-complete /deep/ .multiselect-options li.is-selected,
.auto-complete /deep/ .multiselect-options li.is-pointed {
    @apply bg-dark;
}
.auto-complete /deep/ .multiselect-clear {
    @apply absolute right-0 top-2 z-0
}
.auto-complete.auto-complete--tag /deep/ .multiselect {
    @apply text-gray-700 text-sm font-semibold bg-transparent;
}
.auto-complete.auto-complete--tag /deep/ .multiselect-placeholder {
    @apply absolute left-0 top-0 w-full;
}
</style>
