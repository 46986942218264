<template>
    <div class="page__contents">
        <DueBar v-if="projects?.length" :actions="dueActions" :due="due" @due-value-changed="dueValueChanged" />
        <div class="p-8 page-head flex gap-5" :class="{'flex-col lgx:flex-row lgx:items-center lgx:justify-between': isSearchActive, 'justify-between items-center': !isSearchActive}">
            <div class="flex items-center">
                <div class="text-xxl text-dark font-light">
                    <span class="inline-block"><span class="font-semibold">{{ openActionsCount }}</span> open {{ openActionsCount > 1 || openActionsCount === 0 ? "actions" : "action" }} in <span class="font-semibold">{{ openProjectsCount }} {{ openProjectsCount > 1 || openProjectsCount === 0 ? "projects" : "project" }}</span></span>
                    <span class="inline-block"></span>
                </div>
            </div>
            <div class="flex items-center justify-between">
                <div class="flex items-center flex-wrap gap-3">
                    <div v-if="activeSearchTerms.length" class="text-md lgx:ml-auto"><span class="font-semibold text-black">{{ actions.length }}</span> with</div>
                    <div class="terms-list flex flex-wrap gap-x-3 gap-y-2" v-if="isSearchActive" :class="{'lgx:ml-auto': !activeSearchTerms.length}">
                        <div class="border text-dark font-semibold rounded pl-2 pr-1 py-0.5 flex items-center gap-2" v-for="(option , index) in activeSearchTerms" :key="index" :class="{'bg-secondary border-secondary text-dark':option.term == 'Notes', 'border-black bg-white':option.term != 'Notes'}">
                            <div class="icon icon--small">
                                <svg class="icon-svg">
                                    <use v-if="option.type === 'label'" xlink:href="#icon-label"></use>
                                    <use v-else-if="option.type === 'person'" xlink:href="#icon-person"></use>
                                    <use v-else-if="option.type === 'project'" xlink:href="#icon-project"></use>
                                    <use v-else-if="option.type === 'dimension' && option.value === 'Scope'" xlink:href="#icon-hex-1"></use>
                                    <use v-else-if="option.type === 'dimension' && option.value === 'Time'" xlink:href="#icon-hex-2"></use>
                                    <use v-else-if="option.type === 'dimension' && option.value === 'Quality'" xlink:href="#icon-hex-3"></use>
                                    <use v-else-if="option.type === 'dimension' && option.value === 'Benefits'" xlink:href="#icon-hex-4"></use>
                                    <use v-else-if="option.type === 'dimension' && option.value === 'Risk'" xlink:href="#icon-hex-5"></use>
                                    <use v-else-if="option.type === 'dimension' && option.value === 'Cost'" xlink:href="#icon-hex-6"></use>
                                    <use v-else xlink:href="#icon-text"></use>
                                </svg>
                            </div>
                            {{ option.value }}
                            <button class="w-5 h-5 hover:bg-silver rounded text-dark flex items-center justify-center p-1.5" @click="deselectSearchOption(option)" @keyup.enter="deselectSearchOption(option)">
                                <div class="icon icon--xxs">
                                    <svg class="icon-svg">
                                        <use xlink:href="#icon-close-light"></use>
                                    </svg>
                                </div>
                            </button>
                        </div>
                        <button v-if="hasTextSearchOption" class="border text-dark font-semibold rounded pl-2 pr-1 py-0.5 flex items-center gap-2 bg-secondary border-secondary" @click="toggleIncludeNotesInSearch" @keyup.enter="toggleIncludeNotesInSearch" tabindex="0">
                            <div class="icon icon--small">
                                <svg class="icon-svg">
                                    <use xlink:href="#icon-search-notes"></use>
                                </svg>
                            </div>
                            Notes
                            <div v-show="includingNotesInSearch === '1'" class="w-5 h-5 bg-white hover:bg-silver rounded-full text-dark flex items-center justify-center">
                                <div class="icon icon--xxs">
                                    <svg class="icon-svg">
                                        <use xlink:href="#icon-tick"></use>
                                    </svg>
                                </div>
                            </div>
                        </button>
                    </div>
                    <div class="relative z-30 lgx:ml-auto">
                        <div class="rounded border" :class="{'border-lighter border-b-lighter':isSearchActive, 'border-ddbg':!isSearchActive, 'rounded-b-none':matchingSearchOptions.length}">
                            <div class="flex">
                                <button type="button" class="cursor-pointer w-9 h-9 flex items-center justify-center" tabindex="0" @click="showSearch" @keyup.enter="showSearch" :class="{'text-light':isSearchActive, 'text-ddbg':!isSearchActive}">
                                    <div class="icon icon--md" :class="{'text-black': searchQuery.length}">
                                        <svg class="icon-svg">
                                            <use xlink:href="#icon-search"></use>
                                        </svg>
                                    </div>
                                </button>
                                <input type="text" ref="searchField" v-model="searchQuery" class="max-w-full transition-all px-0" :class="{'w-72 sm:w-96':isSearchActive, 'w-0':!isSearchActive}" @blur="hideSearch" @focus="clearSearchTimeout" @keyup.enter="addTextQuery" tabindex="0">
                            </div>
                        </div>
                        <div v-show="matchingSearchOptions.length" ref="nbSearchOptionsDropdown" class="absolute left-0 top-full bg-lighter pl-9 pr-2 w-full rounded-b cursor-pointer max-h-40 overflow-y-auto transition-all" :class="{'h-auto':searchQuery.length > 3, 'h-0':searchQuery.length < 3}">
                            <div class="flex flex-wrap gap-x-3 gap-y-2 py-2 text-dark font-semibold">
                                <div class="bg-white rounded px-2 py-1 flex items-center gap-2" v-for="(option , index) in matchingSearchOptions" :key="index" @click="selectSearchOption(option)" @keyup.enter="selectSearchOption(option)" tabindex="0">
                                    <div class="icon icon--small">
                                        <svg class="icon-svg">
                                            <use v-if="option.type === 'label'" xlink:href="#icon-label"></use>
                                            <use v-else-if="option.type === 'person'" xlink:href="#icon-person"></use>
                                            <use v-else-if="option.type === 'project'" xlink:href="#icon-project"></use>
                                            <use v-else-if="option.type === 'dimension' && option.value === 'Scope'" xlink:href="#icon-hex-1"></use>
                                            <use v-else-if="option.type === 'dimension' && option.value === 'Time'" xlink:href="#icon-hex-2"></use>
                                            <use v-else-if="option.type === 'dimension' && option.value === 'Quality'" xlink:href="#icon-hex-3"></use>
                                            <use v-else-if="option.type === 'dimension' && option.value === 'Benefits'" xlink:href="#icon-hex-4"></use>
                                            <use v-else-if="option.type === 'dimension' && option.value === 'Risk'" xlink:href="#icon-hex-5"></use>
                                            <use v-else-if="option.type === 'dimension' && option.value === 'Cost'" xlink:href="#icon-hex-6"></use>
                                            <use v-else xlink:href="#icon-text"></use>
                                        </svg>
                                    </div>
                                    {{ option.value }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ml-2 flex items-center gap-2">
                    <InertiaLink href="/home" class="cursor-pointer flex items-center text-light rounded border border-light bg-gray-100" style="height: 29px;width: 29px;">
                        <div class="icon icon--medium m-auto">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-project-tile"></use>
                            </svg>
                        </div>
                    </InertiaLink>
                    <InertiaLink href="/all-actions" class="cursor-pointer flex items-center text-light rounded border border-light" style="height: 29px;width: 29px;">
                        <div class="icon icon--medium m-auto">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-action-list"></use>
                            </svg>
                        </div>
                    </InertiaLink>
                </div>
            </div>
        </div>
        <div class="flex-table" :style="{ marginTop: actionMarginTop }">
            <div v-if="actions.length" class="ft__head">
                <div class="ft__row border-b-2 border-gray-200">
                    <div class="ft__col ft__col--icon">
                    </div>
                    <div class="ft__col ft__col--id cursor-pointer" @click="changeSort('project_index_id')" :class="{'ft__col--sorted': q_col === 'project_index_id'}">
                        ID
                        <SortIcon v-if="q_col === 'project_index_id'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col ft__col--desc cursor-pointer" @click="changeSort('description')" :class="{'ft__col--sorted': q_col === 'description'}">
                        Description
                        <SortIcon v-if="q_col === 'description'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col ft__col--desc cursor-pointer" @click="changeSort('project')" :class="{'ft__col--sorted': q_col === 'project'}">
                        Project
                        <SortIcon v-if="q_col === 'project'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col ft__col--sm ft__col--priority cursor-pointer" @click="changeSort('priority')" :class="{'ft__col--sorted': q_col === 'priority'}">
                        Priority
                        <SortIcon v-if="q_col === 'priority'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col cursor-pointer ft__col--sm" @click="changeSort('created_at')" :class="{'ft__col--sorted': q_col === 'created_at'}">
                        Raised on
                        <SortIcon v-if="q_col === 'created_at'" :is-up="q_dir === 'up'" />
                    </div>
                    <!-- <div class="ft__col cursor-pointer ft__col--lg hidden 2xl:block">
                    </div> -->
                    <div class="ft__col cursor-pointer ft__col--md ft__col--due" @click="changeSort('due_date')" :class="{'ft__col--sorted': q_col === 'due_date'}">
                        Due
                        <SortIcon v-if="q_col === 'due_date'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col cursor-pointer ft__col--owner" @click="changeSort('owner')" :class="{'ft__col--sorted': q_col === 'owner' }">
                        Owner
                        <SortIcon v-if="q_col === 'owner'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col ft__col--last ft__col--actions">
                    </div>
                </div>
            </div>
            <div class="ft__body all__actions" style="padding-bottom: 275px;">
                <ActionRow :isOpen="true" v-for="(action, index) in actions" :key="action.id" :action="action" :is-active="false" :projects="projects" :show-project-column="true" />
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue";
    import ActionRow from '@components/ActionRow.vue'
    import SortIcon from '@icons/SortIcon.vue'
    import {Inertia} from "@inertiajs/inertia";
    import axios from "axios";
    import DueBar from '@components/DueBar.vue'

    const props = defineProps({
        dueActions: {
            type: Array,
            default: []
        },
        actions: {
            type: Array,
            default: []
        },
        openActionsCount: {
            type: Number,
            required: true
        },
        openProjectsCount: {
            type: Number,
            required: true
        },
        projects: {
            type: Array,
            required: true
        },
        q_col: {
            type: String,
            required: true
        },
        q_dir: {
            type: String,
            required: true
        },
        activeSearchOptionsProp: {
            type: Array,
            default: []
        },
        includeNotesProp: {
            type: String,
            required: true
        },
        due: {
            type: String,
            required: true
        },
    })

    const oldActions = ref(props.actions)

    watch(() => props.actions, (newA, oldA) => {
        oldActions.value = oldA
    })

    const qCol = ref(props.q_col)
    const qDir = ref(props.q_dir)

    const changeSort = (column) => {
        qDir.value = qCol.value !== column ? 'up' : (qDir.value === 'up' ? 'down' : 'up')
        qCol.value = column
        reloadPage()
    }

    const isSearchActive = ref(false)
    const searchQuery = ref("")
    const activeSearchTerms = ref(props.activeSearchOptionsProp) // {type: "", value: ""}
    const matchingSearchOptions = ref([]) // {type: "", value: ""}

    onMounted(() => {
        if (props.activeSearchOptionsProp?.length) {
            isSearchActive.value = true
        }
    })

    const showSearch = () => {
        isSearchActive.value = true
        searchQuery.value = '';
        searchField.value?.focus()
    }

    let searchTimeout = null;
    const clearSearchTimeout = () => {
        // clear the timeout when the input is focused
        if (searchTimeout) clearTimeout(searchTimeout);
    }

    const hideSearch = () => {
        searchTimeout = setTimeout(function () {
            closeSearchIfUnFocused()
        }, 20000)
    }

    const closeSearchIfUnFocused = () => {
        if (!searchField.value?.isFocused && !activeSearchTerms.value?.length) {
            isSearchActive.value = false;
            searchQuery.value = '';
        }
    }

    watch(searchQuery, (newVal, oldVal) => {
        matchingSearchOptions.value = []
        if (newVal?.length >= 3) {
            fetchMatchingSearchOptions()
        } else {
            nextTick(() => adjustMarginTop())
        }
    })

    const fetchMatchingSearchOptions = () => {
        axios.get('/data/search', {
            params: {
                terms: searchQuery.value,
                projects: "1"
            },
        })
            .then((response) => {
                if (response.data) {
                    matchingSearchOptions.value = response.data.filter(option => !activeSearchTerms.value.some(o => o.type === option.type && o.value === option.value))
                    nextTick(() => adjustMarginTop())
                }
            })
            .catch((error) => console.log(error))
    }


    const searchField = ref(null)
    const includingNotesInSearch = ref(props.includeNotesProp)
    const includeNotesInSearch = () => {
        includingNotesInSearch.value = "1"
        reloadPage()
    }
    const excludeNotesInSearch = () => {
        includingNotesInSearch.value = "0"
        reloadPage()
    }
    const toggleIncludeNotesInSearch = () => {
        if (includingNotesInSearch.value === "1") {
            excludeNotesInSearch()
        } else {
            includeNotesInSearch()
        }
    }
    const addTextQuery = () => {
        if (searchQuery.value?.length >= 3) {
            if (!hasTextSearchOption.value) {
                includingNotesInSearch.value = "1"
            }
            activeSearchTerms.value.push({
                type: "text",
                value: searchQuery.value
            })

            searchQuery.value = ""
            searchField.value?.focus()

            reloadPage()
        }
    }

    const selectSearchOption = (option) => {
        let index = matchingSearchOptions.value.findIndex((item) => item === option);
        if (index !== -1) {
            matchingSearchOptions.value.splice(index, 1);
            activeSearchTerms.value.push(option);
            searchQuery.value = ""
            searchField.value?.focus()
        }
        reloadPage();
    }

    const deselectSearchOption = (option) => {
        let index = activeSearchTerms.value.findIndex((item) => item.type === option.type && item.value === option.value);
        if (index !== -1) {
            activeSearchTerms.value.splice(index, 1);
            hideSearch()
        }
        reloadPage();
    }

    const generateUrlWithSearchParams = computed(() => {
        let params = {};

        activeSearchTerms.value.forEach(item => {
            if (!params.hasOwnProperty(item.type)) {
                params[item.type] = item.value;
            } else {
                params[item.type] += ',,,' + item.value;
            }
        });

        let url = '/all-actions';
        url += '?due=' + newDueValue.value;

        let queryParams = new URLSearchParams(params).toString();
        if (queryParams) url += '&' + queryParams;
        if (hasTextSearchOption.value) {
            url += "&notes=" + (includingNotesInSearch.value === "1" ? "1" : "0")
        }
        url += "&q_dir=" + qDir.value + "&q_col=" + qCol.value
        return url;
    });

    const hasTextSearchOption = computed(() => {
        return activeSearchTerms.value.some(o => o.type === 'text')
    })

    const reloadPage = () => {
        Inertia.visit(generateUrlWithSearchParams.value, {
            preserveState: true,
        });
    }

    const newDueValue = ref(props.due)
    const dueValueChanged = (q) => {
        newDueValue.value = q
        reloadPage()
    }

    const nbSearchOptionsDropdown = ref(null)
    const searchDropdownHeight = ref(0)
    const adjustMarginTop = () => searchDropdownHeight.value = nbSearchOptionsDropdown.value?.offsetHeight ?? 0
    const actionMarginTop = computed(() => searchDropdownHeight.value + "px")

</script>

<style type="postcss" scoped>
    .flex-table {
        @apply px-3 text-dark
    }
    .ft__row__wrap {
        transition:600ms;
        @apply border-b-2 border-gray-200 relative
    }
    .ft__row {
        @apply flex w-full static flex-wrap lgx:flex-nowrap;
    }
    .ft__row__wrap--highlight {
        @apply bg-highlight;
    }
    .ft__row--extras .ft__col {
        @apply pt-2;
    }
    .ft__head {
        @apply hidden lgx:block
    }
    .ft__head .ft__col {font-size:11px;
        @apply py-2 font-semibold text-gray-600
    }
    .ft__head .ft__col--sorted {
        @apply text-black
    }
    .ft__head .ft__col .icon {
        @apply inline-block align-top mt-1 ml-1
    }
    .ft__col {
        @apply p-2 order-none;
    }
    .ft__col.np-t {
        @apply pt-0;
    }
    .ft__col.np-l {
        @apply pl-0;
    }
    .ft__col--icon {
        @apply w-12
    }
    .ft__col--icon .icon {
        @apply mt-1
    }
    .ft__col--id {
        @apply w-20
    }
    .ft__col--desc {
        @apply flex-1 flex-grow
    }
    .ft__col--xs {
        @apply w-28
    }
    .ft__col--sm {
        @apply w-28
    }
    .ft__col--priority {
        @apply w-32
    }
    .ft__col--md {
        @apply w-44
    }
    .ft__col--lg {
        @apply w-52
    }
    .ft__col--due {
        @apply w-48
    }
    .ft__col--owner {
        @apply w-48 order-none
    }
    .ft__col--owner .owner-stamp {
    }
    .ft__col--last {
        @apply lgx:w-52 ml-auto -mb-14 lgx:m-0 relative
    }
    .ft__col--actions {
        @apply lgx:w-64;
    }
    .ft__col--timeline { width:15rem; }
    .ft__col-breaker {
        @apply w-full lgx:hidden
    }
    .ft__col--raised,
    .ft__col--due {
        @apply order-2 lgx:order-none
    }
    .ft__col--sorted,
    .ft__col--last {
        @apply order-3 lgx:order-none
    }
    .ft__col input[type="text"],
    .ft__col textarea {
        @apply bg-transparent focus:bg-gray-100 py-1.5 px-2 transition-all leading-4 appearance-none;
    }
    .ft__row--confirmation {
        @apply justify-end;
    }
    .confirmation-box {width:460px;
        @apply max-w-full p-3 text-dark
    }
    .slide-enter,
    .slide-leave-active {
        transition: all 666ms ease-in;
    }
    .slide-enter, .slide-leave-to {
        opacity: 0;
        left: 100%;
    }
    .ft__row--extras {
        @apply bg-gray-50 lgx:bg-transparent;
    }
    /* .ft__row--extras .ft__col--icon,
    .ft__row--extras .ft__col--id {
        @apply hidden
    } */
    @media(max-width: 1023px) {
        .ft__row.ft__row--extras .ft__col--timeline {
            width:100%; order:6;
        }
        .ft__row.ft__row--extras .ft__col--icon,
        .ft__row.ft__row--extras .ft__col--id,
        .ft__row.ft__row--extras .ft__col--priority {
            @apply hidden
        }

        .ft__col.np-t {
            @apply pt-2
        }
    }
</style>
<style type="text/css" scoped>

</style>
