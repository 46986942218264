<template>
    <div class="slidein slidein--left" style="z-index: 999">
        <div class="slidein__head absolute left-0 top-0 h-full bg-xdark z-10">
            <div class="pl-2 pt-2">
                <button type="button" class="btn-back nb-focus" @click="$emit('hide')" tabindex="0" ref="closeNotesButton">
                    <div class="icon icon--md">
                        <svg class="icon-svg">
                            <use xlink:href="#icon-close-notes"></use>
                        </svg>
                    </div>
                </button>
            </div>
        </div>
        <div class="slidein__body">
            <div class="slide-in-title">
                <span class="align-top">{{ actionIndexId }}: {{ actionDescription }}</span>
            </div>
            <div class="slide-in-title">
                <button type="button" class="inline font-light cursor-pointer nb-focus" :class="{'text-light': filesOnly}" @click="showNotesOnly" @keyup.enter="showNotesOnly">Notes</button>
                <div class="inline px-4">|</div>
                <button type="button" class="inline font-light cursor-pointer nb-focus" :class="{'text-light': !filesOnly}" @click="showFilesOnly" @keyup.enter="showFilesOnly">Files</button>
            </div>
            <hr>
            <div class="sortby flex items-center py-1 px-3 bg-gray-200 text-black font-bold mb-5 justify-between">
                <div class="col flex items-center">
                    <div class="col pr-3 cursor-pointer nb-focus" @click="changeFilter" @keyup.enter="changeFilter" tabindex="0">
                        {{ sortByNewest ? 'Newest' : 'Oldest' }} first
                    </div>
                    <SortIcon :is-up="!sortByNewest" class="cursor-pointer" @click="changeFilter" />
                </div>
                <div v-if="!filesOnly" class="col flex items-center">
                    <div class="col pr-3 cursor-pointer nb-focus" @click="changeEventsFilter" @keyup.enter="changeEventsFilter" tabindex="0">
                        {{ showingEvents ? 'Hide' : 'Show' }} events
                    </div>
                </div>

            </div>
            <div v-for="(note, index) in orderedNotes" :key="note.id">
                <div class="pb-5 whitespace-pre-wrap">
                    {{ note.date }} <br v-if="!note.is_automated"/>
                    <span v-else> - </span>
                    <span v-if="!filesOnly">{{ note.description }}</span>
                    <NoteAttachmentElement v-if="note.filepath_1" :filepath="note.filepath_1" :filename="note.original_filename_1" :allowDeleting="filesOnly" :actionId="actionId" class="my-1" />
                    <NoteAttachmentElement v-if="note.filepath_2" :filepath="note.filepath_2" :filename="note.original_filename_2" :allowDeleting="filesOnly" :actionId="actionId" class="my-1" />
                    <NoteAttachmentElement v-if="note.filepath_3" :filepath="note.filepath_3" :filename="note.original_filename_3" :allowDeleting="filesOnly" :actionId="actionId" class="my-1" />
                    <div v-if="!filesOnly" class="message-by">{{ note.author }}</div>
                </div>
            </div>
        </div>
        <div class="slidein__footer">
            <div class="relative">
                <FormErrors :errors="form.errors" />
                <textarea class="nb-focus pb-14" name="" rows="3" placeholder="New note..." v-model="form.description" maxlength="500" ref="noteTextarea"></textarea>
                <div class="absolute bottom-2 right-6 text-black">
                    <div class="labels-input-wrapper" style="gap: 12px;">
                        <div class="label-item" v-for="(file, index) in form.files" :key="index" style="padding-right: 2rem;">
                            {{ shortenedFilenames[index] }}
                            <div class="label-close noprint nb-focus" @click.stop="removeFile(index)" @keyup.enter.stop="removeFile(index)" tabindex="0">
                                <div class="icon icon--xssmall">
                                    <svg class="icon-svg">
                                        <use xlink:href="#icon-close"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div v-if="form.files.length < 3" class="new-lable-btn noprint nb-focus" @click.stop="addFile" @keyup.enter.stop="addFile" tabindex="0">
                            <button type="button" class="nb-focus">
                                <div class="ds-selected-val ds-selected-val--default">
                                    <div class="flex items-center">
                                        <div class="icon icon--small mr-2">
                                            <svg class="icon-svg">
                                                <use xlink:href="#icon-attachment"></use>
                                            </svg>
                                        </div>
                                        Attach
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right pt-3">
                <button type="button" class="btn btn--sm btn--white btn--border nb-focus" @click="submitForm" tabindex="0">Add note</button>
            </div>
            <input type="file" ref="fileInput" class="hidden" @change="handleFiles" multiple>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {useForm} from "@inertiajs/inertia-vue3";
import SortIcon from '@icons/SortIcon.vue'
import {onMounted} from "vue"
import FormErrors from "@components/FormErrors";
import NoteAttachmentElement from "./NoteAttachmentElement.vue";

const props = defineProps({
    actionId: {
        type: Number,
        required: true
    },
    actionIndexId: {
        type: String,
        required: true
    },
    actionDescription: {
        type: String,
        required: true
    },
    notes: {
        type: Array,
        default: []
    }
})

const form = useForm({
    description: "",
    files: []
})

const sortByNewest = ref(true);

const changeFilter = () => {
    sortByNewest.value = !sortByNewest.value
}

const orderedNotes = computed(() => {
        let notes = props.notes
            .filter(n => filesOnly.value ? n.filepath_1 || n.filepath_2 || n.filepath_3 : n)
            .filter(n => showingEvents.value ? n : !n.is_automated)

    return sortByNewest.value ? notes : notes.slice().reverse();
})

const submitForm = () => {
    form.post('/action/' + props.actionId + '/add-note', {
        preserveScroll: true,
        preserveState: true,
        onSuccess: (v) => {
            form.reset()
            noteTextarea.value?.focus()
        }
    })
}

const closeNotesButton = ref(null)
const noteTextarea = ref(null)
onMounted(() => {
    closeNotesButton.value.focus();
});

const filesOnly = ref(false)
const showFilesOnly = () => filesOnly.value = true
const showNotesOnly = () => filesOnly.value = false

const showingEvents = ref(true)
const changeEventsFilter = () => showingEvents.value = !showingEvents.value

const fileInput = ref(null)
const addFile = () => {
    fileInput.value.click()
}

const handleFiles = (event) => {
    const selectedFiles = Array.from(event.target.files)
    form.files.push(...selectedFiles.slice(0, 3 - form.files.length))
    event.target.value = ''
}

const removeFile = (index) => {
    form.files.splice(index, 1)
}

const shortenedFilenames = computed(() => form.files.map(file => {
    return file.name.length > 20
        ? file.name.slice(0, 15) + '...' + file.name.slice(-4)
        : file.name;
}))

</script>

<style type="postcss">
.slidein { height: calc(100% - 102px); width:500px; box-shadow: 0 -1px 15px rgba(0,0,0,0.3);
    @apply bg-dark text-white fixed bottom-0 right-0 px-6 pb-6 pt-3 font-light flex flex-col z-40 max-w-full;
}
@media(max-width: 1023px) {
    .slidein { height: calc(100% - 46px)}
}
.slide-in-title {
    @apply text-xxl font-light pb-4
}
.slide-in-title span {
    @apply text-sm
}
.message-by {
    @apply text-right italic
}
.slidein__body {
    @apply flex-1 overflow-y-auto pl-7
}
.slidein__footer {
    @apply border-t border-light pt-4 mt-3 pl-7
}
.slidein__footer textarea {
    @apply text-gray-500
}
.notes-enter-active,
.notes-leave-active {
  transition: 0.5s ease;
}
.notes-enter-from,
.notes-leave-to {
  opacity: 0;
  transform:translateX(500px);
}


.labels-input-wrapper {
    @apply flex flex-wrap gap-2 sm:gap-3
}
.new-lable-btn { padding:4px 8px; line-height:13px;
    @apply flex items-center rounded bg-silver lg:bg-lighter text-sm
}
.new-lable-btn .span {
    @apply pl-2 inline-block;
}
.ft__col input[type="text"].new-lable-input {
    @apply bg-gray-200 w-auto;
}
.label-item {padding:4px 8px; line-height:13px;
    @apply items-center text-sm rounded bg-silver lg:bg-lighter relative pr-8 md:flex
}
.label-item .label-close { cursor:pointer;
    @apply w-7 h-7 absolute right-0 top-0 flex items-center justify-center lg:text-white;
}
</style>
