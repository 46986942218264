<template>
    <div>
        <p class="text-xxl mb-3">Verify your email address in order to access the Sympmy Beat - Beta</p>
        <form @submit.prevent="submitForm">
            <FormErrors :errors="form.errors" />
            <div class="auth-button-wrapper pb-8">
                <input type="submit" value="Resend Verification Email" :disabled="form.processing">
            </div>
            <div class="text-center border-t border-gray-400 pt-8">
                <a href="#" class="btn-text text-lg" @click.stop.prevent="logout">Sign out</a>
            </div>
        </form>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrors from "@components/FormErrors";
import useToast from "@composables/useToast";
import {Inertia} from "@inertiajs/inertia";

const form = useForm()
const {toast} = useToast()

const submitForm = () => {
    form.post('/email/verification-notification', {
        onSuccess: visit => {
            toast('success', "The Verification email has been sent!")
        },
    })
}
const logout = () => Inertia.post('/logout')
</script>

<script>
import AuthLayout from "@layouts/AuthLayout"

export default {
    layout: AuthLayout
}
</script>

<style type="postcss">
    .auth-input-wrapper {
        @apply relative pb-6;
    }
    .auth-input-wrapper button {
        @apply  absolute right-4 top-4
    }
    .auth-input-wrapper button span {
        @apply underline font-semibold
    }
    .auth-input-wrapper input { cursor:pointer;
        @apply block text-white w-full rounded-md border-2 border-white py-3 px-2 md:px-5 bg-transparent text-sm md:text-xxl;
    }
    .auth-input-wrapper input:placeholder {
        @apply italic
    }
    .auth-input-wrapper input.password {
        @apply pr-12;
    }

    .auth-button-wrapper {
        @apply relative pt-12;
    }
    .auth-button-wrapper input[type="submit"] { cursor:pointer;
        @apply block text-palegreen w-full rounded-md border-2 border-palegreen py-3 px-2 md:px-5 bg-transparent text-sm md:text-xxl hover:bg-success hover:border-success hover:text-white transition-all;
    }

    .auth-otp-wrapper {
        @apply relative pb-6 grid grid-cols-6 gap-3 md:gap-4 mx-auto;
    }
    .auth-otp-wrapper input {
        @apply block text-gray-700 w-full rounded-md border-2 border-white py-3 px-2 md:px-5 bg-transparent text-xxl md:text-4xl text-center;
    }
    .auth-input-wrapper [multiple]::placeholder,
    .auth-input-wrapper [type=date]::placeholder,
    .auth-input-wrapper [type=datetime-local]::placeholder,
    .auth-input-wrapper [type=email]::placeholder,
    .auth-input-wrapper [type=month]::placeholder,
    .auth-input-wrapper [type=number]::placeholder,
    .auth-input-wrapper [type=password]::placeholder,
    .auth-input-wrapper [type=search]::placeholder,
    .auth-input-wrapper [type=tel]::placeholder,
    .auth-input-wrapper [type=text]::placeholder,
    .auth-input-wrapper [type=time]::placeholder,
    .auth-input-wrapper [type=url]::placeholder,
    .auth-input-wrapper [type=week]::placeholder,
    .auth-input-wrapper select::placeholder,
    .auth-input-wrapper textarea::placeholder {
        @apply  text-sm md:text-xxl
    }
</style>
