<template>
    <div v-if="true" class="ft__row__wrap">
        <div class="ft__row">
            <div class="ft__col ft__col--id">
                <span class="font-semibold leading-8">{{ user.id }}</span>
            </div>
            <div class="ft__col ft__col--lg np-l">
                <input type="text" v-model="nameForm.name" @blur="submitNameForm" />
            </div>
            <div class="ft__col ft__col--desc ft__col--email np-l">
                <input type="email" v-model="emailForm.email" @blur="submitEmailForm" />
            </div>
            <div class="ft__col-breaker"></div>
            <div class="ft__col ft__col--md">
                <div class="text-light lg:hidden">Storage</div>
                <span class="leading-8 font-semibold" :class="{'text-danger': user.storage_used >= 90}">{{ user.storage_used }} / 100 (MB)</span>
            </div>
            <div class="ft__col ft__col--sm">
                <div class="text-light lg:hidden">Access</div>
                <span v-if="user.can_access" class="leading-8 font-semibold text-success">Enabled</span>
                <span v-else class="leading-8 font-semibold text-danger">Disabled</span>
            </div>
            <div class="ft__col ft__col--md">
                <div class="text-light lg:hidden">Notifications</div>
                <span v-if="user.notifications_enabled" class="leading-8 font-semibold text-success">Enabled</span>
                <span v-else class="leading-8 font-semibold text-danger">Disabled</span>
            </div>
            <div class="ft__col ft__col--md">
                <div class="text-light lg:hidden">Last Login</div>
                <span class="leading-8">{{ user.last_login_at ?? '-' }}</span>
            </div>
            <div class="ft__col ft__col--md">
                <div class="text-light lg:hidden">Join Date</div>
                <span class="leading-8">{{ user.created_at }}</span>
            </div>
            <div class="ft__col ft__col--last self-end lg:self-auto">
                <div class="flex items-center gap-4 justify-end">
                    <div class="col">
                        <div class="action__status">
                            <div class="as__button text-center" :class="{'open': !user.is_admin, 'closed': user.is_admin}">{{user.is_admin ? 'Admin' : 'User'}}</div>
                        </div>
                    </div>
                    <div class="col">

                        <div class="context__navs" :class="{'context__navs--open': dropDown}" v-click-away="onClickAway">
                            <button type="button" class="context__nav" @click="dropDown = !dropDown">
                                ...
                            </button>
                            <ul class="dropdown" v-if="dropDown">
                                <li><a href="" @click.stop.prevent="deleteUser">Delete</a></li>
                                <li v-if="user.can_access"><a href="" @click.stop.prevent="disableAccess">Disable Access</a></li>
                                <li v-else><a href="" @click.stop.prevent="enableAccess">Enable Access</a></li>
                                <li v-if="user.notifications_enabled"><a href="" @click.stop.prevent="disableNotifications">Disable Notifications</a></li>
                                <li v-else><a href="" @click.stop.prevent="enableNotifications">Enable Notifications</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";
import {useForm} from "@inertiajs/inertia-vue3";
import useToast from "@composables/useToast";
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    user: {
        type: Object,
        required: true
    },
})

const emit = defineEmits(['notes'])

const {toast} = useToast()

const displayErrors = (errors) => {
    for (const error in errors) {
        toast('danger', errors[error])
    }
}

const emailForm = useForm({
    email: props.user.email
})
const submitEmailForm = () => {
    emailForm.post('/admin/user/' + props.user.id + '/edit-email', {
        preserveScroll: true,
        preserveState: (page) => Object.keys(page.props.errors).length,
    })
}

const nameForm = useForm({
    name: props.user.name
})
const submitNameForm = () => {
    nameForm.post('/admin/user/' + props.user.id + '/edit-name', {
        preserveScroll: true,
        preserveState: (page) => Object.keys(page.props.errors).length,
    })
}
const enableAccess = () => {
    Inertia.post('/admin/user/' + props.user.id + '/enable-access',  null, {
        preserveScroll: true,
        preserveState: (page) => Object.keys(page.props.errors).length,
    })
}
const disableAccess = () => {
    Inertia.post('/admin/user/' + props.user.id + '/disable-access',  null, {
        preserveScroll: true,
        preserveState: (page) => Object.keys(page.props.errors).length,
    })
}
const enableNotifications = () => {
    Inertia.post('/admin/user/' + props.user.id + '/enable-notifications',  null, {
        preserveScroll: true,
        preserveState: (page) => Object.keys(page.props.errors).length,
    })
}
const disableNotifications = () => {
    Inertia.post('/admin/user/' + props.user.id + '/disable-notifications',  null, {
        preserveScroll: true,
        preserveState: (page) => Object.keys(page.props.errors).length,
    })
}
const deleteUser = () => {
    Inertia.post('/admin/user/' + props.user.id + '/delete',  null, {
        preserveScroll: true,
        preserveState: (page) => Object.keys(page.props.errors).length,
        onBefore: () => confirm('Are you sure you want to delete this user?'),
    })
}


const dropDown = ref(false);
const onClickAway = (event) => {
    dropDown.value = false;
}
</script>

<style type="postcss" scoped>
.flex-table {
    @apply px-3 text-dark
}
.ft__row__wrap {
    transition:600ms;
    @apply border-b-2 border-gray-200 relative
}
.ft__row {
    @apply flex w-full static flex-wrap lg:flex-nowrap;
}
.ft__row__wrap--highlight {
    @apply bg-highlight;
}
.ft__row--extras .ft__col {
    @apply pt-2;
}
.ft__head {
    @apply hidden lg:block
}
.ft__head .ft__col {font-size:11px;
    @apply py-2 font-semibold text-gray-600
}
.ft__head .ft__col--sorted {
    @apply text-black
}
.ft__head .ft__col .icon {
    @apply inline-block align-middle mr-1
}
.ft__col {padding-left:6px; padding-right:6px;
    @apply py-1 sm:py-2 order-none;
}
.ft__col.np-t {
    @apply pt-0;
}
.ft__col.np-l {
    @apply pl-0;
}
.ft__col--icon {
    @apply w-12
}
.ft__col--icon .icon {
    @apply mt-1
}
.ft__col--id {
    @apply w-20
}
.ft__col--desc { min-width:120px;
    @apply flex-1 flex-grow
}
.ft__col--email {min-width:120px;}
.ft__col--xs {
    @apply w-28
}
.ft__col--sm {
    @apply w-28
}
.ft__col--md {
    @apply w-44
}
.ft__col--lg {
    @apply w-52
}
.ft__col--due {
    @apply w-36
}
.ft__col--owner {
    @apply w-48 order-none
}
.ft__col--owner .owner-stamp {
}
.ft__col--last {
    @apply lg:w-52 ml-auto relative
}
.ft__col--timeline { width:15rem; }
.ft__col-breaker {
    @apply w-full lg:hidden
}
.ft__col--raised,
.ft__col--due {
    @apply order-2 lg:order-none
}
.ft__col--sorted,
.ft__col--last {
    @apply order-3 lg:order-none
}
.ft__col input[type="text"],
.ft__col input[type="email"],
.ft__col textarea {padding:4px 8px; line-height:16px;
    @apply bg-transparent focus:bg-gray-100 transition-all appearance-none;
}
.ft__row--confirmation {
    @apply justify-end;
}
.confirmation-box {width:460px;
    @apply max-w-full p-3 text-dark
}
.slide-enter,
.slide-leave-active {
    transition: all 666ms ease-in;
}
.slide-enter, .slide-leave-to {
    opacity: 0;
    left: 100%;
}
.ft__row--extras {
    @apply bg-gray-50 lg:bg-transparent;
}
/* .ft__row--extras .ft__col--icon,
.ft__row--extras .ft__col--id {
    @apply hidden
} */
@media(max-width: 1023px) {
    .ft__row.ft__row--extras .ft__col--timeline {
        width:100%; order:6;
    }
    .ft__row.ft__row--extras .ft__col--icon,
    .ft__row.ft__row--extras .ft__col--id,
    .ft__row.ft__row--extras .ft__col--priority {
        @apply hidden
    }

    .ft__col.np-t {
        @apply pt-2
    }
}
.context__navs {
    @apply relative;
}
.context__nav {border-radius:4px;
    @apply pt-2 pl-4 pr-1 rounded-br-none text-right bg-gray-200 font-semibold;
}
.context__navs--open .context__nav {
    @apply bg-gray-600 text-white;
}

.action__status {
    @apply relative;
}
.as__button { line-height:18px; padding-top:1px; padding-bottom:1px; border-radius:4px;
    @apply text-sm font-semibold rounded-br-none bg-transparent border-2 px-1 transition-all w-24 font-semibold;
}

.as__button.closed {
    @apply text-danger border-danger
}
.action__status--open .as__button {
    @apply bg-dark rounded-b-none border-dark text-white
}
</style>
