<template>
    <div class="icon" :class="sizeClass">
        <slot></slot>
    </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    size: {
        type: String,
        default: 'xs'
    }
})

const sizeClass = computed(() => 'icon--' + props.size)
</script>

<style type="postcss">

</style>
