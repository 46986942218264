<template>
    <div class="timeline-holder">
        <div class="timeline-wrapper">
            <div class="close-timeline cursor-pointer noprint pr-2 pt-3" @click="$emit('close')">
                <div class="icon icon--xssmall">
                    <svg class="icon-svg">
                        <use xlink:href="#icon-close-light"></use>
                    </svg>
                </div>
            </div>
            <div class="pr-20 pl-14 pb-16">
                <div class="timeline">
                    <div class="timeline--col timeline--left" :style="{'width': timeline.left + '%'}">
                        <div class="timeline--days-wrap">
                            <div class="timeline--days w-max font-medium" @click="$emit('open-notes')">{{ timeline.left_caption }}</div>
                        </div>
                        <div class="timeline--bar " :class="[leftColourClass, timeline.left === 100 ? 'rounded-right' : '']"></div>
                    </div>
                    <div v-if="timeline.mid" class="timeline--col timeline--mid" :style="{'width': timeline.mid + '%'}">
                        <div class="timeline--days-wrap ml-auto">
                            <div class="timeline--days text-right"></div>
                        </div>
                        <div class="timeline--bar" :class="midColourClass" :style="{'border-radius': (timeline.left > 0) ? '' : '0.25rem'}"></div>
                    </div>
                    <div class="timeline--col timeline--right" :style="{'width': timeline.right + '%'}">
                        <div class="timeline--days-wrap ml-auto">
                            <div class="timeline--days text-right flex justify-end font-medium" @click="$emit('open-notes')">
                                <div class="col pr-2">
                                    {{ timeline.right_caption }}
                                </div>
                            </div>
                        </div>
                        <div class="timeline--bar" :class="rightColourClass" :style="{'border-radius': (timeline.left + timeline.mid > 0) ? '' : '0.25rem'}"></div>
                    </div>

                    <div>
                        <!-- bottom dates -->
                        <TimelinePoint
                            v-for="(point, index) in timeline.bottom_dates"
                            :key="index"
                            :is-end="false"
                            :is-lower="true"
                            :position="point.position"
                            :titles="point.titles"
                            :date="point.value"
                            @open-notes="$emit('open-notes')"
                        />
                        <!-- inner dates -->
                        <TimelinePoint
                            v-for="(point, index) in timeline.inner_dates"
                            :key="index"
                            :is-end="false"
                            :is-lower="false"
                            :position="point.position"
                            :titles="point.titles"
                            :date="point.value"
                            @open-notes="$emit('open-notes')"
                        />
                        <!-- left Date -->
                        <TimelinePoint
                            :is-end="true"
                            :is-lower="false"
                            :position="0"
                            :titles="timeline.left_date.titles"
                            :date="timeline.left_date.date"
                            @open-notes="$emit('open-notes')"
                        />
                        <!-- right Date -->
                        <TimelinePoint
                            :is-end="true"
                            :is-lower="false"
                            :position="100"
                            :titles="timeline.right_date.titles"
                            :date="timeline.right_date.date"
                            @open-notes="$emit('open-notes')"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import TimelinePoint from "@components/TimelinePoint.vue";

const props = defineProps({
    timeline: {
        type: Object,
        required: true
    },
})

const emit = defineEmits(['close', 'open-notes'])

const leftColourClass = computed(() => (
    props.timeline.left_type === 'complete_dark'
        ? 'bg-blue'
        : (props.timeline.left_type === 'past_dark'
                ? 'bg-danger'
                : (props.timeline.left_type === 'tolerance_dark'
                        ? 'bg-amber'
                        : (props.timeline.left_type === 'complete'
                                ? 'bg-paleblue'
                                : (props.timeline.left_type === 'past'
                                        ? 'bg-error'
                                        : (props.timeline.left_type === 'tolerance'
                                                ? 'bg-warning'
                                                : (props.timeline.left_type === 'future'
                                                        ? 'bg-palegreen'
                                                        : 'bg-success' // future_dark
                                                )
                                        )
                                )
                        )
                )
        )
))
const midColourClass = computed(() => (
    props.timeline.mid_type === 'complete_dark'
        ? 'bg-blue'
        : (props.timeline.mid_type === 'past_dark'
                ? 'bg-danger'
                : (props.timeline.mid_type === 'tolerance_dark'
                        ? 'bg-amber'
                        : (props.timeline.mid_type === 'complete'
                                ? 'bg-paleblue'
                                : (props.timeline.mid_type === 'past'
                                        ? 'bg-error'
                                        : (props.timeline.mid_type === 'tolerance'
                                                ? 'bg-warning'
                                                : (props.timeline.mid_type === 'future'
                                                        ? 'bg-palegreen'
                                                        : 'bg-success' // future_dark
                                                )
                                        )
                                )
                        )
                )
        )
))
const rightColourClass = computed(() => (
    props.timeline.right_type === 'complete_dark'
        ? 'bg-blue'
        : (props.timeline.right_type === 'past_dark'
                ? 'bg-danger'
                : (props.timeline.right_type === 'tolerance_dark'
                        ? 'bg-amber'
                        : (props.timeline.right_type === 'complete'
                                ? 'bg-paleblue'
                                : (props.timeline.right_type === 'past'
                                        ? 'bg-error'
                                        : (props.timeline.right_type === 'tolerance'
                                                ? 'bg-warning'
                                                : (props.timeline.right_type === 'future'
                                                        ? 'bg-palegreen'
                                                        : 'bg-success' // future_dark
                                                )
                                        )
                                )
                        )
                )
        )
))
</script>

<style type="postcss">
    .timeline-holder {
        @apply overflow-x-auto;
    }
    .timeline {
        @apply flex relative font-light
    }
    .timeline-wrapper { background:#011224; min-width:1024px; padding: 12px; padding-bottom: 6px;
        @apply text-white text-sm relative;
    }
    .close-timeline {
        @apply absolute right-2 top-2
    }
    .timeline--days {
        @apply pb-1 whitespace-pre bg-xdark;
    }
    .timeline--days:hover {
        @apply cursor-pointer;
    }
    /* .timeline--col {
        @apply relative
    } */
    .timeline--col {
        padding-left: 1px; padding-right: 1px;
    }
    .timeline--bar {
        @apply w-full mb-12;
        /* border-left: 1px solid #011224;
        border-right: 1px solid #011224; */
        height: 6px;
    }
    .timeline--col:first-child .timeline--bar {
        @apply border-l-0
    }
    .timeline--col:last-child .timeline--bar {
        @apply border-r-0
    }
    .timeline--left .timeline--bar {
        border-top-left-radius:2px;
        border-bottom-left-radius: 2px;
        /* @apply rounded-l-full */
    }
    .timeline--right .timeline--bar {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        /* @apply rounded-r-full */
    }
    .timeline--bar.rounded-right {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
    .timeline--split {
        @apply absolute top-9;
    }
    .timeline--split-pointer {
        @apply relative block mx-auto pb-1;
    }
    .timeline--split-centered {transform:translateX(-50%);
        @apply z-10;
    }
    .timeline-label {
        @apply block
    }
    .timeline--split { min-width:18px;
        @apply text-sm whitespace-pre
    }
    .timeline--split-diamond {
        @apply relative block mx-auto pb-1 z-10;
    }
    .timeline--split-diamond:after {
        content:''; width:10px; height:10px; background:#fff; border:2px solid var(--xdark); position:absolute; margin: 0 auto; top:-8px; left:0; right:0; transform:rotate(45deg);
    }
    .timeline--split-pointer:before,
    .timeline--split-pointer:after {content:''; border:0 solid transparent; border-bottom:5px solid #fff; border-left-width:5px; border-right-width:5px; margin-top:-2px;
        @apply absolute top-0 left-0 right-0 w-0 h-0 mx-auto;
    }
    .timeline--split-pointer:before {
         border-bottom:8px solid var(--xdark); border-left-width:8px; border-right-width:8px; margin-top:-5px;
    }
    .timeline--split-left-mark { border:0 solid transparent; border-left-width:16px;  border-bottom:16px solid #fff; border-bottom-color:inherit;
        @apply absolute right-full top-0 w-0 h-0 -ml-0.5
    }
    .timeline--today {
        @apply pl-2
    }
    .timeline--col:last-child .timeline--split-pointer {
        @apply mr-0;
    }
    .bg-success {background-color:#48c568}
    .timeline--days-wrap {height:21px;
        @apply relative table
    }
    .timeline--days-wrap .timeline--days {
        @apply absolute left-0 px-1;
    }
    .timeline--days-wrap.ml-auto .timeline--days {
        @apply left-auto right-0
    }
</style>
