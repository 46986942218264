<template>
    <div class="setting-wrapper">
        <div class="pb-24">
            <div class="grid lg:grid-cols-3">
                <div class="col pb-5 lg:pb-0 lg:pr-4">
                    <div class="text-xxl text-gray-800 pb-2"><slot name="title"></slot></div>
                    <slot name="subtitle"></slot>
                </div>
                <div class="col col-span-2">
                    <div class="form__card">
                        <div class="form__card--body">
                            <slot></slot>
                        </div>
                    </div>
                    <div class="form__card">
                        <div class="form__card--footer">
                            <slot name="title"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>

<style type="postcss" scoped>
.setting-wrapper {
    @apply bg-gray-100 lg:p-12 p-4;
}
.form__card {
    @apply bg-white shadow-md rounded-lg
}
.form__card--body {
    @apply md:py-10 md:px-8 py-6 px-5
}
.form__card--footer {
    @apply md:py-6 py-4 md:px-8 px-5 bg-gray-200 rounded-b-md text-right
}
</style>
