import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'

export default function useToast() {

    const toast = (type, text) => {
        createToast(text, {
            type: type,
            position: "top-center",
            showIcon: true,
            hideProgressBar: true,
            swipeClose: false,
            toastBackgroundColor: type === 'danger' ? '#d04040' : (type === 'success' ? '#48c568' : '#ffb144')
        })
    }

    return {
        toast
    }
}
