<template>
    <div>
        <p v-if="isWithToken" class="text-xxl mb-6">Please confirm access to your account by entering the authentication code provided by your authenticator application.</p>
        <p v-else class="text-xxl mb-6">Please confirm access to your account by entering one of your emergency recovery codes.</p>
        <form @submit.prevent="submitForm">
            <div v-if="isWithToken" class="auth-otp-wrapper">
                <input v-for="(cell, index) in cells" :key="index"
                       v-model="cells[index]"
                       type="tel"
                       maxlength="1"
                       @focus="focusedCellId = index"
                       @keydown.delete="onCellErase(index, $event)"
                       @keydown="onKeyDown"
                       style="color: #ffffff"
                       :id="'cell_' + index"
                >
            </div>
            <div v-else class="auth-input-wrapper">
                <input type="text" v-model="form.recovery_code">
            </div>
            <FormErrors :errors="form.errors" />
            <div class="auth-button-wrapper pb-8">
                <input type="submit" value="Get Started" :disabled="form.processing">
            </div>
            <div class="text-center border-t border-gray-400 pt-8">
                <button v-if="isWithToken" type="button" class="btn-text text-lg" @click="isWithToken = false">Use a recovery code</button>
                <button v-else type="button" class="btn-text text-lg" @click="isWithToken = true">Use an authentication code</button>
            </div>
        </form>
    </div>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrors from "@components/FormErrors";
import {computed, ref, watch} from "vue";
    const form = useForm({
        code: "",
        recovery_code: ""
    })

    const isWithToken = ref(true)

    const cells = ref(['', '', '', '', '', ''])

const completeCode = computed(() => cells.value.join(''))
const submitForm = () => {
        form.transform((data) => ({
            code: completeCode.value,
            recovery_code: form.recovery_code
        })).post('/two-factor-challenge')
}

const focusedCellId = ref(0)

const focusPreviousCell = () => {
    if (focusedCellId.value) {
        focusCellByIndex(focusedCellId.value - 1)
    }
}
const focusNextCell = () => {
    if (focusedCellId.value < 6) {
        focusCellByIndex(focusedCellId.value + 1)
    }
}

const focusCellByIndex = (index) => {
    focusedCellId.value = index
    const el = document.getElementById('cell_' + focusedCellId.value)
    if (el) {
        el.focus()
        el.select()
    }
}

watch(() => [...cells.value], (newV, oldV) => {
    for (var i = 0; i < 6; i++) {
        if (newV[i] !== oldV[i]) {
            onCellChanged(i, newV[i])
            return
        }
    }
})

const onCellChanged = (index, newVal, oldVal) => {
    if (!newVal || !newVal.match('^\\d{1}$'))  {
        cells.value[index] = ''
        return
    }

    focusNextCell()

    /* performing character preview if it's enabled */
    // if (secure && characterPreview) {
    //     setTimeout(setCellInputType, 300, index);
    // }
}

const onCellErase = (index, e) => {
    if (!cells.value[index].length) {
        focusPreviousCell();
        e.preventDefault();
    }
}

const onKeyDown = (e) => {
    switch (e.keyCode) {
        /* left arrow key */
    case 37:
        focusPreviousCell();
        break;
        /* right arrow key */
    case 39:
        focusNextCell();
        break;
    default:
        break;
    }
}

</script>

<script>
import AuthLayout from "@layouts/AuthLayout"

export default {
    layout: AuthLayout
}
</script>

<style type="postcss">
    .auth-input-wrapper {
        @apply relative pb-6;
    }
    .auth-input-wrapper button {
        @apply  absolute right-4 top-4
    }
    .auth-input-wrapper button span {
        @apply underline font-semibold
    }
    .auth-input-wrapper input { cursor:pointer;
        @apply block text-white w-full rounded-md border-2 border-white py-3 px-2 md:px-5 bg-transparent text-sm md:text-xxl;
    }
    .auth-input-wrapper input:placeholder {
        @apply italic
    }
    .auth-input-wrapper input.password {
        @apply pr-12;
    }

    .auth-button-wrapper {
        @apply relative pt-12;
    }
    .auth-button-wrapper input[type="submit"] { cursor:pointer;
        @apply block text-palegreen w-full rounded-md border-2 border-palegreen py-3 px-2 md:px-5 bg-transparent text-sm md:text-xxl hover:bg-success hover:border-success hover:text-white transition-all;
    }

    .auth-otp-wrapper {
        @apply relative pb-6 grid grid-cols-6 gap-3 md:gap-4 mx-auto;
    }
    .auth-otp-wrapper input {
        @apply block text-gray-700 w-full rounded-md border-2 border-white py-3 px-2 md:px-5 bg-transparent text-xxl md:text-4xl text-center;
    }
    .auth-input-wrapper [multiple]::placeholder,
    .auth-input-wrapper [type=date]::placeholder,
    .auth-input-wrapper [type=datetime-local]::placeholder,
    .auth-input-wrapper [type=email]::placeholder,
    .auth-input-wrapper [type=month]::placeholder,
    .auth-input-wrapper [type=number]::placeholder,
    .auth-input-wrapper [type=password]::placeholder,
    .auth-input-wrapper [type=search]::placeholder,
    .auth-input-wrapper [type=tel]::placeholder,
    .auth-input-wrapper [type=text]::placeholder,
    .auth-input-wrapper [type=time]::placeholder,
    .auth-input-wrapper [type=url]::placeholder,
    .auth-input-wrapper [type=week]::placeholder,
    .auth-input-wrapper select::placeholder,
    .auth-input-wrapper textarea::placeholder {
        @apply  text-sm md:text-xxl
    }
</style>
