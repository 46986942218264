<template>

    <div class="bg-dark text-white border-t border-light lg:border-none due-bar-wrapper">
        <div class="lg:flex gap-5">
            <div class="col-auto">
                <div :class="{'justify-between items-end': ucActions}" class="flex">
                    <div class="col">
                        <DueSelector :dueProp="due" v-on:valueChanged="emitDueValueChanged" />
                    </div>
                    <div class="col pl-4 lg:hidden">
                        <div v-if="!ucActions" class="toggle-actions-btn" @click="ucActions = true">
                            <span class="font-bold">{{ actions.length }}</span>
                            action{{ actions.length > 1 || actions.length === 0 ? 's' : '' }}
                        </div>
                        <div v-else class="p-2 pb-0" @click="ucActions = false">
                            <div class="icon icon--md">
                                <svg class="icon-svg">
                                    <use xlink:href="#icon-pointer-up"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="ucActions" class="flex-1">
                <div v-if="!actions.length" class="text-xxl font-thin pt-1">No actions due {{ due === 'today' ? 'today' : (due === 'week' ? 'this week' : 'next week') }}</div>
                <div v-else>
                    <div class="grid lg:grid-cols-3 gap-4 uc-actions pt-4 lg:pt-0">
                        <div v-for="(action, index) in filteredActions" :key="index" class="col-auto">
                            <ActionCard :action="action"/>
                        </div>
                    </div>
                    <button v-if="actions.length > 3" type="button" class="mt-2 cursor-pointer flex items-center" style="width: fit-content" @click.stop="showingAllDueActions = !showingAllDueActions">
                        <div class="icon icon--xs">
                            <svg class="icon-svg">
                                <use :xlink:href="showingAllDueActions ? '#icon-pointer-up' : '#icon-pointer-down'"></use>
                            </svg>
                        </div>
                        <span class="ml-2 text-xl">
                                <span v-if="!showingAllDueActions" class="font-bold">{{ actions.length - 3 }}</span> <span class="font-light text-lighter">{{ showingAllDueActions ? 'less' : 'more' }}</span>
                            </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import ActionCard from '@components/ActionCard.vue'
import DueSelector from '@components/DueSelector.vue'
import {computed, onMounted, ref} from "vue";
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    actions: {
        type: Array,
        default: []
    },
    due: {
        type: String,
        required: true
    },
})


const ucActions = ref(true);
onMounted(() => {
    let windowWidth = ref(window.innerWidth);
    if (windowWidth.value > 889) {
        ucActions.value = true
    } else {
        ucActions.value = false
    }
});

const emit = defineEmits(["due-value-changed"])

const emitDueValueChanged = (q) => {
    emit("due-value-changed", q)
}

const showingAllDueActions = ref(false)
const filteredActions = computed(() => {
    if (showingAllDueActions.value) {
        return props.actions;
    } else {
        return props.actions.slice(0, 3);
    }
})
</script>

<style type="postcss" scoped>
    .due-bar-wrapper {padding:12px 20px;}
</style>
