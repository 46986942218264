<template>
    <div class="ft__row__wrap" :class="{'ft__row__wrap--highlight': isHighlighted}" @mouseenter="removeHighlighted()">
        <div class="ft__row">
            <div class="ft__col ft__col--id">
                <div class="no-arrows">
                    <input type="number" min="1" :max="maxOrder" v-model="modelValue" @input="$emit('update:modelValue', $event.target.value)" class="bg-transparent focus:bg-gray-100" />
                </div>
            </div>
            <div class="ft__col ft__col--lg">
                <textarea rows="8" placeholder="..." v-model="questionForm.question" @blur="submitQuestionForm" maxlength="250"></textarea>
            </div>
            <div class="ft__col ft__col--desc">
                <textarea rows="8" placeholder="..." v-model="answerForm.answer" @blur="submitAnswerForm" maxlength="500"></textarea>
            </div>
            <div class="ft__col ft__col--md" style="padding-left: 2.25rem;">
                <div class="text-light lg:hidden">Date Added</div>
                <span class="leading-8">{{ faq.created_at }}</span>
            </div>
            <div class="ft__col-breaker"></div>
            <div class="ft__col ft__col--sdflast">
                <div class="flex items-center justify-end" style="padding-top: 4px;">
                    <div class="col">
                        <div class="icon icon--xs noprint cursor-pointer text-danger" @click="deleteFaq">
                            <svg class="icon-svg">
                                <use xlink:href="#icon-close"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";
import {useForm} from "@inertiajs/inertia-vue3";
import useToast from "@composables/useToast";
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    faq: {
        type: Object,
        required: true
    },
    isActive: {
        type: Boolean,
        default: false
    },
    modelValue: {
        type: String,
        required: true
    },
    maxOrder: {
        type: Number,
        required: true
    }
})

const emit = defineEmits(['update:modelValue'])

const {toast} = useToast()

const displayErrors = (errors) => {
    for (const error in errors) {
        toast('danger', errors[error])
    }
}

const questionForm = useForm({
    question: props.faq.question
})
const submitQuestionForm = () => {
    questionForm.post('/admin/faq/' + props.faq.id + '/edit-question', {
        preserveScroll: true,
        preserveState: (page) => Object.keys(page.props.errors).length,
    })
}


const answerForm = useForm({
    answer: props.faq.answer
})
const submitAnswerForm = () => {
    answerForm.post('/admin/faq/' + props.faq.id + '/edit-answer', {
        preserveScroll: true,
        preserveState: (page) => Object.keys(page.props.errors).length,
    })
}

const deleteFaq = () => {
    Inertia.post('/admin/faq/' + props.faq.id + '/delete',  null, {
        preserveScroll: true,
        preserveState: (page) => Object.keys(page.props.errors).length,
        onBefore: () => confirm('Are you sure you want to delete this FAQ?'),
    })
}

const dropDown = ref(false);
const onClickAway = (event) => {
    dropDown.value = false;
}

const isHighlighted = ref(props.isActive);
function removeHighlighted() {
    isHighlighted.value = false
}

</script>

<style type="postcss" scoped>
.flex-table {
    @apply px-3 text-dark
}
.ft__row__wrap {
    transition:600ms;
    @apply border-b-2 border-gray-200 relative
}
.ft__row {
    @apply flex w-full static flex-wrap lg:flex-nowrap;
}
.ft__row__wrap--highlight {
    @apply bg-highlight;
}
.ft__row--extras .ft__col {
    @apply pt-2;
}
.ft__head {
    @apply hidden lg:block
}
.ft__head .ft__col {font-size:11px;
    @apply py-2 font-semibold text-gray-600
}
.ft__head .ft__col--sorted {
    @apply text-black
}
.ft__head .ft__col .icon {
    @apply inline-block align-middle mr-1
}
.ft__col {padding-left:6px; padding-right:6px;
    @apply py-1 sm:py-2 order-none;
}
.ft__col.np-t {
    @apply pt-0;
}
.ft__col.np-l {
    @apply pl-0;
}
.ft__col--icon {
    @apply w-12
}
.ft__col--icon .icon {
    @apply mt-1
}
.ft__col--id {
    @apply w-20
}
.ft__col--desc { min-width:120px;
    @apply flex-1 flex-grow
}
.ft__col--xs {
    @apply w-28
}
.ft__col--sm {
    @apply w-28
}
.ft__col--md {
    @apply w-44
}
.ft__col--lg {
    @apply w-52
}
.ft__col--due {
    @apply w-36
}
.ft__col--owner {
    @apply w-48 order-none
}
.ft__col--owner .owner-stamp {
}
.ft__col--last {
    @apply lg:w-52 ml-auto relative
}
.ft__col--timeline { width:15rem; }
.ft__col-breaker {
    @apply w-full lg:hidden
}
.ft__col--raised,
.ft__col--due {
    @apply order-2 lg:order-none
}
.ft__col--sorted,
.ft__col--last {
    @apply order-3 lg:order-none
}
.ft__col input[type="text"],
.ft__col input[type="email"],
.ft__col textarea {padding:4px 8px; line-height:16px;
    @apply bg-transparent focus:bg-gray-100 transition-all appearance-none;
}
.ft__row--confirmation {
    @apply justify-end;
}
.confirmation-box {width:460px;
    @apply max-w-full p-3 text-dark
}
.slide-enter,
.slide-leave-active {
    transition: all 666ms ease-in;
}
.slide-enter, .slide-leave-to {
    opacity: 0;
    left: 100%;
}
.ft__row--extras {
    @apply bg-gray-50 lg:bg-transparent;
}
/* .ft__row--extras .ft__col--icon,
.ft__row--extras .ft__col--id {
    @apply hidden
} */
@media(max-width: 1023px) {
    .ft__row.ft__row--extras .ft__col--timeline {
        width:100%; order:6;
    }
    .ft__row.ft__row--extras .ft__col--icon,
    .ft__row.ft__row--extras .ft__col--id,
    .ft__row.ft__row--extras .ft__col--priority {
        @apply hidden
    }

    .ft__col.np-t {
        @apply pt-2
    }
}
.context__navs {
    @apply relative;
}
.context__nav {border-radius:4px;
    @apply pt-2 pl-4 pr-1 rounded-br-none text-right bg-gray-200 font-semibold;
}
.context__navs--open .context__nav {
    @apply bg-gray-600 text-white;
}

.action__status {
    @apply relative;
}
.as__button { line-height:18px; padding-top:1px; padding-bottom:1px; border-radius:4px;
    @apply text-sm font-semibold rounded-br-none bg-transparent border-2 px-1 transition-all w-24 font-semibold;
}

.as__button.closed {
    @apply text-danger border-danger
}
.action__status--open .as__button {
    @apply bg-dark rounded-b-none border-dark text-white
}
</style>
