<template>
    <div class="flex min-h-screen max-w-full w-screen items-center justify-center p-5 bg-dark text-white">
        <div class="auth-card-parent">
            <div class="auth-card">
                <div class="auth-logo pb-12 text-center mb-8">
                    <div class="inline-block align-middle">
                        <img src="/img/logo-full.svg" alt="" class="h-16">
                    </div>
                    <span class="beat-text text-secondary inline-block align-middle ml-1.5 leading-6" style="font-size: 32px;">beat</span>
                </div>
                <slot></slot>
            </div>
            <div class="text-center pt-8">
                <div class="button-links text-secondary">
                    <a href="https://www.sympmy.com/beat" class="btn-text">About Beat</a>
                    <span class="link-separator">|</span>
                    <a href="https://sympmy.com/privacy-policy" class="btn-text">Privacy policy</a>
                    <span class="link-separator">|</span>
                    <a href="https://sympmy.com/terms-and-conditions" class="btn-text">Terms and conditions</a>
                    <span class="link-separator">|</span>
                    <a href="mailto:hello@sympmy.com" class="btn-text">Support</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";

const page = usePage()
const isRegisterPage = computed(() => page.url?.value === '/register')

</script>
<style type="postcss">
    .auth-card-parent {
        width: 480px;
        @apply max-w-full;
    }
    .auth-card {
        width: 480px;
        @apply bg-ddbg p-5 md:p-12 rounded-tl-lg rounded-br-lg max-w-full;
    }

    .button-links {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .link-separator {
        margin: 0 0.5rem;
        @media(min-width: 375px) {
            @apply px-1;
        }
        @media(min-width: 420px) {
            @apply px-2;
        }
        @apply text-white;
    }
</style>
