<template>
    <div class="action__status" :class="{'action__status--open': dropdown}">
        <div class="as__button text-center cursor-pointer nb-focus" @click="toggleDropDown" @keyup.enter="toggleDropDown" @focus="closeDatePopovers" :tabindex="status ==='Closed' ? null : 0" :class="{'open': status ==='Open', 'closed': status ==='Closed', 'escalated': status ==='Escalated', 'closure-requested': status === 'Closure requested' }">{{status}}</div>
        <div class="as_dropdown" v-if="dropdown" v-click-away="closeDropdown">
            <div v-if="!showingClose && !showingCloseRequest && !showingCloseApprove && !showingCloseReject && !showingEscalate && !showingResolve">
                <div v-if="status ==='Escalated' && unresolvedEscalations.length">
                    <div class="mb-4">
                        <div class="font-semibold">Escalated to:</div>
                        <div v-for="(unresolvedEscalation, index) in unresolvedEscalations" :key="index" class="font-light whitespace-nowrap">{{ unresolvedEscalation?.message }}</div>
                        <hr class="border-black mt-4">
                    </div>
                    <div class="as_dropdown__buttons flex-col text-right items-end">
                        <button type="button" class="btn bg-white text-black text-left nb-focus" @click.stop="showResolve" @keyup.enter.stop="showResolve" tabindex="0">Resolve escalation</button>
                        <button type="button" class="btn bg-success text-black text-left nb-focus" @click.stop="showClose" @keyup.enter.stop="showClose" tabindex="0">Close</button>
                        <button type="button" class="btn btn--border border-success text-left nb-focus" @click.stop="showCloseRequest" @keyup.enter.stop="showCloseRequest" tabindex="0">Request closure</button>
                        <button type="button" class="btn bg-danger text-black text-left nb-focus" @click.stop="showEscalate" @keyup.enter.stop="showEscalate" tabindex="0">Re-escalate</button>
                    </div>
                </div>
                <div v-else-if="status ==='Closure requested' && pendingClosureRequest">
                    <div class="mb-4">
                        <div class="font-semibold">Closure requested to:</div>
                        <div class="font-light whitespace-nowrap">{{ pendingClosureRequest.message }}</div>
                        <hr class="border-black mt-4">
                    </div>
                    <div class="as_dropdown__buttons flex-col text-right items-end">
                        <button type="button" class="btn bg-success text-black text-left nb-focus" @click.stop="showCloseApprove" @keyup.enter.stop="showCloseApprove" tabindex="0">Closure approved</button>
                        <button type="button" class="btn bg-danger text-black text-left nb-focus" @click.stop="showCloseReject" @keyup.enter.stop="showCloseReject" tabindex="0">Closure rejected</button>
                        <button type="button" class="btn btn--border border-success text-left nb-focus" @click="submitCancelCloseRequest" @keyup.enter="submitCancelCloseRequest" tabindex="0">Cancel request</button>
                    </div>
                </div>
                <div v-else-if="status ==='Open'">
                    <div class="as_dropdown__buttons flex-col text-right items-end">
                        <button type="button" class="btn bg-success text-black text-left nb-focus" @click.stop="showClose" @keyup.enter.stop="showClose" tabindex="0">Close</button>
                        <button type="button" class="btn btn--border border-success text-left nb-focus" @click.stop="showCloseRequest" @keyup.enter.stop="showCloseRequest" tabindex="0">Request closure</button>
                        <button type="button" class="btn bg-danger text-black text-left nb-focus" @click.stop="showEscalate" @keyup.enter.stop="showEscalate" tabindex="0">Escalate</button>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="as_closed_options" v-if="showingClose">
                    <div class="flex justify-between items-center pb-4 relative">
                        <div class="col">Closed on:</div>
                        <div class="relative" v-click-away="onClickAwayDate">
                            <input type="text" class="date-input" @focus="dateRoughFocused" @click="dateRoughFocused" placeholder="Date" v-model="closeForm.closed_date">
                            <div class="date-drop" v-if="dateDrop">
                                <DatePicker v-model="closeForm.closed_date" :min-date="dueMinDate" :min-date-minus-1="dueMinDateMinus1" :max-date="todaysDate" :max-date-minus-1="todaysDateMinus1" @calendarSelect="closedCalendarDateSelected" />
                            </div>
                        </div>
                    </div>
                    <div class="input-wrapper pb-4">
                        <textarea @focus="closeDatePopovers" class="nb-focus" name="" rows="3" placeholder="Reason for closure" v-model="closeForm.reason"></textarea>
                    </div>
                    <div class="flex justify-end gap-4">
                        <button type="button" class="btn bg-gray-400 text-white nb-focus" @click="closeDropdown" @keyup.enter="closeDropdown" tabindex="0">Cancel</button>
                        <button type="button" class="btn bg-success text-black nb-focus" @click="submitCloseForm" @keyup.enter="submitCloseForm" tabindex="0">OK</button>
                    </div>
                </div>
                <div class="as_closed_options" v-else-if="showingEscalate">
                    <div class="flex justify-between items-center pb-4 relative">
                        <div class="col">Escalated on:</div>
                        <div class="relative" v-click-away="escalateOnClickAwayDate">
                            <div class="w-48">
                                <input type="text" class="date-input" @focus="escalateDateRoughFocused" @click="escalateDateRoughFocused" placeholder="Date" v-model="escalateForm.escalated_date">
                                <div class="date-drop" v-if="escalateDateDrop">
                                    <DatePicker v-model="escalateForm.escalated_date" :min-date="dueMinDate" :min-date-minus-1="dueMinDateMinus1" :max-date="todaysDate" :max-date-minus-1="todaysDateMinus1" @calendarSelect="escalatedCalendarDateSelected" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between items-center pb-4">
                        <div class="col pr-8">Escalate to:</div>
                        <div class="w-48">
                            <div class="owner-stamp" style="width: 100%">
                                <div class="flex items-center">
                                    <div class="col pr-1 pt-2">
                                        <PersonIcon size="xs" />
                                    </div>
                                    <div class="col flex-1">
                                        <AutoComplete @focus="closeDatePopovers" :id="'escalate-person-' + actionId" placeholder="Escalate Person" data-url="/data/users" v-model="escalateForm.escalated_person" class="bg-transparent" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-wrapper pb-4">
                        <textarea class="nb-focus" name="" rows="3" placeholder="Reason for escalation" v-model="escalateForm.reason"></textarea>
                    </div>
                    <div class="flex justify-end gap-4">
                        <button type="button" class="btn bg-gray-400 text-white nb-focus" @click="closeDropdown" @keyup.enter="closeDropdown" tabindex="0">Cancel</button>
                        <button type="button" class="btn bg-success nb-focus" @click="submitEscalateForm" @keyup.enter="submitEscalateForm" tabindex="0">OK</button>
                    </div>
                </div>
                <div class="as_closed_options" v-if="showingResolve && unresolvedEscalations.length">
                    <div class="flex justify-between items-center pb-4 relative">
                        <div class="col">Escalation resolved on:</div>
                        <div class="relative" v-click-away="resolveOnClickAwayDate">
                            <div class="w-48">
                                <input type="text" class="date-input" @focus="resolveDateRoughFocused" @click="resolveDateRoughFocused" placeholder="Date" v-model="resolveForm.resolve_date">
                                <div class="date-drop" v-if="resolveDateDrop">
                                    <DatePicker v-model="resolveForm.resolve_date" :min-date="unresolvedEscalations[unresolvedEscalations.length -1].resolveMinDate" :min-date-minus-1="unresolvedEscalations[unresolvedEscalations.length -1].resolveMinDateMinus1" :max-date="todaysDate" :max-date-minus-1="todaysDateMinus1" @calendarSelect="resolvedCalendarDateSelected" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between items-center pb-4">
                        <div class="col pr-8">Resolved by:</div>
                        <div class="w-48">
                            <div class="owner-stamp" style="width: 100%">
                                <div class="flex items-center">
                                    <div class="col pr-1 pt-2">
                                        <PersonIcon size="xs" />
                                    </div>
                                    <div class="col flex-1">
                                        <AutoComplete @focus="closeDatePopovers" :id="'resolve-person-' + actionId" placeholder="Resolve Person" data-url="/data/users" v-model="resolveForm.resolve_person" class="bg-transparent" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-wrapper pb-4">
                        <textarea @focus="closeDatePopovers" class="nb-focus" name="" rows="3" placeholder="Comments" v-model="resolveForm.comments"></textarea>
                    </div>
                    <div class="flex justify-end gap-4">
                        <button type="button" class="btn bg-gray-400 text-white nb-focus" @click="closeDropdown" @keyup.enter="closeDropdown" tabindex="0">Cancel</button>
                        <button type="button" class="btn bg-success nb-focus" @click="submitResolveForm" @keyup.enter="submitResolveForm" tabindex="0">OK</button>
                    </div>
                </div>
                <div class="as_closed_options" v-else-if="showingCloseRequest">
                    <div class="flex justify-between items-center pb-4 relative">
                        <div class="col">Close requested on:</div>
                        <div class="relative" v-click-away="closeRequestOnClickAwayDate">
                            <div class="w-48">
                                <input type="text" class="date-input" @focus="closeRequestDateRoughFocused" @click="closeRequestDateRoughFocused" placeholder="Date" v-model="closeRequestForm.close_request_date">
                                <div class="date-drop" v-if="closeRequestDateDrop">
                                    <DatePicker v-model="closeRequestForm.close_request_date" :min-date="dueMinDate" :min-date-minus-1="dueMinDateMinus1" :max-date="todaysDate" :max-date-minus-1="todaysDateMinus1" @calendarSelect="closeRequestCalendarDateSelected" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between items-center pb-4">
                        <div class="col pr-8">Requested of:</div>
                        <div class="w-48">
                            <div class="owner-stamp" style="width: 100%">
                                <div class="flex items-center">
                                    <div class="col pr-1 pt-2">
                                        <PersonIcon size="xs" />
                                    </div>
                                    <div class="col flex-1">
                                        <AutoComplete @focus="closeDatePopovers" :id="'close-request-person-' + actionId" placeholder="Close Request Person" data-url="/data/users" v-model="closeRequestForm.close_request_person" class="bg-transparent" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-wrapper pb-4">
                        <textarea class="nb-focus" name="" rows="3" placeholder="Reason for closure request" v-model="closeRequestForm.reason"></textarea>
                    </div>
                    <div class="flex justify-end gap-4">
                        <button type="button" class="btn bg-gray-400 text-white nb-focus" @click="closeDropdown" @keyup.enter="closeDropdown" tabindex="0">Cancel</button>
                        <button type="button" class="btn bg-success nb-focus" @click="submitCloseRequestForm" @keyup.enter="submitCloseRequestForm" tabindex="0">OK</button>
                    </div>
                </div>
                <div class="as_closed_options" v-else-if="showingCloseApprove && pendingClosureRequest">
                    <div class="flex justify-between items-center pb-4 relative">
                        <div class="col">Close approved on:</div>
                        <div class="relative" v-click-away="closeApproveOnClickAwayDate">
                            <div class="w-48">
                                <input type="text" class="date-input" @focus="closeApproveDateRoughFocused" @click="closeApproveDateRoughFocused" placeholder="Date" v-model="closeApproveForm.close_approve_date">
                                <div class="date-drop" v-if="closeApproveDateDrop">
                                    <DatePicker v-model="closeApproveForm.close_approve_date" :min-date="pendingClosureRequest.resolveMinDate" :min-date-minus-1="pendingClosureRequest.resolveMinDateMinus1" :max-date="todaysDate" :max-date-minus-1="todaysDateMinus1" @calendarSelect="closeApproveCalendarDateSelected" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between items-center pb-4">
                        <div class="col pr-8">Approved by:</div>
                        <div class="w-48">
                            <div class="owner-stamp" style="width: 100%">
                                <div class="flex items-center">
                                    <div class="col pr-1 pt-2">
                                        <PersonIcon size="xs" />
                                    </div>
                                    <div class="col flex-1">
                                        <AutoComplete @focus="closeDatePopovers" :id="'close-approve-person-' + actionId" placeholder="Close Approve Person" data-url="/data/users" v-model="closeApproveForm.close_approve_person" class="bg-transparent" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-wrapper pb-4">
                        <textarea class="nb-focus" name="" rows="3" placeholder="Reason for closure" v-model="closeApproveForm.reason"></textarea>
                    </div>
                    <div class="flex justify-end gap-4">
                        <button type="button" class="btn bg-gray-400 text-white nb-focus" @click="closeDropdown" @keyup.enter="closeDropdown" tabindex="0">Cancel</button>
                        <button type="button" class="btn bg-success nb-focus" @click="submitCloseApproveForm" @keyup.enter="submitCloseApproveForm" tabindex="0">OK</button>
                    </div>
                </div>
                <div class="as_closed_options" v-else-if="showingCloseReject && pendingClosureRequest">
                    <div class="flex justify-between items-center pb-4 relative">
                        <div class="col">Close rejected on:</div>
                        <div class="relative" v-click-away="closeRejectOnClickAwayDate">
                            <div class="w-48">
                                <input type="text" class="date-input" @focus="closeRejectDateRoughFocused" @click="closeRejectDateRoughFocused" placeholder="Date" v-model="closeRejectForm.close_reject_date">
                                <div class="date-drop" v-if="closeRejectDateDrop">
                                    <DatePicker v-model="closeRejectForm.close_reject_date" :min-date="pendingClosureRequest.resolveMinDate" :min-date-minus-1="pendingClosureRequest.resolveMinDateMinus1" :max-date="todaysDate" :max-date-minus-1="todaysDateMinus1" @calendarSelect="closeRejectCalendarDateSelected" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between items-center pb-4">
                        <div class="col pr-8">Rejected by:</div>
                        <div class="w-48">
                            <div class="owner-stamp" style="width: 100%">
                                <div class="flex items-center">
                                    <div class="col pr-1 pt-2">
                                        <PersonIcon size="xs" />
                                    </div>
                                    <div class="col flex-1">
                                        <AutoComplete @focus="closeDatePopovers" :id="'close-reject-person-' + actionId" placeholder="Close Reject Person" data-url="/data/users" v-model="closeRejectForm.close_reject_person" class="bg-transparent" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-wrapper pb-4">
                        <textarea class="nb-focus" name="" rows="3" placeholder="Reason for closure rejection" v-model="closeRejectForm.reason"></textarea>
                    </div>
                    <div class="flex justify-end gap-4">
                        <button type="button" class="btn bg-gray-400 text-white nb-focus" @click="closeDropdown" @keyup.enter="closeDropdown" tabindex="0">Cancel</button>
                        <button type="button" class="btn bg-success nb-focus" @click="submitCloseRejectForm" @keyup.enter="submitCloseRejectForm" tabindex="0">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {computed, ref} from "vue"
import {useForm} from "@inertiajs/inertia-vue3"
import useDatePicker from "@composables/useDatePicker"
import DatePicker from '@components/DatePicker.vue'
import AutoComplete from '@components/AutoComplete.vue'
import PersonIcon from '@icons/PersonIcon.vue'
import {watch} from "vue";
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    baseUrl: {
        type: String,
        required: true
    },
    actionId: {
        type: Number,
        required: true
    },
    status: {
        type: String,
        required: true
    },
    unresolvedEscalations: {
        type: Array,
        default: []
    },
    pendingClosureRequest: {
        type: Object,
        default: {}
    },
    dueMinDateMinus1: {
        type: String,
        default: null
    },
    dueMinDate: {
        type: String,
        default: null
    },
    closePopoverProp: {
        type: Boolean,
        default: false
    },
})

const emits = defineEmits(['popoverClosed'])
watch(() => props.closePopoverProp, (newVal) => {
    if (newVal) {
        dateDrop.value = false
        closeRequestDateDrop.value = false
        escalateDateDrop.value = false
        resolveDateDrop.value = false
        closeRejectDateDrop.value = false
        closeApproveDateDrop.value = false
        emits('popoverClosed')
        closeDropdown()
    }
})

const closeDatePopovers = () => {
    onClickAwayDate()
    closeRequestOnClickAwayDate()
    escalateOnClickAwayDate()
    resolveOnClickAwayDate()
    closeRejectOnClickAwayDate()
    closeApproveOnClickAwayDate()
}

const currentDate = computed(() => {
    const today = new Date()
    return today.toLocaleString('en-UK', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    })
})


function useCloseRequest() {
    const showingCloseRequest = ref(false)
    const showCloseRequest = () => {
        showingCloseRequest.value = true
        hideClose()
        hideEscalate()
        hideResolve()
        hideCloseReject()
        hideCloseApprove()
    }
    const hideCloseRequest = () => {
        showingCloseRequest.value = false
    }
    const closeRequestForm = useForm({
        close_request_date: currentDate.value,
        close_request_person: '',
        reason: ''
    })

    const submitCloseRequestForm = () => {
        if (closeRequestForm.close_request_person?.length) {
            closeRequestForm.post(props.baseUrl + '/close-request', {
                preserveScroll: true,
                preserveState: true,
                onFinish: (p) => closeDropdown()
            })
        }
    }

    return {showingCloseRequest, showCloseRequest, hideCloseRequest, closeRequestForm, submitCloseRequestForm}
}
const {showingCloseRequest, showCloseRequest, hideCloseRequest, closeRequestForm, submitCloseRequestForm} = useCloseRequest()
const {dateDrop: closeRequestDateDrop, onClickAwayDate: closeRequestOnClickAwayDate, dateRoughFocused: closeRequestDateRoughFocused} = useDatePicker(closeRequestForm, 'close_request_date')

const closeRequestCalendarDateSelected = () => setTimeout(() => closeRequestOnClickAwayDate(), 100)


function useCloseApprove() {
    const showingCloseApprove = ref(false)
    const showCloseApprove = () => {
        showingCloseApprove.value = true
        hideClose()
        hideEscalate()
        hideResolve()
        hideCloseRequest()
        hideCloseReject()
    }
    const hideCloseApprove = () => {
        showingCloseApprove.value = false
    }
    const closeApproveForm = useForm({
        close_approve_date: currentDate.value,
        close_approve_person: '',
        reason: ''
    })

    const submitCloseApproveForm = () => {
        if (closeApproveForm.close_approve_person?.length) {
            closeApproveForm.post(props.baseUrl + '/close-approve', {
                preserveScroll: true,
                preserveState: true,
                onFinish: (p) => closeDropdown()
            })
        }
    }

    return {showingCloseApprove, showCloseApprove, hideCloseApprove, closeApproveForm, submitCloseApproveForm}
}
const {showingCloseApprove, showCloseApprove, hideCloseApprove, closeApproveForm, submitCloseApproveForm} = useCloseApprove()
const {dateDrop: closeApproveDateDrop, onClickAwayDate: closeApproveOnClickAwayDate, dateRoughFocused: closeApproveDateRoughFocused} = useDatePicker(closeApproveForm, 'close_approve_date')

const closeApproveCalendarDateSelected = () => setTimeout(() => closeApproveOnClickAwayDate(), 100)

function useCloseReject() {
    const showingCloseReject = ref(false)
    const showCloseReject = () => {
        showingCloseReject.value = true
        hideClose()
        hideEscalate()
        hideResolve()
        hideCloseRequest()
        hideCloseApprove()
    }
    const hideCloseReject = () => {
        showingCloseReject.value = false
    }
    const closeRejectForm = useForm({
        close_reject_date: currentDate.value,
        close_reject_person: '',
        reason: ''
    })

    const submitCloseRejectForm = () => {
        if (closeRejectForm.close_reject_person?.length) {
            closeRejectForm.post(props.baseUrl + '/close-reject', {
                preserveScroll: true,
                preserveState: true,
                onFinish: (p) => closeDropdown()
            })
        }
    }

    return {showingCloseReject, showCloseReject, hideCloseReject, closeRejectForm, submitCloseRejectForm}
}
const {showingCloseReject, showCloseReject, hideCloseReject, closeRejectForm, submitCloseRejectForm} = useCloseReject()
const {dateDrop: closeRejectDateDrop, onClickAwayDate: closeRejectOnClickAwayDate, dateRoughFocused: closeRejectDateRoughFocused} = useDatePicker(closeRejectForm, 'close_reject_date')

const closeRejectCalendarDateSelected = () => setTimeout(() => closeRejectOnClickAwayDate(), 100)


const submitCancelCloseRequest = () => {
    Inertia.post(props.baseUrl + '/close-cancel', null, {
        preserveScroll: true,
        preserveState: true,
        onFinish: (p) => closeDropdown()
    })
}

function useClose() {
    const showingClose = ref(false)
    const showClose = () => {
        showingClose.value = true
        hideEscalate()
        hideResolve()
        hideCloseRequest()
        hideCloseReject()
        hideCloseApprove()
    }
    const hideClose = () => {
        showingClose.value = false
    }
    const closeForm = useForm({
        closed_date: currentDate.value,
        reason: ''
    })

    const submitCloseForm = () => {
        closeForm.post(props.baseUrl + '/close',  {
            preserveScroll: true,
            preserveState: true,
            onFinish: (p) => closeDropdown()
        })
    }

    return {showingClose, showClose, hideClose, closeForm, submitCloseForm}
}
const {showingClose, showClose, hideClose, closeForm, submitCloseForm} = useClose()
const {dateDrop, onClickAwayDate, dateRoughFocused} = useDatePicker(closeForm, 'closed_date')

const closedCalendarDateSelected = () => setTimeout(() => onClickAwayDate(), 100)

function useEscalate() {
    const showingEscalate = ref(false)
    const showEscalate = () => {
        showingEscalate.value = true
        hideClose()
        hideResolve()
        hideCloseRequest()
        hideCloseReject()
        hideCloseApprove()
    }
    const hideEscalate = () => {
        showingEscalate.value = false
    }
    const escalateForm = useForm({
        escalated_date: currentDate.value,
        escalated_person: '',
        reason: ''
    })

    const submitEscalateForm = () => {
        if (escalateForm.escalated_person?.length) {
            escalateForm.post(props.baseUrl + '/escalate', {
                preserveScroll: true,
                preserveState: true,
                onFinish: (p) => closeDropdown()
            })
        }
    }

    return {showingEscalate, showEscalate, hideEscalate, escalateForm, submitEscalateForm}
}
const {showingEscalate, showEscalate, hideEscalate, escalateForm, submitEscalateForm} = useEscalate()
const {dateDrop: escalateDateDrop, onClickAwayDate: escalateOnClickAwayDate, dateRoughFocused: escalateDateRoughFocused} = useDatePicker(escalateForm, 'escalated_date')

const escalatedCalendarDateSelected = () => setTimeout(() => escalateOnClickAwayDate(), 100)

function useResolve() {
    const showingResolve = ref(false)
    const showResolve = () => {
        showingResolve.value = true
        hideClose()
        hideEscalate()
        hideCloseRequest()
        hideCloseReject()
        hideCloseApprove()
    }
    const hideResolve = () => {
        showingResolve.value = false
    }
    const resolveForm = useForm({
        resolve_date: currentDate.value,
        resolve_person: '',
        comments: ''
    })

    const submitResolveForm = () => {
        if (resolveForm.resolve_date && resolveForm.resolve_person?.length) {
            resolveForm.post(props.baseUrl + '/resolve-escalate', {
                preserveScroll: true,
                preserveState: true,
                onFinish: (p) => closeDropdown()
            })
        }
    }

    return {showingResolve, showResolve, hideResolve, resolveForm, submitResolveForm}
}
const {showingResolve, showResolve, hideResolve, resolveForm, submitResolveForm} = useResolve()
const {dateDrop: resolveDateDrop, onClickAwayDate: resolveOnClickAwayDate, dateRoughFocused: resolveDateRoughFocused} = useDatePicker(resolveForm, 'resolve_date')

const resolvedCalendarDateSelected = () => setTimeout(() => resolveOnClickAwayDate(), 100)

function useDropdown() {
    const dropdown = ref(false)

    const toggleDropDown = () => {
        if (dropdown.value) {
            closeDropdown()
        } else {
            dropdown.value = true;
        }
    }

    function closeDropdown() {
        if (!dateDrop.value && !escalateDateDrop.value && !resolveDateDrop.value && !closeRequestDateDrop.value && !closeApproveDateDrop.value && !closeRejectDateDrop.value) {
            dropdown.value = false;
            showingClose.value = false;
            showingEscalate.value = false;
            showingResolve.value = false;
            showingCloseRequest.value = false;
            showingCloseReject.value = false;
            showingCloseApprove.value = false;

            closeForm.reason = ""
            closeForm.closed_date = currentDate.value

            escalateForm.reason = ""
            escalateForm.escalated_person = ""
            escalateForm.escalated_date = currentDate.value

            resolveForm.comments = ""
            resolveForm.resolve_person = ""
            resolveForm.resolve_date = currentDate.value

            closeRequestForm.reason = ""
            closeRequestForm.close_request_person = ""
            closeRequestForm.close_request_date = currentDate.value

            closeRejectForm.reason = ""
            closeRejectForm.close_reject_person = ""
            closeRejectForm.close_reject_date = currentDate.value

            closeApproveForm.reason = ""
            closeApproveForm.close_approve_person = ""
            closeApproveForm.close_approve_date = currentDate.value
        }
    }
    return {dropdown, toggleDropDown, closeDropdown}
}
const {dropdown, toggleDropDown, closeDropdown} = useDropdown()

const todaysDate = computed(() => {
    let today = new Date()
    return today.toLocaleString('en-UK', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    })
})
const todaysDateMinus1 = computed(() => {
    let today = new Date()
    const yesterday = new Date(today)

    yesterday.setDate(yesterday.getDate() + 1)
    return yesterday.toLocaleString('en-UK', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    })
})
</script>

<style type="postcss">
    .action__status {
        @apply relative;
    }
    .as__button { line-height:16px; padding:3px 8px; border-radius:4px;
        @apply text-sm rounded-br-none bg-transparent border transition-all lgx:w-full font-semibold text-ddbg border-ddbg;
    }
    .as__button.escalated { padding:3px 7px;
        @apply text-danger border-danger
    }
    .as__button.closure-requested { padding:3px 5px;}
    .as__button.closed {
        @apply border-transparent
    }
    .action__status--open .as__button {
        @apply bg-ddbg rounded-b-none border-ddbg text-white
    }
    .as_dropdown {
        @apply absolute top-full right-0 bg-ddbg text-white p-4 z-10 w-96;
    }

    .as_dropdown__buttons {
        @apply flex gap-4
    }
    .as__btn {
        @apply w-24 py-1 px-3 rounded-md text-center
    }
    .as_dropdown .input-wrapper textarea {
        @apply leading-5 w-full font-thin bg-white border-gray-600 text-dark
    }
    .ft__col .as_closed_options input[type="text"].date-input { line-height:16px; padding:4px 8px;
        @apply bg-lighter rounded-md rounded-br-none font-semibold w-32 text-dark;
    }
    .as_dropdown .owner-stamp {
        @apply bg-lighter text-dark
    }

    .as_dropdown__buttons button.btn {
        @apply w-auto px-4;
    }
    @media(max-width:399px) {
        .as_dropdown {right: -69px;;}
    }
</style>
