<template>
    <div class="content-page cover-page">
        <div class="container-sm mx-auto">
            <div class="bg-white lg:p-12 md:p-10 p-8 lg:border-l lg:border-r border-dark cover-page">
                <div class="md:w-7/12">
                    <div class="lg:mb-12 mb-8 text-dark">
                        <div class="flex mb-2" style="align-items: center">
                            <div class="col pr-3">
                                <div class="icon icon--md">
                                    <svg class="icon-svg">
                                        <use xlink:href="#icon-info-tick"></use>
                                    </svg>
                                </div>
                            </div>
                            <div class="col text-xxl font-light">Thanks for your feedback</div>
                        </div>
                        <p class="text-md">Everything you tell us is valuable. If you've asked for a response, we'll review your feedback and get back to you.</p>
                    </div>
                    <InertiaLink href="/home?due=today" class="btn btn--wide bg-secondary border-secondary text-dark feedback-btn">Back to the actions</InertiaLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>


</script>

<style type="postcss" scoped>


.feedback-btn{
    line-height:18px;
    @apply text-center border font-semibold
}
</style>

<style type="text/css" scoped>
    .content-page { background: #efefef;}
    .cover-page {height: calc(100vh - 112px);}
</style>
