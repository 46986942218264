<template>
    <div class="page__contents">
        <AdminHeader />
        <div class="flex-table">
            <div v-if="displayUser.length" class="ft__head">
                <div class="ft__row">
                    <div class="ft__col ft__col--id cursor-pointer" @click="changeSort('id')" :class="{'ft__col--sorted': q_col === 'id'}">
                        ID
                        <SortIcon v-if="q_col === 'id'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col ft__col--lg cursor-pointer" @click="changeSort('name')" :class="{'ft__col--sorted': q_col === 'name'}">
                        Name
                        <SortIcon v-if="q_col === 'name'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col ft__col--desc ft__col--email cursor-pointer" @click="changeSort('email')" :class="{'ft__col--sorted': q_col === 'email'}">
                        Email
                        <SortIcon v-if="q_col === 'email'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col ft__col--md cursor-pointer" @click="changeSort('storage_used_in_kb')" :class="{'ft__col--sorted': q_col === 'storage_used_in_kb'}">
                        Storage
                        <SortIcon v-if="q_col === 'storage_used_in_kb'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col ft__col--sm cursor-pointer" @click="changeSort('can_access')" :class="{'ft__col--sorted': q_col === 'can_access'}">
                        Access
                        <SortIcon v-if="q_col === 'can_access'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col ft__col--md cursor-pointer" @click="changeSort('notifications_enabled')" :class="{'ft__col--sorted': q_col === 'notifications_enabled'}">
                        Notifications
                        <SortIcon v-if="q_col === 'notifications_enabled'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col ft__col--md cursor-pointer" @click="changeSort('last_login_at')" :class="{'ft__col--sorted': q_col === 'last_login_at'}">
                        Last Login
                        <SortIcon v-if="q_col === 'last_login_at'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col ft__col--md cursor-pointer" @click="changeSort('created_at')" :class="{'ft__col--sorted': q_col === 'created_at'}">
                        Join Date
                        <SortIcon v-if="q_col === 'created_at'" :is-up="q_dir === 'up'" />
                    </div>
                    <div class="ft__col ft__col--last">
                    </div>
                </div>
            </div>
            <div class="ft__body">
                <UserRow v-for="(user, index) in displayUser" :key="index" :user="user" />
            </div>
            <div v-if="hasMore" class="ft__footer text-center mt-5">
                <button type="button" class="btn btn--dark btn--lg w-32" @click="loadMore">load more</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import AdminHeader from "@components/AdminHeader"
import SortIcon from '@icons/SortIcon.vue'
import {computed, ref, watch} from "vue";
    import UserRow from '@components/UserRow.vue'
import {Inertia} from "@inertiajs/inertia";
import axios from "axios";


    const props = defineProps({
        q_col: {
            type: String,
            required: true
        },
        q_dir: {
            type: String,
            required: true
        },
    })

const displayUser = ref([])
const next_url = ref('')

const hasMore = computed(() => next_url.value !== null && next_url.value !== undefined)

const loadMore = async () => {
    if (hasMore.value) {
        await fetchItems(true)
    }
}

const fetchItems = async (more) => {
    if (!more) {
        displayUser.value = []
        next_url.value = null
    }
    let suffix = '?q_col=' + props.q_col + '&q_dir=' + props.q_dir
    let url = more ? next_url.value : ('/admin/users/api'  + suffix)

    await axios.get(url)
        .then((resp) => {
            displayUser.value.push.apply(displayUser.value, resp.data.users)
            next_url.value = resp.data.next_url
        })
        .catch((err) => {
            console.log(err)
        })
}

fetchItems(false)

const sort = ref('priority')

function useSort()
{
    const changeSort = (qCol) => {
        let qDir = props.q_col !== qCol ? 'up' : (props.q_dir === 'up' ? 'down' : 'up')
        let suffix = '?q_col=' + qCol + '&q_dir=' + qDir
        Inertia.visit('/admin/users' + suffix, {
            preserveState: false
        })
    }

    return {changeSort}
}

const {changeSort} = useSort()
</script>
<style type="postcss" scoped>
    .flex-table {
        @apply px-3 text-dark
    }
    .ft__row__wrap {
        transition:600ms;
        @apply border-b-2 border-gray-200 relative
    }
    .ft__row {
        @apply flex w-full static flex-wrap lg:flex-nowrap;
    }
    .ft__row__wrap--highlight {
        @apply bg-highlight;
    }
    .ft__row--extras .ft__col {
        @apply pt-2;
    }
    .ft__head {
        @apply hidden lg:block
    }
    .ft__head .ft__col {font-size:11px;
        @apply py-2 font-semibold text-gray-600
    }
    .ft__head .ft__col--sorted {
        @apply text-black
    }
    .ft__head .ft__col .icon {
        @apply inline-block align-top mt-1 ml-1
    }
    .ft__col {
        @apply p-2 order-none;
    }
    .ft__col.np-t {
        @apply pt-0;
    }
    .ft__col.np-l {
        @apply pl-0;
    }
    .ft__col--icon {
        @apply w-12
    }
    .ft__col--icon .icon {
        @apply mt-1
    }
    .ft__col--id {
        @apply w-20
    }
    .ft__col--desc {
        @apply flex-1 flex-grow
    }
    .ft__col--email {min-width:120px;}
    .ft__col--xs {
        @apply w-28
    }
    .ft__col--sm {
        @apply w-28
    }
    .ft__col--md {
        @apply w-44
    }
    .ft__col--lg {
        @apply w-52
    }
    .ft__col--due {
        @apply w-36
    }
    .ft__col--owner {
        @apply w-48 order-none
    }
    .ft__col--owner .owner-stamp {
    }
    .ft__col--last {
        @apply lg:w-52 ml-auto relative
    }
    .ft__col--timeline { width:15rem; }
    .ft__col-breaker {
        @apply w-full lg:hidden
    }
    .ft__col--raised,
    .ft__col--due {
        @apply order-2 lg:order-none
    }
    .ft__col--sorted,
    .ft__col--last {
        @apply order-3 lg:order-none
    }
    .ft__col input[type="text"],
    .ft__col textarea {
        @apply bg-transparent focus:bg-gray-100 py-1.5 px-2 transition-all leading-4 appearance-none;
    }
    .ft__row--confirmation {
        @apply justify-end;
    }
    .confirmation-box {width:460px;
        @apply max-w-full p-3 text-dark
    }
    .slide-enter,
    .slide-leave-active {
        transition: all 666ms ease-in;
    }
    .slide-enter, .slide-leave-to {
        opacity: 0;
        left: 100%;
    }
    .ft__row--extras {
        @apply bg-gray-50 lg:bg-transparent;
    }
    /* .ft__row--extras .ft__col--icon,
    .ft__row--extras .ft__col--id {
        @apply hidden
    } */
    @media(max-width: 1023px) {
        .ft__row.ft__row--extras .ft__col--timeline {
            width:100%; order:6;
        }
        .ft__row.ft__row--extras .ft__col--icon,
        .ft__row.ft__row--extras .ft__col--id,
        .ft__row.ft__row--extras .ft__col--priority {
            @apply hidden
        }

        .ft__col.np-t {
            @apply pt-2
        }
    }
</style>
<style type="text/css" scoped>

</style>
