<template>
    <div class="p-8 page-head">
        <div class="text-xxl text-dark font-light">
            <span class="inline-block">
                <InertiaLink href="/admin/users" :class="{'underline': !isUsers, 'text-light': !isUsers}">Users</InertiaLink>
                <span class="px-4">|</span>
                <InertiaLink href="/admin/analytics" :class="{'underline':!isAnalytics, 'text-light':!isAnalytics}">Analytics</InertiaLink>
                <span class="px-4">|</span>
                <InertiaLink href="/admin/access" :class="{'underline':!isSettings, 'text-light':!isSettings}">System</InertiaLink>
                <span class="px-4">|</span>
                <InertiaLink href="/admin/faqs" :class="{'underline':!isFAQs, 'text-light':!isFAQs}">FAQs</InertiaLink>
            </span>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";

const isUsers = computed(() => usePage().url.value.startsWith('/admin/users'))
const isAnalytics = computed(() => usePage().url.value.startsWith('/admin/analytics'))
const isSettings = computed(() => usePage().url.value.startsWith('/admin/access'))
const isFAQs = computed(() => usePage().url.value.startsWith('/admin/faqs'))
</script>

<style type="postcss">
.uc-action {
    @apply block border-2 border-light rounded-md p-6;
}
</style>
