<template>
        <div class="setting-wrapper">
            <div class="pb-24 pt-8">
                <div class="grid lg:grid-cols-3">
                    <div class="col pb-5 lg:pb-0 lg:pr-4">
                        <div class="text-xxl text-gray-800 pb-2">Update Details</div>
                        <p>Modify your name and email</p>
                    </div>
                    <div class="col col-span-2">
                        <div class="form__card">
                            <form @submit.prevent="submitProfileInformationForm">
                                <div class="form__card--body">
                                    <div class="input-wrapper" :class="{'input-error': profileInformationForm.errors?.updateProfileInformation?.name}">
                                        <div class="input-label">Name</div>
                                        <input type="text" v-model="profileInformationForm.name" required>
                                    </div>
                                    <div class="input-wrapper" :class="{'input-error': profileInformationForm.errors?.updateProfileInformation?.email}">
                                        <div class="input-label">Email</div>
                                        <input type="email" v-model="profileInformationForm.email" required>
                                    </div>
                                    <FormErrors :errors="profileInformationForm.errors?.updateProfileInformation" />
                                </div>
                                <div class="form__card--footer">
                                    <input type="submit" value="Save" class="btn btn--dark btn--md w-32" :disabled="profileInformationForm.processing">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pb-24">
                <div class="grid lg:grid-cols-3">
                    <div class="col pb-5 lg:pb-0 lg:pr-4">
                        <div class="text-xxl text-gray-800 pb-2">Update Password</div>
                        <p>For enhanced security, it is suggested you update your password frequently</p>
                    </div>
                    <div class="col col-span-2">
                        <div class="form__card">
                            <form @submit.prevent="submitPasswordForm">
                                <div class="form__card--body">
                                    <div class="input-wrapper" :class="{'input-error': passwordForm.errors?.updatePassword?.current_password}">
                                        <div class="input-label">Current Password</div>
                                        <input type="password" v-model="passwordForm.current_password">
                                    </div>
                                    <div class="input-wrapper" :class="{'input-error': passwordForm.errors?.updatePassword?.password}">
                                        <div class="input-label">New Password</div>
                                        <input type="password" v-model="passwordForm.password">
                                    </div>
                                    <div class="input-wrapper" :class="{'input-error': passwordForm.errors?.updatePassword?.password_confirmation}">
                                        <div class="input-label">Confirm Password</div>
                                        <input type="password" v-model="passwordForm.password_confirmation">
                                    </div>
                                    <FormErrors :errors="passwordForm.errors?.updatePassword" />
                                </div>
                                <div class="form__card--footer">
                                    <input type="submit" value="Save" class="btn btn--dark btn--md w-32" :disabled="passwordForm.processing">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pb-24">
                <div class="grid lg:grid-cols-3">
                    <div class="col pb-5 lg:pb-0 lg:pr-4">
                        <div class="text-xxl text-gray-800 pb-2">Two Factor Authentication</div>
                        <p>Add additional security to your account using two factor authentication.</p>
                    </div>
                    <div class="col col-span-2">
                        <div class="form__card">
                            <div class="form__card--body">
                                <h3 v-if="twoFactorEnabled && ! confirming" class="text-xl font-medium text-gray-900">
                                    You have enabled two factor authentication.
                                </h3>
                                <h3 v-else-if="confirming" class="text-xl font-medium text-gray-900">
                                    Finish enabling two factor authentication.
                                </h3>
                                <h3 v-else class="text-xl font-medium text-gray-900">
                                    You have not enabled two factor authentication.
                                </h3>

                                <div class="mt-3 text-lg text-gray-600">
                                    <p>
                                        When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from the Google Authenticator application on your mobile
                                    </p>
                                </div>
                                <div v-if="twoFactorEnabled">
                                    <div v-if="qrCode">
                                        <div class="mt-4 max-w-xl text-sm text-gray-600">
                                            <p v-if="confirming" class="font-semibold">
                                                To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code.
                                            </p>

                                            <p v-else>
                                                Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key.
                                            </p>
                                        </div>

                                        <div class="mt-4" v-html="qrCode" />
                                        <div v-if="confirming" class="mt-4">
                                            <div class="input-wrapper" :class="{'input-error': confirmationForm.errors.code}">
                                                <div class="input-label">Code</div>
                                                <input type="text" autofocus autocomplete="one-time-code" v-model="confirmationForm.code" @keyup.enter="confirmTwoFactorAuthentication" required>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="recoveryCodes.length > 0 && ! confirming">
                                        <div class="mt-4 max-w-xl text-sm text-gray-600">
                                            <p class="font-semibold">
                                                Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.
                                            </p>
                                        </div>

                                        <div class="grid gap-1 max-w-xl mt-4 px-4 py-4 font-mono text-sm bg-gray-100 rounded-lg">
                                            <div v-for="code in recoveryCodes" :key="code">
                                                {{ code }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-5">
                                    <div v-if="!twoFactorEnabled">
                                        <input type="submit" value="Enable" class="btn btn--dark btn--md w-32" :disabled="enabling" @click="enableTwoFactorAuthentication">
                                    </div>
                                    <div v-else>
                                        <input v-if="confirming" type="button" value="Confirm" class="btn btn--dark mr-2" style="width: auto;" :disabled="enabling" @click="confirmTwoFactorAuthentication">
                                        <input v-if="recoveryCodes.length > 0 && ! confirming" type="button" value="Regenerate Recovery Codes" class="btn btn--dark mr-2" style="width: auto;" :disabled="enabling" @click="regenerateRecoveryCodes">
                                        <input v-if="recoveryCodes.length === 0 && ! confirming" type="button" value="Show Recovery Codes" class="btn btn--dark mr-2" style="width: auto;" :disabled="enabling" @click="showRecoveryCodes">
                                        <input v-if="confirming" type="button" value="Cancel" class="btn mr-2" style="width: auto;" :disabled="enabling" @click="disableTwoFactorAuthentication">
                                        <input v-if="!confirming" type="button" value="Disable" class="btn bg-danger text-white mr-2" style="width: auto;" :disabled="enabling" @click="disableTwoFactorAuthentication">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pb-24">
                <div class="grid lg:grid-cols-3">
                    <div class="col pb-5 lg:pb-0 lg:pr-4">
                        <div class="text-xxl text-gray-800 pb-2">Notifications</div>
                        <p>Receive email notifications for your actions</p>
                    </div>
                    <div class="col col-span-2">
                        <div class="form__card">
                            <div class="form__card--body">
                                <h3 v-if="notifications_enabled" class="text-xl font-medium text-gray-900">
                                    You have enabled email notifications.
                                </h3>
                                <h3 v-else class="text-xl font-medium text-gray-900">
                                    You have not enabled email notifications.
                                </h3>

                                <div class="mt-3 text-lg text-gray-600">
                                    <p>
                                        When email notifications are enabled, you will be sent a notification email on the due date then daily after the due date until the action is Closed.
                                    </p>
                                </div>
                                <div class="mt-5">
                                    <input v-if="!notifications_enabled" type="button" value="Enable" class="btn btn--dark btn--md w-32" @click="enableNotifications">
                                    <input v-else type="button" value="Disable" class="btn bg-danger text-white mr-2" style="width: auto;" @click="disableNotifications">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isAdmin" class="pb-24">
                <div class="grid lg:grid-cols-3">
                    <div class="col pb-5 lg:pb-0 lg:pr-4">
                        <div class="text-xxl text-gray-800 pb-2">Customisation Settings</div>
                        <p>Customise Sympmy Beat for your needs</p>
                    </div>
                    <div class="col col-span-2">
                        <div class="row">
                            <div class="col pb-10">
                                <div class="form__card">
                                    <form @submit.prevent="submitActionsForm">
                                        <div class="form__card--body">
                                            <h3 class="text-xl font-medium text-gray-900">
                                                Tolerance
                                            </h3>
                                            <div class="mt-3 text-lg text-gray-600 mb-3">
                                                <p>
                                                    The Tolerance is the number of days before the due date when you wish the action timeline to be displayed Amber as an alert.
                                                </p>
                                                <p>
                                                    e.g. A Tolerance of 0 will display the timeline as Green up to and including the due date. After the due date the timeline will be displayed Red.
                                                </p>
                                                <p>
                                                    A Tolerance of 3 will display the timeline Green up to three days before the due date; it will then be displayed Amber. After the due date the timeline will be displayed Red.
                                                </p>
                                            </div>
                                            <div class="input-wrapper" :class="{'input-error': actionsForm.errors?.tolerance}">
                                                <div class="input-label">Tolerance</div>
                                                <input type="number" min="0" max="999" v-model="actionsForm.tolerance" required>
                                            </div>
                                            <FormErrors :errors="actionsForm.errors" />
                                        </div>
                                        <div class="form__card--footer">
                                            <input type="submit" value="Save" class="btn btn--dark btn--md w-32" :disabled="actionsForm.processing">
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col pb-10">
                                <div class="form__card">
                                    <div class="form__card--body">
                                        <h3 v-if="system_notes_enabled" class="text-xl font-medium text-gray-900">
                                            You have enabled the System Generated Notes.
                                        </h3>
                                        <h3 v-else class="text-xl font-medium text-gray-900">
                                            You have not enabled the System Generated Notes.
                                        </h3>

                                        <div class="mt-3 text-lg text-gray-600">
                                            <p>
                                                All of your interactions with Sympmy Beat are automatically recorded and shown in the Notes panel along with the entries you add. These system generated messages may be disabled allowing you to focus only on the notes you input.
                                            </p>
                                        </div>
                                        <div class="mt-5">
                                            <input v-if="!system_notes_enabled" type="button" value="Enable" class="btn btn--dark btn--md w-32" @click="enableSystemNotes">
                                            <input v-else type="button" value="Disable" class="btn bg-danger text-white mr-2" style="width: auto;" @click="disableSystemNotes">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col pb-10">
                                <div class="form__card">
                                    <div class="form__card--body">
                                        <h3 v-if="tooltips_enabled" class="text-xl font-medium text-gray-900">
                                            You have enabled the Tooltips.
                                        </h3>
                                        <h3 v-else class="text-xl font-medium text-gray-900">
                                            You have not enabled the Tooltips.
                                        </h3>

                                        <div class="mt-3 text-lg text-gray-600">
                                            <p>
                                                Tooltips are displayed when you hover over any of the fields in the 'Add new' bar. You can choose to disable these.
                                            </p>
                                        </div>
                                        <div class="mt-5">
                                            <input v-if="!tooltips_enabled" type="button" value="Enable" class="btn btn--dark btn--md w-32" @click="enableTooltips">
                                            <input v-else type="button" value="Disable" class="btn bg-danger text-white mr-2" style="width: auto;" @click="disableTooltips">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script setup>
import {useForm, usePage} from "@inertiajs/inertia-vue3"
import FormErrors from "@components/FormErrors"
import {Inertia} from "@inertiajs/inertia";
import {computed, ref} from "vue";
import axios from "axios";

const props = defineProps({
    details: {
        type: Object,
        required: true
    },
    two_factor_enabled: {
        type: Boolean,
        required: true
    },
    confirmsTwoFactorAuthentication: {
        type: Boolean,
        required: true
    },
    notifications_enabled: {
        type: Boolean,
        required: true
    },
    tolerance: {
        type: Number,
        required: true
    },
    system_notes_enabled: {
        type: Boolean,
        required: true
    },
    tooltips_enabled: {
        type: Boolean,
        required: true
    },
})


function profile() {
    const profileInformationForm = useForm({
        name: props.details.name,
        email: props.details.email,
    })

    const submitProfileInformationForm = () => {
        profileInformationForm.put('/user/profile-information', {
            preserveScroll: true,
        })
    }
    return {profileInformationForm, submitProfileInformationForm}
}
const {profileInformationForm, submitProfileInformationForm} = profile()

function password() {
    const passwordForm = useForm({
        current_password: null,
        password: null,
        password_confirmation: null,
    })

    const submitPasswordForm = () => {
        passwordForm.put('/user/password', {
            preserveScroll: true,
            onSuccess: visit => {
                passwordForm.reset()
            }
        })
    }
    return {passwordForm, submitPasswordForm}
}
const {passwordForm, submitPasswordForm} = password()


function twoFactor() {
    const enabling = ref(false);
    const confirming = ref(false);
    const disabling = ref(false);
    const qrCode = ref(null);
    const recoveryCodes = ref([]);

    const confirmationForm = useForm({
        code: '',
    });

    const twoFactorEnabled = computed(() => ! enabling.value && props.two_factor_enabled);

    const enableTwoFactorAuthentication = () => {
        enabling.value = true;

        Inertia.post('/user/two-factor-authentication', {}, {
            preserveScroll: true,
            onSuccess: () => Promise.all([
                showQrCode(),
                showRecoveryCodes(),
            ]),
            onFinish: () => {
                enabling.value = false;
                confirming.value = props.confirmsTwoFactorAuthentication
            },
        });
    };

    const showQrCode = () => {
        return axios.get('/user/two-factor-qr-code').then(response => {
            qrCode.value = response.data.svg;
        });
    };

    const showRecoveryCodes = () => {
        return axios.get('/user/two-factor-recovery-codes').then(response => {
            recoveryCodes.value = response.data;
        });
    };

    const confirmTwoFactorAuthentication = () => {
        confirmationForm.post('/user/confirmed-two-factor-authentication', {
            preserveScroll: true,
            preserveState: true,
            onSuccess: () => {
                confirming.value = false;
                qrCode.value = null;
            },
        });
    };

    const regenerateRecoveryCodes = () => {
        axios
            .post('/user/two-factor-recovery-codes')
            .then(() => showRecoveryCodes());
    };

    const disableTwoFactorAuthentication = () => {
        disabling.value = true;

        Inertia.delete('/user/two-factor-authentication', {
            preserveScroll: true,
            onSuccess: () => {
                disabling.value = false;
                confirming.value = false;
            },
        });
    };

    return {twoFactorEnabled, confirming, enabling, qrCode, recoveryCodes, enableTwoFactorAuthentication, confirmationForm, regenerateRecoveryCodes, disableTwoFactorAuthentication, confirmTwoFactorAuthentication, showRecoveryCodes}
}
const {twoFactorEnabled, confirming, enabling, qrCode, recoveryCodes, enableTwoFactorAuthentication, confirmationForm, regenerateRecoveryCodes, disableTwoFactorAuthentication, confirmTwoFactorAuthentication, showRecoveryCodes} = twoFactor()

function notifications() {
    const enableNotifications = () => {
        Inertia.post('/settings/enable-notifications', {}, {
            preserveScroll: true,
        });
    }
    const disableNotifications = () => {
        Inertia.post('/settings/disable-notifications', {}, {
            preserveScroll: true,
        });
    }
    return {enableNotifications, disableNotifications}
}
const {enableNotifications, disableNotifications} = notifications()

function actions() {
    const actionsForm = useForm({
        tolerance: props.tolerance
    })

    const submitActionsForm = () => {
        actionsForm.post('/settings/actions', {
            preserveScroll: true,
        })
    }
    return {actionsForm, submitActionsForm}
}
const {actionsForm, submitActionsForm} = actions()

function systemNotes() {
    const enableSystemNotes = () => {
        Inertia.post('/settings/enable-system-notes', {}, {
            preserveScroll: true,
        });
    }
    const disableSystemNotes = () => {
        Inertia.post('/settings/disable-system-notes', {}, {
            preserveScroll: true,
        });
    }
    return {enableSystemNotes, disableSystemNotes}
}
const {enableSystemNotes, disableSystemNotes} = systemNotes()


function tooltips() {
    const enableTooltips = () => {
        Inertia.post('/settings/enable-tooltips', {}, {
            preserveScroll: true,
        });
    }
    const disableTooltips = () => {
        Inertia.post('/settings/disable-tooltips', {}, {
            preserveScroll: true,
        });
    }
    return {enableTooltips, disableTooltips}
}
const {enableTooltips, disableTooltips} = tooltips()

const isAdmin = computed(() => usePage().props.value.user?.is_admin)
</script>

<style type="postcss">
    .setting-wrapper {
        @apply bg-gray-100 lg:p-12 p-4;
    }
    .form__card {
        @apply bg-white shadow-md rounded-lg
    }
    .form__card--body {
        @apply md:py-10 md:px-8 py-6 px-5 lg:w-9/12
    }
    .form__card--footer {
        @apply md:py-6 py-4 md:px-8 px-5 bg-gray-200 rounded-b-md text-right
    }
    .form__card [multiple],
    .form__card [type=date],
    .form__card [type=datetime-local],
    .form__card [type=email],
    .form__card [type=month],
    .form__card [type=number],
    .form__card [type=password],
    .form__card [type=search],
    .form__card [type=tel],
    .form__card [type=text],
    .form__card [type=time],
    .form__card [type=url],
    .form__card [type=week],
    .form__card select,
    .form__card textarea {
        @apply px-6 py-4 shadow border border-gray-200
    }
    .form__card .input-wrapper {
        @apply pb-8
    }
    .form__card .input-wrapper .input-label {
        @apply pb-2 font-semibold
    }
    .form__card p {
        @apply pb-4 leading-7
    }
    .password-box {
        @apply bg-gray-200 p-6 rounded-lg mt-5
    }
    .password-box p {
        @apply leading-4 pb-3 mb-0;
    }
    .cutom-checkbox {
        @apply relative block mb-2
    }
    .cutom-checkbox input[type="checkbox"] {
        @apply absolute left-0 top-0 opacity-0;
    }
    .cutom-checkbox .cutom-checkbox-label {
        @apply relative pl-9 leading-10 pt-1;
    }
    .cutom-checkbox .cutom-checkbox-label:before,
    .cutom-checkbox .cutom-checkbox-label:after {content:'';
        @apply w-6 h-6 border-2 border-gray-500 inline-block absolute left-0 top-0 transition-all;
    }
    .cutom-checkbox .cutom-checkbox-label:after { transform:rotate(45deg);
        @apply w-2 h-3 border-l-0 border-t-0 ml-2 mt-1 opacity-0;
    }
    .cutom-checkbox input[type="checkbox"]:checked ~ .cutom-checkbox-label:after,
    .cutom-checkbox input[type="checkbox"]:checked ~ .cutom-checkbox-label:before {
        @apply border-dark opacity-100
    }

    .auth-otp-wrapper {
        @apply relative pb-6 grid grid-cols-6 gap-3 md:gap-4 mx-auto;
    }
    .auth-otp-wrapper input {
        @apply block text-gray-700 w-full rounded-md border-2 border-white py-3 px-2 md:px-5 bg-transparent text-2xl md:text-4xl text-center;
    }
</style>
