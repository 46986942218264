<template>
    <form @submit.prevent="submit">
        <div class="auth-input-wrapper" :class="{'input-error': form.errors?.email}">
            <input type="email" placeholder="Email address" v-model="form.email">
        </div>
        <div class="auth-input-wrapper" :class="{'input-error': form.errors?.password}">
            <input type="password" placeholder="Password" v-model="form.password">
        </div>

        <div class="auth-input-wrapper mt-6">
            <div class="custom-checkbox">
                <label class="flex items-center">
                    <input type="checkbox" class="pr-2" v-model="form.remember">
                    <span class="align-middle ml-0 md:ml-2 text-md md:text-xl">Stay signed in on this device</span>
                </label>
            </div>
        </div>

        <FormErrors :errors="form.errors" />
        <div class="auth-button-wrapper pb-8">
            <input type="submit" value="Sign in" :disabled="form.processing">
        </div>
        <div class="text-center border-t border-gray-400 pt-8">
            <InertiaLink href="/forgot-password" class="btn-text text-lg">Forgot Password</InertiaLink>
        </div>
    </form>
</template>

<script setup>
import {useForm} from "@inertiajs/inertia-vue3"
import FormErrors from "@components/FormErrors";
import useToast from "../../composables/useToast";
import {onMounted} from "vue";

const props = defineProps({
    status: {
        type: Object,
        default: null
    }
})

const { toast } = useToast()
onMounted(() => {
    if (props.status !== null && props.status?.type && props.status?.message) {
        if (props.status.type === 'danger') {
            toast('danger', props.status.message)
        } else if (props.status.type === 'success') {
            toast('success', props.status.message)
        } else {
            toast('info', props.status.message)
        }
    }
})

const form = useForm({
    email: "",
    password: "",
    remember: false,
})

const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post('/login', {
        onFinish: () => form.reset('password'),
    });
};
</script>

<script>
import AuthLayout from "@layouts/AuthLayout"

export default {
    layout: AuthLayout
}
</script>

<style type="postcss" scoped>
    .auth-input-wrapper {
        @apply relative pb-6;
    }
    .auth-input-wrapper button {
        @apply  absolute right-4 top-4
    }
    .auth-input-wrapper button span {
        @apply underline font-semibold
    }
    .auth-input-wrapper input { cursor:pointer;
        @apply block text-white w-full rounded-md border-2 border-white py-3 px-2 md:px-5 bg-transparent text-sm md:text-xxl;
    }
    .auth-input-wrapper input:placeholder {
        @apply italic
    }
    .auth-input-wrapper input.password {
        @apply pr-12;
    }

    .auth-button-wrapper {
        @apply relative pt-12;
    }
    .auth-button-wrapper input[type="submit"] { cursor:pointer;
        @apply block text-palegreen w-full rounded-md border-2 border-palegreen py-3 px-2 md:px-5 bg-transparent text-sm md:text-xxl hover:bg-success hover:border-success hover:text-white transition-all;
    }

    .auth-otp-wrapper {
        @apply relative pb-6 grid grid-cols-6 gap-3 md:gap-4 mx-auto;
    }
    .auth-otp-wrapper input {
        @apply block text-gray-700 w-full rounded-md border-2 border-white py-3 px-2 md:px-5 bg-transparent text-xxl md:text-4xl text-center;
    }
    .auth-input-wrapper [multiple]::placeholder,
    .auth-input-wrapper [type=date]::placeholder,
    .auth-input-wrapper [type=datetime-local]::placeholder,
    .auth-input-wrapper [type=email]::placeholder,
    .auth-input-wrapper [type=month]::placeholder,
    .auth-input-wrapper [type=number]::placeholder,
    .auth-input-wrapper [type=password]::placeholder,
    .auth-input-wrapper [type=search]::placeholder,
    .auth-input-wrapper [type=tel]::placeholder,
    .auth-input-wrapper [type=text]::placeholder,
    .auth-input-wrapper [type=time]::placeholder,
    .auth-input-wrapper [type=url]::placeholder,
    .auth-input-wrapper [type=week]::placeholder,
    .auth-input-wrapper select::placeholder,
    .auth-input-wrapper textarea::placeholder {
        @apply  text-sm md:text-xxl
    }

    .auth-input-wrapper {
        .custom-checkbox {
            @apply relative;
        }
        .custom-checkbox input[type="checkbox"] {
            @apply absolute left-0 right-0 opacity-0;
        }
        .custom-checkbox label {
            @apply block pl-10;
            @apply flex items-center;
        }
        .custom-checkbox span:before {
            content: '';
            border-radius: 6px;
            @apply md:w-9 md:h-9 w-7 h-7  border-2 border-white inline-block absolute left-0 top-1/2 transform -translate-y-1/2;
            background: #444b52;
        }
        .custom-checkbox span:after {
            content: '';
            transform: rotate(0deg);
            @apply border-none absolute hidden bg-no-repeat bg-center;
            background-image: url('data:image/svg+xml,<svg width="100%" height="100%" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><rect id="tick-12px" serif:id="tick 12px" x="0" y="0" width="12" height="12" style="fill:none;"/><path d="M0.435,7.133L4.215,10.873C4.318,10.975 4.461,11.027 4.605,11.015C4.75,11.004 4.882,10.93 4.969,10.813L11.617,1.779C11.78,1.557 11.733,1.244 11.51,1.08C11.288,0.917 10.975,0.964 10.811,1.187L4.506,9.755C4.506,9.755 1.137,6.421 1.137,6.421C0.941,6.227 0.624,6.229 0.43,6.426C0.236,6.622 0.238,6.939 0.435,7.133Z" style="fill:rgb(39,44,48);"/></svg>');
            left: 2px;
            top: 2px;
            height: 17px;
            width: 17px;
        }
        @screen md {
            .custom-checkbox span:after {
                height: 20px;
                width: 20px;
                top:2px;
                left:4px;
            }
        }
        .custom-checkbox input[type="checkbox"]:checked ~ span:before {
            @apply border-white bg-white;
        }
        .custom-checkbox input[type="checkbox"]:checked ~ span:after {
            @apply block;
        }
    }

</style>
