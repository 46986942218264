<template>
    <div class="page-wrapper">
        <div class="page-top-wrap">
            <div class="page-header">
                <div class="flex gap-3 sm:gap-5 items-center">
                    <div class="col">
                        <InertiaLink href="/">
                            <div class="inline-block align-middle">
                                <img src="/img/logo-full.svg" alt="" class="logo-img">
                            </div>
                            <span class="beat-text text-secondary inline-block align-middle ml-1.5 leading-6">beat</span>
                        </InertiaLink>
                    </div>
                    <div class="col">
                        <div class="header-time hidden sm:block">{{ currentTime }}</div>
                    </div>
                    <div class="col hidden lg:inline-block">
                        {{ currentDayOfTheWeek }}
                        <span class="font-semibold">{{ currentDay + ' ' + currentMonth + ' ' + currentYear }}</span>
                    </div>
                    <div class="col lg:hidden hidden sm:inline-block">
                        <span class="font-semibold whitespace-nowrap">{{ currentDay + ' ' + currentMonth }}</span>
                    </div>
                </div>
                <div class="col">
                    <div class="flex gap-1 items-end noprint">
                        <div class="col lg:hidden" v-if="!isOnSettingsPage && newBar">
                            <button type="button" class="btn-add-new" @click="newBarVisible = !newBarVisible" :class="{'btn-add-new--open': newBarVisible}">
                                <span class="icon">
                                    <svg class="icon-svg pointer-events-none">
                                        <use xlink:href="#icon-add-2"></use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div v-if="!isAdmin" class="col">
                            <div class="user-actions" :class="{'user-actions--open': dropDown2}" v-click-away="onClickAway2">
                                <button type="button" class="user-action" @click="dropDown2 = !dropDown2">
                                    <span class="icon">
                                        <svg class="icon-svg">
                                            <use xlink:href="#icon-help"></use>
                                        </svg>
                                    </span>
                                </button>
                                <transition name="xxx">
                                    <ul class="dropdown" v-if="dropDown2">
                                        <li>
                                            <InertiaLink href="/faqs" @click="dropDown2 = false">FAQs</InertiaLink>
                                        </li>
                                        <li>
                                            <InertiaLink href="/feedback" @click="dropDown2 = false">Feedback</InertiaLink>
                                        </li>
                                    </ul>
                                </transition>
                            </div>
                        </div>
                        <div class="col">
                            <div class="user-actions" :class="{'user-actions--open': dropDown}" v-click-away="onClickAway">
                                <button type="button" class="user-action" @click="dropDown = !dropDown">
                                    <span class="icon">
                                        <svg class="icon-svg">
                                            <use xlink:href="#icon-user"></use>
                                        </svg>
                                    </span>
                                </button>
                                <transition name="xxx">
                                    <ul class="dropdown" v-if="dropDown">
                                        <li>
                                            <InertiaLink href="/settings" @click="dropDown = false">Settings</InertiaLink>
                                        </li>
                                        <li>
                                            <a href="#" @click.stop.prevent="logout">Sign out</a>
                                        </li>
                                    </ul>
                                </transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <add-new-bar v-if="!isOnSettingsPage && newBarVisible && newBar && !isAdmin" @added="clickOutside"></add-new-bar>
            <AddUserBar v-if="!isOnSettingsPage && newBarVisible && newBar && isAdmin" @added="clickOutside" />
        </div>
        <div class="page-contents-wrap" :class="{'with-new-bar': !isOnSettingsPage && newBar}">
            <div class="lg:pt-1" v-if="newBar"></div>
            <div class="lg:pt-2" v-if="!newBar"></div>
            <slot></slot>
        </div>
        <icon-stripes></icon-stripes>
    </div>
</template>
<script setup>
import {ref, onMounted, computed, watch} from "vue";
    import IconStripes from '@components/Icons.vue'
    import AddNewBar from '@components/AddNewBar.vue'
    import AddUserBar from '@components/AddUserBar.vue'
    import {usePage} from "@inertiajs/inertia-vue3";
    // import useToast from "@composables/useToast"
    import {Inertia} from "@inertiajs/inertia";
    const dropDown = ref(false);
    const dropDown2 = ref(false);
    // const props = defineProps({
    //     newBar: {
    //         type: Boolean,
    //         default: false
    //     }
    // });
    const newBar = ref(true);
    const newBarVisible = ref(false);
    const oldWindowWidth = ref(window.innerWidth);
    function responsiveAddnewBar() {
        let windowWidth = ref(window.innerWidth);
        // if(windowWidth.value > 889) {
        if (!oldWindowWidth.value <= 890 && windowWidth.value > 889) {
            newBarVisible.value = true
        } else if(oldWindowWidth.value < 889 && newBarVisible.value == true) {
            newBarVisible.value = true
        } else {
            newBarVisible.value = false
        }
    }
    onMounted(() => {
        responsiveAddnewBar();
        let delay;
        window.addEventListener('resize', function(event){
            responsiveAddnewBar();
            clearTimeout(delay);
            delay = setTimeout(function() {
                oldWindowWidth.value = window.innerWidth;
            }, 150);
        });
    });

    const onClickAway = (event) => {
        dropDown.value = false;
    }

    const onClickAway2 = (event) => {
        dropDown2.value = false;
    }
    const clickOutside = (event) => {
        let windowWidth = ref(window.innerWidth);
        if(event?.target?.className != 'btn-add-new btn-add-new--open') {
            if(windowWidth.value < 890) {
                //setTimeout(() => newBarVisible.value = false, 300)
                newBarVisible.value = false
            }
        }
    }

    // const {toast} = useToast()
    // function useFlashMessages() {
    //     const flash = computed(() => usePage().props.value.flash)
    //
    //     watch(flash, (newV, oldV) => {
    //         if (newV && newV.type && newV.message) {
    //             toast(newV.type, newV.message)
    //         }
    //     }, {
    //         immediate: true
    //     })
    // }
    // useFlashMessages()
    // function useErrorMessages() {
    //     const errors = computed(() => usePage().props.value.errors)
    //
    //     watch(errors, (newV, oldV) => {
    //         if (newV && Object.keys(newV).length) {
    //             for (const error in newV) {
    //                 toast('danger', newV[error])
    //             }
    //         }
    //     }, {
    //         immediate: true
    //     })
    // }
    // useErrorMessages()

const logout = () => Inertia.post('/logout')

function useCurrentDateTime() {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ]
    const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']

    const currentDay = ref(null)
    const currentMonth = ref(null)
    const currentYear = ref(null)
    const currentDayOfTheWeek = ref(null)
    const currentTime = ref(null)

    const getNow = () => {
        const today = new Date()
        currentDay.value = ('0' + today.getDate()).slice(-2)
        currentMonth.value = monthNames[today.getMonth()]
        currentYear.value = today.getFullYear()
        currentDayOfTheWeek.value = days[today.getDay()]
        currentTime.value = ('0' + today.getHours()).slice(-2) + ":" + ('0' + today.getMinutes()).slice(-2)
    }
    getNow()
setInterval(getNow, 1000)

    return {currentDay, currentMonth, currentYear, currentDayOfTheWeek, currentTime}
}

const {currentDay, currentMonth, currentYear, currentDayOfTheWeek, currentTime} = useCurrentDateTime()

const isAdmin = computed(() => usePage().props.value.user?.is_admin)

const isOnSettingsPage = computed(() => usePage().url.value.startsWith('/settings'))
</script>
<style type="postcss">
    .page-top-wrap {
        @apply lg:fixed top-0 left-0 w-full z-50;
    }
    .page-contents-wrap {
        @apply lg:pt-16
    }
    @media(min-width:890px) {
        .page-contents-wrap.with-new-bar {padding-top:100px;}
    }
    .page-header { padding-top:7px; padding-bottom:7px;
        @apply px-4 bg-dark text-white flex items-end justify-between;
    }
    .logo-img {height:28px;}
    .beat-text {font-size:22px;}
    .header-time { font-size:13px;
        @apply px-2 py-0.5 bg-ddbg text-white rounded
    }
    .user-actions { margin-bottom:-7px;
        @apply relative
    }
    .btn-add-new { margin-bottom:-7px;
        @apply relative w-12 p-2 rounded-t-lg;
        padding-bottom:13px;
    }
    .btn-add-new:before {content:'';
        @apply absolute left-0 top-0 right-0 bottom-0 z-10
    }
    .user-action {
        @apply w-12 p-2 rounded-t-lg;
        padding-bottom:13px;
    }
    .user-actions--open .user-action {
        @apply bg-ddbg;
    }
    .btn-add-new--open {
        @apply bg-ddbg;
    }
    .page-header .dropdown {
        @apply  w-36 text-right rounded-lg rounded-tr-none border-t-0;
    }
</style>
