import {ref, watch} from "vue";

export default function useDatePicker(form, dateProperty) {
    const oldDateValue = ref(form[dateProperty])

    const dateDrop = ref(false);

    const onClickAwayDate = (event) => {
        dateDrop.value = false;
    }

    const dateRoughFocused = () => {
        dateDrop.value = true
        // oldDateValue.value = form[dateProperty]
        // form[dateProperty] = ""
    }
    return {dateDrop, onClickAwayDate, dateRoughFocused}
}
