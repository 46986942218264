<template>
    <div class="page__contents">
        <AdminHeader />

        <div class="setting-wrapper">
            <div class="pb-24">
                <div class="grid lg:grid-cols-3">
                    <div class="col pb-5 lg:pb-0 lg:pr-4">
                        <div class="text-xxl text-gray-800 pb-2">System Access</div>
                        <p>Control if whether Non-Admin users can log in or not.</p>
                    </div>
                    <div class="col col-span-2">
                        <div class="form__card">
                            <div class="form__card--body">
                                <h3 v-if="accessEnabled" class="text-xl font-medium text-gray-900">
                                    You have enabled User Access.
                                </h3>
                                <h3 v-else class="text-xl font-medium text-gray-900">
                                    You have not enabled User Access.
                                </h3>

                                <div class="mt-3 text-lg text-gray-600">
                                    <p>
                                        Control if whether Non-Admin users can log in or not.
                                    </p>
                                </div>
                                <div class="mt-5">
                                    <input v-if="!accessEnabled" type="button" value="Enable" class="btn btn--dark btn--md w-32" @click="enableAccess">
                                    <input v-else type="button" value="Disable" class="btn bg-danger text-white mr-2" style="width: auto;" @click="disableAccess">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import AdminHeader from "@components/AdminHeader"
import {Inertia} from "@inertiajs/inertia";
import useToast from "@composables/useToast";

    const props = defineProps({
        accessEnabled: {
            type: Boolean,
            required: true
        }
    })


const {toast} = useToast()

const displayErrors = (errors) => {
    for (const error in errors) {
        toast('danger', errors[error])
    }
}
const enableAccess = () => {
    Inertia.post('/admin/access/enable',  null, {
        preserveScroll: true,
        preserveState: (page) => Object.keys(page.props.errors).length,
    })
}
const disableAccess = () => {
    Inertia.post('/admin/access/disable',  null, {
        preserveScroll: true,
        preserveState: (page) => Object.keys(page.props.errors).length,
    })
}

</script>
<style type="postcss" scoped>
    .flex-table {
        @apply px-3 text-dark
    }
    .ft__row__wrap {
        transition:600ms;
        @apply border-b-2 border-gray-200 relative
    }
    .ft__row {
        @apply flex w-full static flex-wrap lg:flex-nowrap;
    }
    .ft__row__wrap--highlight {
        @apply bg-highlight;
    }
    .ft__row--extras .ft__col {
        @apply pt-2;
    }
    .ft__head {
        @apply hidden lg:block
    }
    .ft__head .ft__col {font-size:11px;
        @apply py-2 font-semibold text-gray-600
    }
    .ft__head .ft__col--sorted {
        @apply text-black
    }
    .ft__head .ft__col .icon {
        @apply inline-block align-middle mr-1
    }
    .ft__col {
        @apply p-2 order-none;
    }
    .ft__col.np-t {
        @apply pt-0;
    }
    .ft__col.np-l {
        @apply pl-0;
    }
    .ft__col--icon {
        @apply w-12
    }
    .ft__col--icon .icon {
        @apply mt-1
    }
    .ft__col--id {
        @apply w-20
    }
    .ft__col--desc {
        @apply flex-1 flex-grow
    }
    .ft__col--xs {
        @apply w-28
    }
    .ft__col--sm {
        @apply w-28
    }
    .ft__col--md {
        @apply w-44
    }
    .ft__col--lg {
        @apply w-52
    }
    .ft__col--due {
        @apply w-36
    }
    .ft__col--owner {
        @apply w-48 order-none
    }
    .ft__col--owner .owner-stamp {
    }
    .ft__col--last {
        @apply lg:w-52 ml-auto -mb-14 lg:m-0 relative
    }
    .ft__col--timeline { width:15rem; }
    .ft__col-breaker {
        @apply w-full lg:hidden
    }
    .ft__col--raised,
    .ft__col--due {
        @apply order-2 lg:order-none
    }
    .ft__col--sorted,
    .ft__col--last {
        @apply order-3 lg:order-none
    }
    .ft__col input[type="text"],
    .ft__col textarea {
        @apply bg-transparent focus:bg-gray-100 py-1.5 px-2 transition-all leading-4 appearance-none;
    }
    .ft__row--confirmation {
        @apply justify-end;
    }
    .confirmation-box {width:460px;
        @apply max-w-full p-3 text-dark
    }
    .slide-enter,
    .slide-leave-active {
        transition: all 666ms ease-in;
    }
    .slide-enter, .slide-leave-to {
        opacity: 0;
        left: 100%;
    }
    .ft__row--extras {
        @apply bg-gray-50 lg:bg-transparent;
    }
    /* .ft__row--extras .ft__col--icon,
    .ft__row--extras .ft__col--id {
        @apply hidden
    } */
    @media(max-width: 1023px) {
        .ft__row.ft__row--extras .ft__col--timeline {
            width:100%; order:6;
        }
        .ft__row.ft__row--extras .ft__col--icon,
        .ft__row.ft__row--extras .ft__col--id,
        .ft__row.ft__row--extras .ft__col--priority {
            @apply hidden
        }

        .ft__col.np-t {
            @apply pt-2
        }
    }
    .setting-wrapper {
        @apply bg-gray-100 lg:p-12 p-4;
    }
    .form__card {
        @apply bg-white shadow-md rounded-lg
    }
    .form__card--body {
        @apply md:py-10 md:px-8 py-6 px-5 lg:w-9/12
    }
    .form__card--footer {
        @apply md:py-6 py-4 md:px-8 px-5 bg-gray-200 rounded-b-md text-right
    }
</style>
<style type="text/css" scoped>

</style>
