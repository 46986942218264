<template>
    <InertiaLink class="uc-action bg-ddbg relative" :href="'/project/' + action.project_slug + '?action=' + action.id">
        <div class="uc-action-top">
            <div v-if="action.type" class="col-auto">
                <div class="icon icon--medium" :class="typeIconPriorityColourClass">
                    <svg class="icon-svg">
                        <use v-if="action.type === 'Decision'" xlink:href="#icon-decision"></use>
                         <use v-else-if="action.type === 'Activity'" xlink:href="#icon-activity"></use>
                    </svg>
                </div>
            </div>
            <div class="col-auto">
                <div class="font-semibold">{{ action.project_index_id }}</div>
            </div>
            <div class="col-auto">
                <div>{{ action.description }}</div>
                <div>({{ action.project }})</div>
            </div>
        </div>
        <div class="uc-action-bottom">
            <div class="col">
                <div v-if="!action.days_overdue">
                    Due <span class="font-semibold">{{ action.due_date_short }}</span>
                </div>
                <div v-else class="flex items-center">
                    <div class="icon icon--md">
                        <svg class="icon-svg">
                            <use xlink:href="#icon-time"></use>
                        </svg>
                    </div>
                    <span class="font-semibold ml-2">{{action.days_overdue + " day" + (action.days_overdue > 1 ? "s" : "") }} overdue</span>
                </div>
            </div>
            <div class="col">
                <div class="flex items-center">
                    <div class="icon icon--xs">
                        <svg class="icon-svg">
                            <use xlink:href="#icon-person"></use>
                        </svg>
                    </div>
                    <div class="pl-2">{{ action.owner }}</div>
                </div>
            </div>
        </div>
    </InertiaLink>
</template>

<script setup>
import {computed} from "vue"

const props = defineProps({
    action: {
        type: Object,
        required: true
    },
})

const typeIconPriorityColourClass = computed(() => props.action?.priority === 'Critical' ? 'text-danger' : (props.action?.priority === 'High' ? 'text-error' : (props.action?.priority === 'Medium' ? 'text-warning' : (props.action?.priority === 'Low' ? 'text-success' : 'text-lighter'))))

</script>

<style type="postcss">
.uc-action { border-radius: 4px;
    @apply flex flex-col border border-light h-full;
}
.uc-action-top { padding: 8px 12px; min-height: 60px;
    @apply flex gap-3 flex-1;
}
.uc-action-bottom {
    background-color: #2F353B; padding: 4px 8px; line-height: 20px; border-bottom-right-radius: 4px; border-bottom-left-radius: 4px;
    @apply flex justify-between items-center w-full
}
</style>
