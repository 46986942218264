<template>
    <div class="date-picker">
        <DatePicker mode="date" v-model="date" trim-weeks :first-day-of-week="2" :attributes='attrs' :min-date="minDate" :max-date="maxDate" @update:modelValue="dateSelected" @blur="blurred" />
    </div>
</template>
<script setup>
import {computed, ref, watch} from "vue"
import 'v-calendar/dist/style.css'
import { DatePicker } from 'v-calendar'

const props = defineProps({
    modelValue: {
        type: String,
        default: null
    },
    minDateMinus1: {
        type: String,
        default: null
    },
    maxDateMinus1: {
        type: String,
        default: null
    },
    minDate: {
        type: String,
        default: null
    },
    maxDate: {
        type: String,
        default: null
    },
})

const emit = defineEmits(['update:modelValue', 'calendarSelect'])

const date = ref(props.modelValue)

watch(() => props.modelValue, (newVal) => {
    if (newVal !== parsedDate.value) {
        date.value = null
    }
})

watch(date, (newVal) => {
    if (date.value) {
        emit('update:modelValue', parsedDate.value)
    }
});

const blurred = () => emit('calendarSelect')

const dateSelected = () => {
    emit('calendarSelect')
}

const parsedDate = computed(() => {
    return date.value
        ? date.value.toLocaleString('en-UK', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        })
        : null
})

const attrs = [
    {
        key: 'today',
        // highlight: true,
        dot: true,
        dates: new Date(),
    },
]

if (props.minDateMinus1) {
    attrs.push({
        key:'past',
        highlight:{
            contentClass:'disabledDate',
        },
        dates: {
            end: props.minDateMinus1,
        }
    })
}
if (props.maxDateMinus1) {
    attrs.push({
        key:'past2',
        highlight:{
            contentClass:'disabledDate',
        },
        dates: {
            start: props.maxDateMinus1,
        }
    })
}


</script>
<style type="postcss">
.date-picker .vc-container {
    @apply border-0 bg-dark rounded-lg rounded-tr-none text-white
}
.vc-header {
    @apply pb-3
}
.vc-title {
    @apply text-white text-base font-normal;
}
.vc-header {
    @apply py-2;
}
.vc-pane { min-width:260px;}
.vc-weeks {
    @apply gap-2 p-2
}
.vc-day {min-height:28px;
    border-radius:0.25rem !important;
    @apply bg-white text-dark rounded
}
.vc-day:nth-child(7n - 1),
.vc-day:nth-child(7n) {
    @apply bg-gray-300;
}
.vc-weekday {
    @apply hidden;
}
.vc-day.is-not-in-month {
    @apply opacity-50
}
.vc-day.is-not-in-month * {
    @apply opacity-100
}
.vc-arrow {
    @apply w-10 h-5 rounded bg-gray-300;
}
.vc-arrow.is-left {
    @apply rounded-r-none text-left justify-start pl-1;
}
.vc-arrow.is-right {
    @apply rounded-l-none text-left justify-end pr-1;
}
.vc-arrow.is-left:before {
    content:''; border:0 solid transparent; border-right:8px solid #000; border-top-width:4px; border-bottom-width:4px;
}
.vc-arrow.is-right:before {
    content:''; border:0 solid transparent; border-left:8px solid #000; border-top-width:4px; border-bottom-width:4px;
}
.vc-arrow svg {
    @apply hidden
}
.vc-day-content {
    @apply font-bold
}
.vc-day-content,
.vc-highlight { border-radius:0.25rem !important; color:#272c30 !important;
    @apply rounded w-full
}
.vc-day-content.is-disabled,
.vc-day-content.is-not-in-month {
    cursor: not-allowed;
}
.vc-day-content:focus {
    text-decoration: underline;
}
.vc-highlight { background-color:#fbdb4d !important;
    @apply text-dark
}
.disabledDate,
.disabledDate:hover{
    border-radius:0.25rem !important;
    background-color: gray !important;
    opacity: 1;
    @apply rounded w-full text-dark
}
.vc-day-layer{
    border-radius:0.25rem !important;
}
</style>
