<template>
    <div class="labels-input-wrapper" style="gap: 12px;">
        <div class="label-item" v-for="(label, index) in labels" :key="index" :style="{'padding-right': canAdd ? '2rem' : '8px'}">
            {{ label.name }}
            <div v-if="canAdd" class="label-close noprint nb-focus" @click="removeLabel(label.id)" @keyup.enter="removeLabel(label.id)" tabindex="0">
                <div class="icon icon--xs">
                    <svg class="icon-svg">
                        <use xlink:href="#icon-close"></use>
                    </svg>
                </div>
            </div>
        </div>
        <input v-if="canAdd && showingAdd" type="text" class="new-lable-input noprint nb-focus" v-model="form.name" @blur="addLabel" @keyup.enter="addLabel" ref="input" tabindex="0" style="height: 21px">
        <div v-if="canAdd && !showingAdd" class="new-lable-btn noprint nb-focus" @click="showAdd" @keyup.enter="showAdd" tabindex="0">
            <span v-if="labels.length === 0" class="pr-2">Labels</span>
            <div class="icon icon--xs">
                <svg class="icon-svg">
                    <use xlink:href="#icon-add"></use>
                </svg>
            </div>
        </div>
    </div>
</template>
<script setup>
import {ref} from "vue"
import {Inertia} from "@inertiajs/inertia"
import useToast from "@composables/useToast"
import {useForm} from "@inertiajs/inertia-vue3";

const props = defineProps({
    canAdd: {
        type: Boolean,
        required: true
    },
    baseUrl: {
        type: String,
        required: true
    },
    labels: {
        type: Array,
        default: [] // {id, value}
    }
})

const input = ref(null)
const showingAdd = ref(false)
const showAdd = () => {
    showingAdd.value = true
    setTimeout(() => input.value?.focus(), 300)
}
const hideAdd = () => {
    showingAdd.value = false
    form.reset()
}

const {toast} = useToast()

const form = useForm({
    name: ''
})

const addLabel = () => {
    if (form.name && form.name.length) {
        form.post(props.baseUrl + '/add-label/', {
            preserveScroll: true,
            preserveState: true,
            onSuccess: (v) => hideAdd()
        })
    } else {
        hideAdd()
    }
}
const removeLabel = (labelId) => {
    Inertia.post(props.baseUrl + '/remove-label/' + labelId,  null, {
        preserveScroll: true,
        preserveState: true,
    })
}


</script>
<style type="postcss">
    .labels-input-wrapper {
        @apply flex flex-wrap gap-2 sm:gap-3
    }
    .new-lable-btn { padding:4px 8px; line-height:13px;
        @apply flex items-center rounded bg-silver lg:bg-lighter text-sm
    }
    .new-lable-btn .span {
        @apply pl-2 inline-block;
    }
    .ft__col input[type="text"].new-lable-input {
        @apply bg-gray-200 w-auto;
    }
    .label-item {padding:4px 8px; line-height:13px;
        @apply items-center text-sm rounded bg-silver lg:bg-lighter relative pr-8 md:flex
    }
    .label-item .label-close { cursor:pointer;
        @apply w-7 h-7 absolute right-0 top-0 flex items-center justify-center lg:text-white;
    }
</style>
<!-- <style type="postcss">
    .vue-tags-input {
        @apply max-w-none bg-transparent
    }
    .vue-tags-input .ti-input {
        @apply border-none p-0;
    }
</style> -->
