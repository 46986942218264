<template>
    <div class="content-page cover-page">
        <div class="container-sm mx-auto">
            <div class="bg-white lg:p-12 md:p-10 p-8 lg:border-l lg:border-r border-dark cover-page text-md">
                <div class="md:w-7/12">
                    <div class="lg:mb-12 mb-8 text-dark">
                        <div class="flex mb-2" style="align-items: center">
                            <div class="col pr-3">
                                <div class="icon icon--md">
                                    <svg class="icon-svg">
                                        <use xlink:href="#icon-feedback"></use>
                                    </svg>
                                </div>
                            </div>
                            <div class="col text-xxl font-light">Let us know what you think</div>
                        </div>
                        <p class="text-lg">Sympmy Beat is in a first-stage live beta. We are continually working to improve the product, so your feedback is vital to us. If you have any questions, issues or suggestions please let us know.</p>
                    </div>

                    <form @submit.stop.prevent="submitForm">
                        <div class="flex mb-3 items-center">
                            <div class="col font-semibold text-dark mr-2">I have a...</div>
                            <div class="col">
                                <div class="select-input select-input--due cursor-pointer" v-click-away="onClickAway">
                                    <div class="si-selected-val" @click="dropdown = !dropdown" :class="{'si-selected-val--default': form.type == 'Select'}">
                                        {{form.type}}
                                    </div>
                                    <ul class="si-options dropdown" v-if="dropdown">
                                        <li class="si-option" v-for="(item, index) in options" @click="changeVal(item)">
                                            <div>{{item}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="input-wrapper mb-3">
                            <textarea name="" rows="10" v-model="form.message" maxlength="1500" placeholder="Your Feedback" class="bg-silver p-4"></textarea>
                        </div>
                        <div class="input-wrapper">
                            <div class="custom-checkbox">
                                <label>
                                    <input type="checkbox" class="pr-2" v-model="form.response">
                                    <span class="font-semibold text-dark">I would like a response</span>
                                </label>
                            </div>
                        </div>
                        <div class="pl-9 text-dark">Replies will be sent to your registered email address {{ email }}</div>
                        <div class="text-right mt-6">
                            <input :disabled="!canSubmit" type="submit" value="Send feedback" class="btn feedback-btn bg-primary text-white hover:bg-xdark btn--wide" :style="{cursor: !canSubmit ? 'not-allowed' : 'pointer'}">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";
    import {useForm} from "@inertiajs/inertia-vue3";
    const options = ['Question', 'Problem', 'Suggestion', 'Comment']
    const dropdown = ref(false);

    const props = defineProps({
        email: {
            type: String,
            required: true
        }
    })

    function changeVal(val) {
        form.type = val;
        dropdown.value = false;
    }
    const onClickAway = (event) => {
        dropdown.value = false;
    }

    const form = useForm({
        type: 'Select',
        message: '',
        response: false
    })

    const canSubmit = computed(() => form.type && (form.type === 'Question' || form.type === 'Problem' || form.type === 'Suggestion' || form.type === 'Comment') && form.message)

    const submitForm = () => {
        if (canSubmit.value) {
            form.post('/feedback')
        }
    }

</script>

<style type="postcss" scoped>
    .select-input {
        @apply relative w-36;
    }
    .si-options {
        @apply w-full z-10;
    }
    .si-selected-val { border-radius:4px; font-size:12px; padding:4px 8px; line-height:13px;
        @apply bg-silver rounded-br-none transition-all whitespace-nowrap text-dark font-semibold;
    }
    .si-selected-val--default {
        @apply text-light font-light
    }

    .feedback-btn{
       line-height:18px;
        @apply lg:w-32 w-full text-center border font-semibold bg-primary border-primary text-white
    }
    .feedback-btn:disabled {
        @apply hover:bg-primary hover:text-white
    }
</style>

<style type="text/css" scoped>
    .content-page { background: #efefef;}
    .cover-page {height: calc(100vh - 112px);}
</style>
