<template>
    <div class="context__navs auto-complete auto-complete--tag">
        <Multiselect
            ref="multiselect"
            placeholder="Type..."
            :searchable="false"
            :filter-results="false"
            :resolve-on-load="false"
            :clear-on-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :can-deselect="false"
            :can-clear="false"
            :create-option="false"
            :append-new-option="false"
            :hide-selected="false"

            @change="valueChanged"
            :options="contextOptions"
            @click="closeMe"
        >
            <template v-slot:singlelabel="{ value }">
                <button type="button" class="context__nav" tabindex="-1">
                    ...
                </button>
            </template>
            <template v-slot:placeholder>
                <button type="button" class="context__nav" tabindex="-1">
                    ...
                </button>
            </template>
        </Multiselect>
    </div>
</template>
<script setup>
import {computed, ref} from "vue"
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

const props = defineProps({
    isOpen: {
        type: Boolean,
        required: true
    },
})

const multiselect = ref(null)
const contextOptions = computed(() => {
    let arr = [ 'Rename']

    if (props.isOpen) {
        arr.push('Close')
    } else {
        arr.push('Open')
    }

    return arr
})

const emit = defineEmits(['rename', 'open', 'close'])

const valueChanged = (val) => {
    if (val === 'Rename') {
        emit('rename')
    } else if (val === 'Open') {
        emit('open')
    } else if (val === 'Close') {
        emit('close')
    }
}

const isOpen = ref(false)
const closeMe = () => {
    isOpen.value = !isOpen.value
    if (!isOpen.value) {
        multiselect.value?.close()
    } else {
        multiselect.value?.open()
    }
}
</script>

<style type="postcss" scoped>
/*.context__navs.auto-complete /deep/ .multiselect .multiselect-dropdown {*/
/*    display: block !important;*/
/*}*/

.context__nav { border-radius:4px;
    @apply pt-2 pl-4 pr-1 rounded-br-none text-right bg-dark text-white font-semibold;
}
.action-card .context__nav {width: 18px; height: 18px; padding: 2px; line-height: 14px}
.context__navs.auto-complete /deep/ .multiselect  {
    @apply bg-dark;
}
.context__navs.auto-complete /deep/ .multiselect.is-open  {
    @apply bg-dark text-white rounded-bl-none;
}
.context__navs.auto-complete /deep/ .multiselect.is-open .context__nav {
    @apply bg-dark text-white rounded-bl-none;
}

.auto-complete /deep/ .multiselect { min-height:24px;
    @apply block rounded w-full font-semibold relative text-gray-700 appearance-none bg-transparent p-0 rounded-br-none border-none text-sm;
}
.action-card .context__navs.auto-complete /deep/ .multiselect {min-height: 18px;}
.auto-complete /deep/ .multiselect-dropdown {
    @apply bg-dark text-white font-medium rounded-none border border-dark;
}

.context__navs.auto-complete /deep/ .multiselect .multiselect-dropdown {
    width: fit-content;
    margin-left: auto;
    margin-right: 1px;
    overflow: hidden;

}

.auto-complete /deep/ .multiselect-dropdown {
    @apply bg-dark text-white font-medium rounded-none border border-dark;
}
.auto-complete /deep/ .multiselect-options li,
.auto-complete /deep/ .multiselect-no-options,
.auto-complete /deep/ .multiselect-no-results {
    @apply py-1 px-2 block whitespace-nowrap text-white text-sm border-t-0 border-b border-light ;
    padding:4px 8px; line-height:15px;
}
.auto-complete /deep/ .multiselect-options li:last-child,
.auto-complete /deep/ .multiselect-no-options:last-child,
.auto-complete /deep/ .multiselect-no-results:last-child {
    @apply border-transparent
}
.auto-complete /deep/ .multiselect-options li:hover,
.auto-complete /deep/ .multiselect-options li.is-selected,
.auto-complete /deep/ .multiselect-options li.is-pointed {
    @apply bg-ddbg;
}
.auto-complete /deep/ .multiselect-clear {
    @apply absolute right-0 top-2 z-0
}
.auto-complete.auto-complete--tag /deep/ .multiselect {
    @apply text-gray-700 text-sm font-semibold;
}
.auto-complete.auto-complete--tag /deep/ .multiselect-placeholder {
    @apply absolute left-0 top-0 w-full;
}


</style>
