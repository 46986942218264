<template>
    <div class="add-new-bar noprint">
        <div class="flex gap-sm flex-wrap">
            <div class="col-auto lg:block hidden">
                <div class="text-xxl leading-7 font-thin">Add user</div>
            </div>
            <div class="col 2xl:w-1/3 lg:w-1/4 w-full">
                <input type="email" placeholder="Email" v-model="form.email">
            </div>
            <div class="col 2xl:w-1/3 lg:w-1/4 w-full">
                <input type="text" placeholder="Name" v-model="form.name">
            </div>
            <div class="col w-full lg:w-auto flex-1">

                <div class="type-selector type-selector--type cursor-pointer" v-click-away="onClickAway">
                    <div class="ps-selected-val" @click="dropdown = !dropdown" :class="{'ps-selected-val--default': form.type === 'Type...'}">
                        {{form.type}}
                    </div>
                    <ul class="ps-options dropdown" v-if="dropdown">
                        <li class="ps-option" v-for="(item, index) in options" @click="valueChanged(item)">
                            <div>{{item}}</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col lg:w-auto w-full">
                <input type="submit" value="Add" @click="submitForm" :disabled="!form.email?.length || !form.name?.length" class="btn btn-add" :style="{cursor: !form.email?.length || !form.name?.length ? 'not-allowed' : 'pointer'}">
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from "vue";
import {useForm, usePage} from "@inertiajs/inertia-vue3";
import useToast from "@composables/useToast";


const form = useForm({
    email: '',
    name: '',
    type: 'Non-Admin',
})

const dropdown = ref(false)
const options = ['Admin', 'Non-Admin'];

const valueChanged = (val) => {
    changeVal(val)
}

function changeVal(val) {
    form.type = val
    dropdown.value = false;
}

const onClickAway = (event) => {
    dropdown.value = false;
}

const {toast} = useToast()

const emits = defineEmits(['added'])

const submitForm = () => {
    if (form.email?.length && form.name?.length && (form.type === 'Admin' || form.type === 'Non-Admin')) {
        form.post("/admin/users/add", {
            preserveScroll: true,
            preserveState: (page) => Object.keys(page.props.errors).length,
            onSuccess: (visit) => {
                form.reset()
                emits('added')
            },
            onFinish: (visit) => {
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur()
                }
            }
        })
    }
}

</script>
<style type="postcss" scoped>
    .add-new-bar {
        @apply bg-ddbg text-white 2xl:p-5 p-4;
    }
    .btn-add {
        cursor:pointer; line-height:18px;
        @apply lg:w-32 w-full text-center border font-semibold
        bg-primary border-primary text-white
        hover:bg-white hover:border-white hover:text-primary
        focus:bg-white focus:border-white focus:text-primary
    }
    .add-new-bar [type=text],
    .add-new-bar [type=email],
    .add-new-bar select,
    .add-new-bar .ps-selected-val {
        @apply text-gray-700 appearance-none bg-white px-4 py-2 text-sm font-semibold;
    }
    .add-new-bar [type=text]::placeholder,
    .add-new-bar [type=email]::placeholder,
    .add-new-bar select::placeholder,
    .add-new-bar .ps-selected-val::placeholder {
        @apply font-normal italic
    }
    .add-new-bar .ps-selected-val--default {
        @apply text-gray-400;
    }
    .add-new-bar .ps-selected-val.critical {
        @apply bg-danger text-white not-italic font-semibold
    }
    .add-new-bar .ps-selected-val.high {
        @apply bg-error text-white not-italic font-semibold
    }
    .add-new-bar .ps-selected-val.medium {
        @apply bg-warning text-white not-italic font-semibold
    }
    .add-new-bar .ps-selected-val.low {
        @apply bg-success text-white not-italic font-semibold
    }

    .type-selector {
        @apply relative;
    }
    .ps-options {
        @apply w-full z-10;
    }
    .ps-selected-val {
        @apply rounded rounded-br-none text-gray-400 bg-gray-200 py-2 px-2 transition-all font-semibold leading-5 text-sm;
    }
    .ps-selected-val--default {
        @apply italic font-thin text-gray-400
    }
    @media(min-width:890px) {
        .gap-sm {
            gap:12px;
        }
    }
</style>
