<template>
    <div class="rounded-md relative group py-2" :class="{'hover:bg-ddbg': allowDeleting, 'bg-ddbg': showingRemove}">
        <div class="flex items-center justify-between">
            <a :href="'/data/attachment/' + filepath" target="_blank" class="flex items-center">
                <div class="icon icon--small mr-2">
                    <svg class="icon-svg">
                        <use xlink:href="#icon-attachment"></use>
                    </svg>
                </div>{{ filename }}
            </a>
            <div v-if="allowDeleting" class="icon icon--xssmall mr-2 cursor-pointer remove-symbol noprint" :class="{'opacity-100': showingRemove}" @click.stop="changeShowRemove" @keyup.enter.stop="changeShowRemove">
                <svg class="icon-svg">
                    <use xlink:href="#icon-close-light"></use>
                </svg>
            </div>
        </div>
        <div class="ft__row ft__row--confirmation noprint pb-2" v-if="showingRemove">
            <div class="confirmation-box">
                <div class="">
                    <div class="col pl-4">
                        <div class="flex justify-end pt-4">
                            <div class="col">
                                <button class="btn btn--border text-black nb-focus" style="background: white;" type="button" @click.stop="deleteAttachment" @keyup.enter.stop="deleteAttachment" tabindex="0">Delete</button>
                            </div>
                            <div class="col pl-3">
                                <button class="btn btn--border text-black nb-focus" style="background: white;" type="button" @click.stop="cancelDeleteAttachment" @keyup.enter.stop="cancelDeleteAttachment" tabindex="0">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";
import {Inertia} from "@inertiajs/inertia";

const props = defineProps({
    filepath: {
        type: String,
        required: true
    },
    filename: {
        type: String,
        required: true
    },
    allowDeleting: {
        type: Boolean,
        default: false
    },
    actionId: {
        type: Number,
        required: true
    },
})

const showingRemove = ref(false)
const changeShowRemove = () => showingRemove.value = !showingRemove.value

const deleteAttachment = () => {
    Inertia.post('/action/' + props.actionId + '/remove-attachment/' + props.filepath, null, {
        preserveScroll: true,
        preserveState: true,
    })
}
const cancelDeleteAttachment = () => showingRemove.value = false

</script>

<style scoped>
.remove-symbol {
    opacity: 0;
}

.group:hover .remove-symbol,
.opacity-100 {
    opacity: 1;
}
</style>
