<template>
    <div :id="'context-nav-' + actionId + '-' + idSuffix" class="context__navs auto-complete auto-complete--tag">
        <Multiselect
            ref="multiselect"
            placeholder="Type..."
            :searchable="false"
            :filter-results="false"
            :resolve-on-load="false"
            :clear-on-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :can-deselect="false"
            :can-clear="false"
            :create-option="false"
            :append-new-option="false"
            :hide-selected="false"
            @close="closed"
            @open="opened"
            @change="valueChanged"
            :options="contextOptions"
            @click="clicked"
        >
            <template v-slot:singlelabel="{ value }">
                <button type="button" class="context__nav" tabindex="-1">
                    ...
                </button>
            </template>
            <template v-slot:placeholder>
                <button type="button" class="context__nav" tabindex="-1">
                    ...
                </button>
            </template>
            <template #option="{ option }">
                <div v-if="option.value === 'Move to ...'" @click.stop.prevent="toggleMoveOptions()" :class="{'showing-move-options': isShowingMoveOptions}">
                    <div class="" :class="{'w-20 ml-auto': isShowingMoveOptions}">
                        {{ option.label }}
                    </div>
                </div>
                <div v-else-if="option.is_move" @click.stop.prevent="handleMove(option)" class="move-option flex items-center">
                    <div v-if="option.slug !== 'general'" class="icon icon--small mr-3 flex-shrink-0">
                        <svg class="icon-svg">
                            <use xlink:href="#icon-project"></use>
                        </svg>
                    </div>
                    <span class="move-label">
                        {{ option.label }}
                    </span>
                </div>
<!--                <div v-else @click="handleOptionClick(option)" :class="{'text-right': isShowingMoveOptions}">-->
<!--                    {{ option.label }}-->
<!--                </div>-->
                <div v-else @click="handleOptionClick(option)">
                    <div class="" :class="{'w-20 ml-auto': isShowingMoveOptions}">
                        {{ option.label }}
                    </div>
                </div>
            </template>
        </Multiselect>
    </div>
</template>
<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue"
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

const props = defineProps({
    canRebaseline: {
        type: Boolean,
        required: true
    },
    canReset: {
        type: Boolean,
        required: true
    },
    canMove: {
        type: Boolean,
        required: true
    },
    projects: {
        type: Array,
        required: true
    },
    currentSlug: {
        type: String,
        required: true
    },
    actionId: {
        type: Number,
        required: true
    },
    idSuffix: {
        type: String,
        required: true
    },
})

const contextOptions = computed(() => {
    let arr = [ 'Delete', 'Duplicate']
    if (props.canMove) {
        arr.push('Move to ...')
    }

    if (isShowingMoveOptions.value) {
        let tempInner = props.projects
            .filter(p => p.slug !== props.currentSlug)
            .map((p) => {
                return {
                    label: p.title,
                    value: p.title,
                    slug: p.slug,
                    is_move: true
                }
            })
        arr.splice(3, 0, ...tempInner)
    }

    if (props.canReset) {
        arr.push('Reset status')
    }
    if (props.canRebaseline) {
        arr.push('Re-baseline')
    }
    return arr
})

const multiselect = ref(null)

const initialWidth = computed(() => {
    if (props.canReset) {
        return 78
    } else if (props.canRebaseline) {
        return 77
    } else if (props.canMove) {
        return 69
    }

    return 66
})

const isOpen = ref(false)

const getDropdown = () => document.querySelector('#context-nav-' + props.actionId + '-' + props.idSuffix + ' .multiselect-dropdown')

const getDropdownWidth = async () => {
    await nextTick(); // Wait for the DOM to update
    const dropdown = getDropdown()
    if (dropdown) {
        return Math.max(...Array.from(dropdown.children).map(c => c.scrollWidth))
    }
    return 0
}

const setDropdownWidth = (newWidth) => {
    const dropdown = getDropdown()
    if (dropdown) {
        dropdown.style.width = newWidth  + "px";
    }
}
const opened = () => {
    setDropdownWidth(initialWidth.value)
}
const clicked = () => {
    isShowingMoveOptions.value = false
    isOpen.value = !isOpen.value
    if (isOpen.value) {
        multiselect.value.open()
    } else {
        multiselect.value.close()
    }
}

const isShowingMoveOptions = ref(false)

const showMoveOptions = async () => {
    isShowingMoveOptions.value = true
    let width = await getDropdownWidth()
    if (width) {
        setDropdownWidth(width + 10)
    }
}

const hideMoveOptions = () => {
    isShowingMoveOptions.value = false
    setDropdownWidth(initialWidth.value)
}
const toggleMoveOptions = () => {
    if (isShowingMoveOptions.value) {
        hideMoveOptions()
    } else {
        showMoveOptions()
    }
}


const closed = () => {
    isOpen.value = false
    isShowingMoveOptions.value = false
    multiselect.value.clear()
    setDropdownWidth(initialWidth.value)
}



const emit = defineEmits(['delete', 'duplicate', 'reset', 'rebaseline', 'move'])

const valueChanged = (val) => {
    if (val === 'Delete') {
        emit('delete')
    } else if (val === 'Duplicate') {
        emit('duplicate')
    } else if (val === 'Reset status') {
        emit('reset')
    } else if (val === 'Re-baseline') {
        emit('rebaseline')
    }
}

const handleOptionClick = (option) => multiselect.value.select(option)
const handleMove = (option) => {
    emit('move', option)
    multiselect.value.close()
}

</script>

<style type="postcss" scoped>
/*.context__navs.auto-complete /deep/ .multiselect .multiselect-dropdown {*/
/*    display: block !important;*/
/*}*/

.context__nav { border-radius:4px;
    @apply pt-2 pl-4 pr-1 rounded-br-none text-right lg:bg-lighter bg-white font-semibold;
}
.context__navs.auto-complete /deep/ .multiselect  {
    @apply lg:bg-lighter bg-white;
}
.context__navs.auto-complete /deep/ .multiselect.is-open  {
    @apply bg-ddbg text-white rounded-bl-none;
}
.context__navs.auto-complete /deep/ .multiselect.is-open .context__nav {
    @apply bg-ddbg text-white rounded-bl-none;
}

.auto-complete /deep/ .multiselect { min-height:24px;
    @apply block rounded w-full font-semibold relative text-gray-700 appearance-none bg-transparent p-0 rounded-br-none border-none text-sm;
}
.auto-complete /deep/ .multiselect-dropdown {
    @apply bg-ddbg text-white font-medium rounded-none border border-dark;
}

.context__navs.auto-complete /deep/ .multiselect .multiselect-dropdown {
    width: fit-content;
    margin-left: auto;
    margin-right: 1px;
    /*overflow: hidden;*/
    /*height: fit-content!important;*/
    /*max-height: fit-content!important;*/
}

.auto-complete /deep/ .multiselect-dropdown {
    @apply bg-ddbg text-white font-medium rounded-none border border-dark;
    overflow: visible;
    max-height: none;
}
.auto-complete /deep/ .multiselect-dropdown .multiselect-options {
    max-height: none;
}
.auto-complete /deep/ .multiselect-options li,
.auto-complete /deep/ .multiselect-no-options,
.auto-complete /deep/ .multiselect-no-results {
    @apply py-1 px-2 block whitespace-nowrap text-white text-sm border-t-0 border-b border-light ;
    padding:4px 8px; line-height:15px;
}
.auto-complete /deep/ .multiselect-options li:last-child,
.auto-complete /deep/ .multiselect-no-options:last-child,
.auto-complete /deep/ .multiselect-no-results:last-child {
    @apply border-transparent
}
.auto-complete /deep/ .multiselect-options li:hover,
.auto-complete /deep/ .multiselect-options li.is-selected,
.auto-complete /deep/ .multiselect-options li.is-pointed {
    @apply bg-dark;
}
.auto-complete /deep/ .multiselect-clear {
    @apply absolute right-0 top-2 z-0
}
.auto-complete.auto-complete--tag /deep/ .multiselect {
    @apply text-gray-700 text-sm font-semibold;
}
.auto-complete.auto-complete--tag /deep/ .multiselect-placeholder {
    @apply absolute left-0 top-0 w-full;
}
.auto-complete /deep/ .multiselect-options li {
    position: relative;
    /* other styles... */
}
.auto-complete /deep/ .multiselect-options .move-option {
    @apply bg-lighter border-b border-white text-black p-2;
    padding: 4px 8px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.auto-complete /deep/ .multiselect-options .move-option:hover {
    @apply bg-white;
}
.auto-complete /deep/ .multiselect-options .showing-move-options {
    @apply bg-dark text-white;
    padding: 4px 8px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

</style>
