<template>
    <div class="mini-timeline-wrapper">
        <div class="mini-timeline" :class="baseColourClass">
            <div v-if="timeline.tone !== null" class="mt-completed" :class="toneColourClass" :style="{'left': timeline.tone + '%', 'border-radius': timeline.tone ? '' : '0.25rem'}"></div>
            <div v-if="timeline.bar && timeline.bar !== timeline.complete && timeline.bar !== timeline.today" class="mt-bar" :style="{'left': timeline.bar + '%'}"></div>
            <div v-if="timeline.complete" class="mt-circle" :style="{'left': timeline.complete + '%'}"></div>
            <div v-else-if="timeline.today" class="mt-split" :style="{'left': (timeline.today > 0 && timeline.today < 100 ? timeline.today - 2 : timeline.today) + '%'}"></div>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    timeline: {
        type: Object,
        required: true
    },
})

const baseColourClass = computed(() => (
    props.timeline.type === 'complete'
        ? 'bg-blue'
        : (props.timeline.type === 'past'
                ? 'bg-danger'
                : (props.timeline.type === 'tolerance'
                        ? 'bg-amber'
                        : 'bg-success'
                )
        )
))
const toneColourClass = computed(() => (
    props.timeline.type === 'complete'
        ? 'bg-paleblue'
        : (props.timeline.type === 'past'
                ? 'bg-error'
                : (props.timeline.type === 'tolerance'
                        ? 'bg-warning'
                        : 'bg-palegreen'
                )
        )
))

</script>

<style type="postcss">
</style>
