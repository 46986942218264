import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { Link } from "@inertiajs/inertia-vue3"
import VueClickAway from "vue3-click-away"
import AppLayout from "@layouts/AppLayout"

createInertiaApp({
    resolve: name => {
        const page = require(`./pages/${name}`).default
        page.layout = page.layout || AppLayout
        return page
    },
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(VueClickAway)
            .use(plugin)
            .component('InertiaLink', Link)
            .mount(el)
    },
})
